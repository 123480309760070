// src/constants/badges.js
export const BADGE_ICONS = {
    early_bird: { icon: '🐤', tooltip: 'Awarded to the first 100 users.' },
    top_contributor: { icon: '🌟', tooltip: 'Awarded to users who submit 10 or more requests.' },
    keyboard_warrior: { icon: '⌨️', tooltip: 'Awarded to users who send 100 or more messages.' },
    credits_badge: { icon: '🐇', tooltip: 'Awarded for finding the hidden credits page.' },
    bug_finder: { icon: '🐜', tooltip: 'Awarded to users who report a verified bug.' },
    request_master: { icon: '📋', tooltip: 'Awarded to users who submit 20 or more requests.' },
    social_butterfly: { icon: '🦋', tooltip: 'Awarded to users who connect Discord' }, //need to add badge to all current discord users and new ones
    voter_elite: { icon: '🗳️', tooltip: 'Awarded to users who cast 50+ votes on cheat requests.' }, //need to add to our vote backend 
    year_one: { icon: '🎉', tooltip: 'Awarded to users active for 1 year.' }, //need to implement this
    admin_ace: { icon: '👑', tooltip: 'Exclusive badge for site administrators.' },
};