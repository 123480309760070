import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import './CategoryDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

function CategoryDetail() {
  const { id: slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const adRefTop = useRef(null);
  const adRefBottom = useRef(null);
  const adRefLeft = useRef(null);
  const adRefRight = useRef(null);

  const [metadata, setMetadata] = useState(null);
  const [category, setCategory] = useState(null);
  const [latestTable, setLatestTable] = useState(null);
  const [tables, setTables] = useState([]);
  const [steamDescription, setSteamDescription] = useState("");
  const [steamBanner, setSteamBanner] = useState("");
  const [loading, setLoading] = useState(true);
  const [tablesLoading, setTablesLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDiscordLinked, setIsDiscordLinked] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [reportInput, setReportInput] = useState("");
  const [isReporting, setIsReporting] = useState(false);
  const [status, setStatus] = useState("green");
  const [reportCount, setReportCount] = useState(0);
  const [isReportFormVisible, setIsReportFormVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLatestTableSaved, setIsLatestTableSaved] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackType, setFeedbackType] = useState("");

  localStorage.setItem("redirectAfterLogin", window.location.pathname);

  const notificationIcon = !isDiscordLinked
    ? "https://sintrix.net/disclinknoti2.png"
    : notificationsEnabled
      ? "https://sintrix.net/disclinknoti3.png"
      : "https://sintrix.net/disclinknoti1.png";

  const steamIcon = "https://sintrix.net/steam-icon.png";
  const youtubeIcon = "https://sintrix.net/youtube.png";

  // Token Management
  const decodeToken = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (err) {
      console.error("Invalid token format:", err);
      return null;
    }
  };

  const verifyToken = (token) => {
    const decoded = decodeToken(token);
    if (!decoded) return false;
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp > currentTime;
  };

  const fetchAccessTokenIfNeeded = async () => {
    const token = localStorage.getItem("token");
    if (!token) return null;

    if (!verifyToken(token)) {
      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const response = await axios.post("/api/auth/refresh-token", { refreshToken });
        const { accessToken } = response.data;
        localStorage.setItem("token", accessToken);
        return accessToken;
      } catch (err) {
        console.error("Failed to refresh token:", err);
        handleLogout();
        return null;
      }
    }
    return token;
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    navigate("/login");
  };

  // Fetch Functions
  const fetchMetadata = async () => {
    try {
      const response = await axios.get(`/metadata/${slug}`);
      setMetadata(response.data);
    } catch (error) {
      console.error("Error fetching metadata:", error);
    }
  };

  const fetchCategoryDetails = async () => {
    try {
      const response = await axios.get(`/api/admin/categories/${slug}`);
      console.log("API Response:", response.data); // Debug log
      setCategory(response.data);
      setStatus(response.data.status || "green");
      setReportCount(response.data.reportCount || 0);
    } catch (error) {
      console.error("Error fetching category details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTables = async () => {
    setTablesLoading(true);
    try {
      const response = await axios.get(`/api/admin/tables/${slug}`);
      const sortedTables = response.data.sort((a, b) => {
        const versionA = a.version.split('.').map(Number);
        const versionB = b.version.split('.').map(Number);
        for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
          const numA = versionA[i] || 0;
          const numB = versionB[i] || 0;
          if (numA !== numB) return numB - numA;
        }
        return 0;
      });
      setTables(sortedTables);
      if (sortedTables.length > 0) setLatestTable(sortedTables[0]);
    } catch (error) {
      console.error("Error fetching tables:", error);
    } finally {
      setTablesLoading(false);
    }
  };

  const fetchSteamDescription = async (appid) => {
    try {
      const response = await axios.get(`/api/requests/steam-description/${appid}`);
      setSteamDescription(response.data.description || "No description available on Steam.");
      setSteamBanner(response.data.header_image || "");
    } catch (error) {
      console.error("Error fetching Steam description:", error);
      setSteamDescription("Failed to load Steam description.");
      setSteamBanner("");
    }
  };

  const fetchDiscordStatus = async () => {
    const token = await fetchAccessTokenIfNeeded();
    if (!token) return;
    try {
      const response = await axios.get("/api/auth/dashboard", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setIsDiscordLinked(response.data.discordLinked);
    } catch (err) {
      console.error("Error fetching Discord status:", err);
    }
  };

  const fetchNotificationStatus = async () => {
    const token = await fetchAccessTokenIfNeeded();
    if (!token) return;
    try {
      const response = await axios.get(`/api/auth/notifications/${slug}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setNotificationsEnabled(response.data.enabled);
    } catch (error) {
      console.error("Error fetching notification status:", error);
      setNotificationsEnabled(false);
    }
  };

  // Handlers
  const toggleReportForm = () => {
    setIsReportFormVisible((prev) => !prev);
    setErrorMessage("");
  };

  const handleReportSubmit = async () => {
    if (!reportInput.trim()) {
      setErrorMessage("Please enter a description of the issue.");
      return;
    }
    setIsReporting(true);
    setErrorMessage("");
    try {
      const token = await fetchAccessTokenIfNeeded();
      if (!token) {
        setErrorMessage("You must be logged in to submit a report.");
        setIsReporting(false);
        return;
      }
      const response = await axios.post(
        `/api/auth/categories/${slug}/report`,
        { description: reportInput },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setReportCount((prev) => prev + 1);
      setReportInput("");
      setIsReportFormVisible(false);
      setSuccessMessage("Report submitted successfully!");
      setTimeout(() => setSuccessMessage(""), 5000);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to submit the report.");
    } finally {
      setIsReporting(false);
    }
  };

  const handleToggleNotifications = async () => {
    const token = await fetchAccessTokenIfNeeded();
    if (!token) {
      alert("You must be logged in to enable notifications.");
      return;
    }
    if (!isDiscordLinked) {
      alert("You must link your Discord account to enable notifications.");
      return;
    }
    try {
      const response = await axios.post(
        `/api/auth/notifications/${slug}`,
        { enabled: !notificationsEnabled },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotificationsEnabled(response.data.enabled);
    } catch (error) {
      console.error("Error updating notification status:", error);
      alert("Failed to update notifications.");
    }
  };

  const handleSaveOrUnsaveLatestTable = async () => {
    if (!latestTable) {
      setFeedbackType("error");
      setFeedbackMessage("No table available to save.");
      setTimeout(() => setFeedbackMessage(""), 5000);
      return;
    }
    try {
      const token = await fetchAccessTokenIfNeeded();
      if (!token) {
        setFeedbackType("error");
        setFeedbackMessage("You must be logged in to modify saved tables.");
        setTimeout(() => setFeedbackMessage(""), 5000);
        return;
      }
      if (isLatestTableSaved) {
        await axios.post(
          `/api/auth/categories/${category.slug}/unsave-latest-table`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setIsLatestTableSaved(false);
        setFeedbackType("success");
        setFeedbackMessage("Table unsaved successfully.");
      } else {
        await axios.post(
          `/api/auth/categories/${category.slug}/save-latest-table`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setIsLatestTableSaved(true);
        setFeedbackType("success");
        setFeedbackMessage("Table saved successfully.");
      }
      setTimeout(() => setFeedbackMessage(""), 5000);
    } catch (error) {
      console.error("Error modifying saved state:", error);
      setFeedbackType("error");
      setFeedbackMessage("Failed to update saved state.");
      setTimeout(() => setFeedbackMessage(""), 5000);
    }
  };

  const handleTableDownload = async (table) => {
    try {
      const response = await axios.get(`/api/admin/download-table/${table._id}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${category.slug}-v${table.version}.CT`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      fetchTables();
    } catch (error) {
      console.error("Error downloading table:", error);
    }
  };

  useEffect(() => {
    const navbar = document.querySelector('.navbar'); // Match your navbar class
    if (navbar) {
      const height = navbar.offsetHeight;
      document.documentElement.style.setProperty('--navbar-height', `${height}px`);
    }
  
    const initializeAds = () => {
      const ads = [adRefTop, adRefBottom, adRefLeft, adRefRight];
      ads.forEach((adRef, index) => {
        if (adRef.current && !adRef.current.hasAttribute('data-adsbygoogle-status')) {
          console.log(`Initializing ad ${index + 1}:`, adRef.current);
          adRef.current.innerHTML = '';
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (e) {
            console.error(`AdSense push error for ad ${index + 1}:`, e);
          }
        }
      });
    };
  
    initializeAds();
    const timer = setTimeout(() => {
      console.log("Retrying ad initialization after delay...");
      initializeAds();
    }, 500);
  
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % category.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + category.images.length) % category.images.length);
  };

  const handleExpandImage = () => {
    setIsExpanded(true);
  };

  const closeExpandImage = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    fetchMetadata();
    fetchCategoryDetails();
    fetchTables();
    fetchDiscordStatus();
    fetchNotificationStatus();
  }, [slug]);

  useEffect(() => {
    if (category?.appid) {
      fetchSteamDescription(category.appid);
    }
  }, [category]);

  if (loading) return <p>Loading game details...</p>;

  return (
    <div className="page-container">
      <div className="ad-sidebar ad-sidebar-left">
        <ins
          ref={adRefLeft}
          key={`left-${location.pathname}`}
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-3980892685014254"
          data-ad-slot="5067767497"
          data-ad-format="vertical"
          data-full-width-responsive="false"
        />
      </div>

      <div className="category-detail-container">
        <Helmet>
          <title>{metadata?.title || "Loading..."}</title>
          <link rel="canonical" href={`https://sintrix.net/category/${slug}`} />
          <meta name="description" content={metadata?.description || "Loading..."} />
          <meta property="og:title" content={metadata?.title || "Loading..."} />
          <meta property="og:description" content={metadata?.description || "Loading..."} />
          <meta property="og:image" content={metadata?.image || "https://sintrix.net/sintrix2.png"} />
          <meta property="og:url" content={metadata?.url || "https://sintrix.net"} />
        </Helmet>

        <div className="ad-container ad-container-top">
          <ins
            ref={adRefTop}
            key={`top-${location.pathname}`}
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3980892685014254"
            data-ad-slot="5618726989"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </div>

        <div className="banner">
          <h2 className="banner-title">{category.name}</h2>
        </div>

        <div className="disclaimer">
          <p>Disclaimer: We are not responsible for any loss of account, data, or damage resulting from the use of these tables. Use at your own risk.</p>
        </div>
        <hr className="divider" />

        {category.discontinued && (
          <p className="discontinued-message">This game is discontinued and will not be receiving updates.</p>
        )}

        {feedbackMessage && (
          <div className={`alert ${feedbackType === "error" ? "alert-error" : "alert-success"}`}>
            <span className="alert-icon">{feedbackType === "error" ? "❌" : "✅"}</span>
            <span className="alert-message">{feedbackMessage}</span>
            <button className="alert-close" onClick={() => setFeedbackMessage("")} aria-label="Close notification">×</button>
          </div>
        )}

        {errorMessage && (
          <div className="alert alert-error">
            <span className="alert-icon">❌</span>
            <span className="alert-message">{errorMessage}</span>
            <button className="alert-close" onClick={() => setErrorMessage("")} aria-label="Close error">×</button>
          </div>
        )}

        {successMessage && (
          <div className="alert alert-success">
            <span className="alert-icon">✅</span>
            <span className="alert-message">{successMessage}</span>
            <button className="alert-close" onClick={() => setSuccessMessage("")} aria-label="Close success">×</button>
          </div>
        )}

        <div className="category-content">
          <div className="category-media">
            <div className="image-container" style={{ position: "relative" }}>
              <div className={`status-badge-overlay ${status}`}>
                {status === "green" && (<><span className="status-badge-icon">✔</span> Working</>)}
                {status === "yellow" && (<><span className="status-badge-icon">⚠</span> Possible Issues</>)}
                {status === "red" && (<><span className="status-badge-icon">✖</span> Broken</>)}
              </div>
              <div className="image-slideshow">
                <img 
                  src={category.images[currentImageIndex]} 
                  alt={`Screenshot ${currentImageIndex + 1}`} 
                  className="category-screenshot" 
                  onClick={handleExpandImage} 
                />
                {category.video && (
                  <a 
                    href={category.video} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="youtube-overlay"
                  >
                    <img src={youtubeIcon} alt="YouTube Icon" className="youtube-icon" />
                  </a>
                )}
                <button className="prev-btn" onClick={prevImage}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button className="next-btn" onClick={nextImage}>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
            {isExpanded && (
              <div className="expanded-image-overlay" onClick={closeExpandImage}>
                <div className="expanded-image-container">
                  <img 
                    src={category.images[currentImageIndex]} 
                    alt={`Expanded Screenshot ${currentImageIndex + 1}`} 
                    className="expanded-image" 
                  />
                  <button className="close-btn" onClick={closeExpandImage}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            )}
            <hr className="divider" />
            <div className="download-section">
              {tablesLoading ? (
                <p>Loading tables...</p>
              ) : (
                <>
                  <h3>Available Tables</h3>
                  {tables.length === 0 ? (
                    <p>No tables available for this game.</p>
                  ) : (
                    <>
                      {tables.slice(0, showMore ? tables.length : 1).map((table) => (
                        <div key={table._id} className="table-item">
                          <div className="table-header">
                            <div className="table-meta">
                              <span><strong>Version:</strong> {table.version}</span>
                              <span><strong>Uploaded:</strong> {new Date(table.createdAt).toLocaleDateString()}</span>
                              <span><strong>Downloads:</strong> {table.downloadCount || 0}</span>
                            </div>
                            <button onClick={() => handleTableDownload(table)} className="download-link">
                              Download
                            </button>
                          </div>
                          <p className="table-description" dangerouslySetInnerHTML={{ __html: table.description.replace(/\n/g, "<br />") }} />
                        </div>
                      ))}
                      {tables.length > 1 && (
                        <a href="#!" onClick={() => setShowMore(!showMore)} className="show-more-link">
                          {showMore ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        </a>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="category-description">
            <div className="header-actions">
              <div className="notification-section">
                <div
                  className="notification-toggle"
                  data-tooltip={!isDiscordLinked ? "Login or link Discord to enable notifications" : notificationsEnabled ? "Disable notifications" : "Enable notifications"}
                  onClick={isDiscordLinked ? handleToggleNotifications : null}
                >
                  <img src={notificationIcon} alt="Notification Icon" className={`notification-icon ${!isDiscordLinked ? "disabled" : ""}`} />
                </div>
                <div
                  className="save-latest-button-container"
                  data-tooltip={!localStorage.getItem("token") ? "Login to save tables" : !latestTable ? "No table available" : isLatestTableSaved ? "Unsave table" : "Save table"}
                  onClick={!localStorage.getItem("token") ? () => setFeedbackMessage("You must log in to save tables.") : !latestTable ? null : handleSaveOrUnsaveLatestTable}
                >
                  {latestTable && (
                    <img
                      src={!localStorage.getItem("token") ? "https://sintrix.net/savetable2.png" : isLatestTableSaved ? "https://sintrix.net/savetable3.png" : "https://sintrix.net/savetable1.png"}
                      alt={isLatestTableSaved ? "Unsave Icon" : "Save Icon"}
                      className={`save-latest-icon ${isLatestTableSaved ? "saved" : ""}`}
                    />
                  )}
                </div>
                {category?.appid && (
                  <div
                    className="steam-link-container"
                    data-tooltip="View on Steam"
                  >
                    <a href={`https://store.steampowered.com/app/${category.appid}`} target="_blank" rel="noopener noreferrer">
                      <img src={steamIcon} alt="Steam Icon" className="steam-icon" />
                    </a>
                  </div>
                )}
                <button onClick={toggleReportForm} className="report-button">
                  {isReportFormVisible ? "Cancel" : "Report Issue"}
                </button>
              </div>
            </div>

            {isReportFormVisible && (
              <div className="report-form">
                <textarea className="report-textbox" placeholder="Describe the issue..." value={reportInput} onChange={(e) => setReportInput(e.target.value)} rows="4" />
                <button className="report-submit-button" onClick={handleReportSubmit} disabled={isReporting}>
                  {isReporting ? "Submitting..." : "Submit Report"}
                </button>
              </div>
            )}
            <hr className="divider" />
            <div className="description-content">
              <p>
                Welcome to our <strong>{category.name}</strong> table page. Here, you’ll find all of our table releases for this game.  
                Our tables are made by experienced table makers, are frequently tested to ensure they work with the latest version of the game,  
                and are easy to use even for complete beginners. We provide some of the best and most popular tables out there.
              </p>
              <h3>How do I use this table?</h3>
              <p>
                You will need Cheat Engine to run this file. You can either download it from  
                <a href="https://cheatengine.org/" target="_blank"> https://cheatengine.org/ </a>  
                or use our custom build available in the “Programs” section of our Discord.
              </p>
              <p>
                <strong>New to Cheat Engine?</strong> No problem! We offer comprehensive guides on how to use Cheat Engine,  
                complete with easy-to-follow instructions and helpful tips.
              </p>
              <p>
                Download the latest <strong>{category.name}</strong> cheat table now and start enjoying more freedom in your gameplay.  
                Don’t forget to browse our collection of Cheat Engine tables for other top games.
              </p>
              <p><strong>Note:</strong> Our Cheat Engine tables are designed for specific versions.  
                Please check the compatibility details before downloading.
              </p>
              <hr className="divider" />
              {steamDescription && (
                <div className="steam-description" dangerouslySetInnerHTML={{ __html: steamDescription }} />
              )}
            </div>
          </div>
        </div>

        <div className="ad-container ad-container-bottom">
          <ins
            ref={adRefBottom}
            key={`bottom-${location.pathname}`}
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-3980892685014254"
            data-ad-slot="3559152814"
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </div>
      </div>

      <div className="ad-sidebar ad-sidebar-right">
        <ins
          ref={adRefRight}
          key={`right-${location.pathname}`}
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-3980892685014254"
          data-ad-slot="8440439240"
          data-ad-format="vertical"
          data-full-width-responsive="false"
        />
      </div>
    </div>
  );
}

export default CategoryDetail;