import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaUndo } from "react-icons/fa";
import axios from "axios";
import "./ModeratorPanel.css";

const ModeratorPanel = () => {
    const [activeSection, setActiveSection] = useState("users");
    const [users, setUsers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [reports, setReports] = useState([]);
    const [expandedReports, setExpandedReports] = useState({});
    const [loading, setLoading] = useState(true);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [roleFilter, setRoleFilter] = useState("all");
    const navigate = useNavigate();
    const [showBanDetailsModal, setShowBanDetailsModal] = useState(false);
    const [selectedUserBanDetails, setSelectedUserBanDetails] = useState(null);
    const [showIpHistory, setShowIpHistory] = useState({});
    const [showUserReports, setShowUserReports] = useState({});

    localStorage.setItem("redirectAfterLogin", window.location.pathname);

    // Define getStatusIcon at the top of the component
    const getStatusIcon = (status) => {
        switch (status) {
            case "green":
                return <FaCheckCircle className="status-icon green" />;
            case "yellow":
                return <FaExclamationTriangle className="status-icon yellow" />;
            case "red":
                return <FaTimesCircle className="status-icon red" />;
            default:
                return null;
        }
    };

    const verifyToken = (token) => {
        const decoded = decodeToken(token);
        if (!decoded) return false;
        const currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp > currentTime;
    };

    const decodeToken = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (err) {
            console.error("Invalid token format:", err);
            return null;
        }
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;
            localStorage.setItem("token", accessToken);
            axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
            return accessToken;
        } catch (err) {
            console.error("Failed to refresh access token. Logging out...");
            handleLogout();
            return null;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("avatar");
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };

    const ensureTokenIsValid = async () => {
        const token = localStorage.getItem("token");
        if (!token || !verifyToken(token)) {
            return await refreshAccessToken();
        } else {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            return token;
        }
    };

    const checkAuthorization = async () => {
        try {
            const token = await ensureTokenIsValid();
            if (!token) {
                setIsAuthorized(false);
                setLoading(false);
                navigate("/login");
                return;
            }
    
            const response = await axios.get("/api/auth/check-role", {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            const { isAuthorized } = response.data;
            if (isAuthorized) {
                setIsAuthorized(true);
            } else {
                setIsAuthorized(false);
                navigate("/not-authorized");
            }
        } catch (error) {
            console.error("Authorization check failed:", error);
            setIsAuthorized(false);
            handleLogout();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuthorization();
    }, []);

    useEffect(() => {
        if (!isAuthorized) return;

        const fetchData = async () => {
            setLoading(true);
            try {
                if (activeSection === "users") {
                    const response = await axios.get("/api/mod/users");
                    setUsers(response.data);
                } else if (activeSection === "categories") {
                    const response = await axios.get("/api/mod/categories");
                    setCategories(response.data);
                } else if (activeSection === "reports") {
                    const response = await axios.get("/api/mod/reports");
                    setReports(response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                if (error.response && error.response.status === 401) {
                    handleLogout();
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [activeSection, isAuthorized]);

    const toggleReportExpansion = (reportId) => {
        setExpandedReports((prev) => ({
            ...prev,
            [reportId]: !prev[reportId],
        }));
    };

    const handleUserAction = async (userId, action) => {
        if (!isAuthorized) return;

        try {
            if (action === "ban") {
                const banReason = prompt("Please enter a reason for banning this user:");
                if (!banReason) {
                    alert("Ban reason is required.");
                    return;
                }
                await axios.post(`/api/mod/users/${userId}/ban`, { reason: banReason });
                setUsers(users.map((user) =>
                    user._id === userId ? { ...user, banned: true, banReason } : user
                ));
            } else if (action === "unban") {
                await axios.post(`/api/mod/users/${userId}/unban`);
                setUsers(users.map((user) =>
                    user._id === userId ? { ...user, banned: false, banReason: null } : user
                ));
            } else if (action === "verify") {
                await axios.post(`/api/mod/users/${userId}/verify`);
                setUsers(users.map((user) =>
                    user._id === userId ? { ...user, isVerified: true } : user
                ));
            }
        } catch (error) {
            console.error(`Error performing ${action} action:`, error);
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const updateCategoryStatus = async (slug, status) => {
        if (!isAuthorized) return;

        try {
            await axios.put(`/api/mod/categories/${slug}/status`, { status });
            setCategories(
                categories.map((category) =>
                    category.slug === slug
                        ? { ...category, status }
                        : category
                )
            );
        } catch (error) {
            console.error("Error updating category status:", error);
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const resolveReports = async (categoryId) => {
        if (!isAuthorized) return;

        try {
            await axios.post(`/api/mod/reports/${categoryId}/resolve`);
            setReports(
                reports.filter((report) => report._id !== categoryId)
            );
        } catch (error) {
            console.error("Error resolving reports:", error);
            if (error.response && error.response.status === 401) {
                handleLogout();
            }
        }
    };

    const filteredUsers = users.filter((user) => {
        const matchesSearch = user.username.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesRole = roleFilter === "all" || user.role === roleFilter;
        return matchesSearch && matchesRole;
    });

    const userCounts = {
        total: users.length,
        users: users.filter(user => user.role === "user").length,
        members: users.filter(user => user.role === "member").length
    };

    const openBanDetailsModal = (user) => {
        setSelectedUserBanDetails({
            username: user.username,
            banReason: user.banReason,
            banDate: user.banDate,
            bannedBy: user.bannedBy?.username || "Unknown"
        });
        setShowBanDetailsModal(true);
    };

    const closeBanDetailsModal = () => {
        setShowBanDetailsModal(false);
        setSelectedUserBanDetails(null);
    };

    const toggleIpHistory = (userId) => {
        setShowIpHistory((prev) => ({
            ...prev,
            [userId]: !prev[userId],
        }));
    };

    const toggleUserReports = (userId) => {
        setShowUserReports((prev) => ({
            ...prev,
            [userId]: !prev[userId],
        }));
    };

    if (loading) {
        return <div className="loading-message">Loading...</div>;
    }

    if (!isAuthorized) {
        return null;
    }

    return (
        <div className="moderator-panel">
            <h1 className="moderator-panel-title">Moderator Panel</h1>
            <div className="moderator-panel-header">
                <button
                    className={activeSection === "users" ? "active" : ""}
                    onClick={() => setActiveSection("users")}
                >
                    Users
                </button>
                <button
                    className={activeSection === "categories" ? "active" : ""}
                    onClick={() => setActiveSection("categories")}
                >
                    Categories
                </button>
                <button
                    className={activeSection === "reports" ? "active" : ""}
                    onClick={() => setActiveSection("reports")}
                >
                    Reports
                </button>
            </div>

            {loading && <p className="text-center">Loading...</p>}

            {activeSection === "users" && (
                <div>
                    <h2>Manage Users</h2>
                    <div className="users-controls">
                        <div className="user-counts">
                            <span>Total: {userCounts.total}</span>
                            <span>Users: {userCounts.users}</span>
                            <span>Members: {userCounts.members}</span>
                        </div>
                        <div className="filters">
                            <input
                                type="text"
                                placeholder="Search users..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="search-input-mod"
                            />
                            <select
                                value={roleFilter}
                                onChange={(e) => setRoleFilter(e.target.value)}
                                className="role-filter"
                            >
                                <option value="all">All Roles</option>
                                <option value="user">User</option>
                                <option value="member">Member</option>
                            </select>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>IP Address</th>
                                <th>Role</th>
                                <th>Verified</th>
                                <th>Banned</th>
                                <th>Created At</th>
                                <th>Last Login</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user) => (
                                <React.Fragment key={user._id}>
                                    <tr>
                                        <td>
                                            <a href={`/profile/${user.username}`} className="profile-link">
                                                {user.username}
                                            </a>
                                        </td>
                                        <td>{user.email || <span className="na-indicator">✘</span>}</td>
                                        <td>{user.ipAddress || <span className="na-indicator">✘</span>}</td>
                                        <td>{user.role}</td>
                                        <td>
                                            {user.isVerified ? (
                                                <span className="status-verified">✔</span>
                                            ) : (
                                                <span className="status-not-verified">✘</span>
                                            )}
                                        </td>
                                        <td>
                                            {user.banned ? (
                                                <>
                                                    <span className="status-banned">✔</span>
                                                    <button
                                                        className="ban-details-btn"
                                                        onClick={() => openBanDetailsModal(user)}
                                                    >
                                                        Details
                                                    </button>
                                                </>
                                            ) : (
                                                <span className="status-not-banned">✘</span>
                                            )}
                                        </td>
                                        <td>
                                            {user.createdAt
                                                ? new Date(user.createdAt).toLocaleDateString("en-US", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })
                                                : <span className="na-indicator">✘</span>}
                                        </td>
                                        <td>
                                            {user.lastLogin
                                                ? new Date(user.lastLogin).toLocaleDateString("en-US", {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                    hour: "numeric",
                                                    minute: "numeric",
                                                })
                                                : <span className="na-indicator">✘</span>}
                                        </td>
                                        <td>
                                            <div className="action-buttons">
                                                <button
                                                    className={user.banned ? "unban" : "ban"}
                                                    onClick={() =>
                                                        handleUserAction(user._id, user.banned ? "unban" : "ban")
                                                    }
                                                >
                                                    {user.banned ? "Unban" : "Ban"}
                                                </button>
                                                {!user.isVerified && (
                                                    <button
                                                        className="verify"
                                                        onClick={() => handleUserAction(user._id, "verify")}
                                                    >
                                                        Verify
                                                    </button>
                                                )}
                                                <button
                                                    className="toggle-ip-history-btn"
                                                    onClick={() => toggleIpHistory(user._id)}
                                                >
                                                    {showIpHistory[user._id] ? "Hide IP History" : "Show IP History"}
                                                </button>
                                                <button
                                                    className="toggle-reports-btn"
                                                    onClick={() => toggleUserReports(user._id)}
                                                >
                                                    {showUserReports[user._id] ? "Hide Reports" : "Show Reports"}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    {(showIpHistory[user._id] || showUserReports[user._id]) && (
                                        <tr>
                                            <td colSpan="9" className="expandable-section">
                                                {showIpHistory[user._id] && (
                                                    <div className="ip-history">
                                                        <h5>IP History (Latest to Oldest):</h5>
                                                        <ul>
                                                            {user.ipHistory && user.ipHistory.length > 0 ? (
                                                                [...user.ipHistory]
                                                                    .reverse()
                                                                    .map((ip, index) => (
                                                                        <li key={`${ip}-${index}`}>{ip}</li>
                                                                    ))
                                                            ) : (
                                                                <li>No IP history available.</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                                {showUserReports[user._id] && (
                                                    <div className="reports-list">
                                                        <h5>User Reports:</h5>
                                                        <ul>
                                                            {user.reports && user.reports.length > 0 ? (
                                                                user.reports.map((report, idx) => (
                                                                    <li key={idx}>
                                                                        <p><strong>Reported By:</strong> {report.reportedBy || "Unknown User"}</p>
                                                                        <p><strong>Reason:</strong> {report.reason}</p>
                                                                        <p><strong>Description:</strong> {report.description || "No description provided"}</p>
                                                                        <p><strong>Reported At:</strong> {new Date(report.date).toLocaleString()}</p>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <p>No reports available for this user.</p>
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>

                    {showBanDetailsModal && selectedUserBanDetails && (
                        <div className="modal">
                            <div className="modal-content">
                                <h3>Ban Details for {selectedUserBanDetails.username}</h3>
                                <p><strong>Reason:</strong> {selectedUserBanDetails.banReason || "No reason provided"}</p>
                                <p><strong>Banned On:</strong> {selectedUserBanDetails.banDate ? new Date(selectedUserBanDetails.banDate).toLocaleString() : "Unknown date"}</p>
                                <p>
                                    <strong>Banned By:</strong>{" "}
                                    {selectedUserBanDetails.bannedBy ? (
                                        <a href={`/profile/${selectedUserBanDetails.bannedBy}`} className="profile-link">
                                            {selectedUserBanDetails.bannedBy}
                                        </a>
                                    ) : (
                                        "Unknown"
                                    )}
                                </p>
                                <div className="modal-button-container">
                                    <button className="close-btn" onClick={closeBanDetailsModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {activeSection === "categories" && (
                <div>
                    <h2>Manage Categories</h2>
                    <input
                        type="text"
                        placeholder="Search categories..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.filter((category) =>
                                category.name.toLowerCase().includes(searchQuery.toLowerCase())
                            ).map((category) => (
                                <tr key={category.slug}>
                                    <td>{category.name}</td>
                                    <td>{getStatusIcon(category.status)}</td>
                                    <td>
                                        <button
                                            className="status-button green"
                                            title="Set status to Working"
                                            onClick={() => updateCategoryStatus(category.slug, "green")}
                                        >
                                            <FaCheckCircle className="status-icon" />
                                        </button>
                                        <button
                                            className="status-button yellow"
                                            title="Set status to Possible Issues"
                                            onClick={() => updateCategoryStatus(category.slug, "yellow")}
                                        >
                                            <FaExclamationTriangle className="status-icon" />
                                        </button>
                                        <button
                                            className="status-button red"
                                            title="Set status to Broken"
                                            onClick={() => updateCategoryStatus(category.slug, "red")}
                                        >
                                            <FaTimesCircle className="status-icon" />
                                        </button>
                                        <button
                                            className="status-button clear"
                                            title="Clear manually set status"
                                            onClick={() => updateCategoryStatus(category.slug, "clear")}
                                        >
                                            <FaUndo className="status-icon" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeSection === "reports" && (
                <div>
                    <h2>Manage Reports</h2>
                    <div className="reports-list">
                        {reports.map((report) => (
                            <div key={report._id} className="report-item">
                                <div
                                    className="report-summary"
                                    onClick={() => toggleReportExpansion(report._id)}
                                >
                                    <span>{report.name}</span>
                                    <span>{report.reports.length} reports</span>
                                    <button className="expand-button">
                                        {expandedReports[report._id] ? "Hide Details" : "View Details"}
                                    </button>
                                </div>
                                {expandedReports[report._id] && (
                                    <div className="report-details">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Reported By</th>
                                                    <th>Reason</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {report.reports.map((r, index) => (
                                                    <tr key={`${report._id}-${index}`}>
                                                        <td>{r.userId.username}</td>
                                                        <td>{r.description || "No reason provided"}</td>
                                                        <td>
                                                            <button onClick={() => resolveReports(report._id)}>
                                                                Resolve
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ModeratorPanel;