import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer
    style={{
      backgroundColor: "var(--section-background)",
      padding: "10px 20px",
      textAlign: "center",
      borderTop: "1px solid var(--border-color)",
      fontSize: "0.9rem",
      marginTop: "20px",
    }}
  >
    <Link
      to="/privacy-policy"
      style={{
        margin: "0 8px",
        color: "var(--link-color)",
        textDecoration: "none",
      }}
    >
      Privacy Policy
    </Link>
    <Link
      to="/terms-of-service" // Added ToS link
      style={{
        margin: "0 8px",
        color: "var(--link-color)",
        textDecoration: "none",
      }}
    >
      Terms of Service
    </Link>
    <Link
      to="/contact"
      style={{
        margin: "0 8px",
        color: "var(--link-color)",
        textDecoration: "none",
      }}
    >
      Contact Us
    </Link>
    <Link
      to="/support"
      style={{
        margin: "0 8px",
        color: "var(--link-color)",
        textDecoration: "none",
      }}
    >
      Support
    </Link>
  </footer>
);

export default Footer;