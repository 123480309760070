import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserDashboard.css';
import { FaEnvelope, FaDiscord, FaBell } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { 
    faUser, 
    faShieldAlt, 
    faEnvelope, 
    faBell, 
    faListAlt, 
    faUserSlash, 
    faCog, 
    faSignOutAlt 
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Picker from '@emoji-mart/react'; // Import emoji picker
import data from '@emoji-mart/data'; // Emoji data
import { BADGE_ICONS } from "../constants/badges";

function UserDashboard() {
    const [userData, setUserData] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [newEmail, setNewEmail] = useState(''); 
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetypePassword] = useState('');
    const [avatarFile, setAvatarFile] = useState(null);
    const [error, setError] = useState('');
    const [logs, setLogs] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [activeSection, setActiveSection] = useState('profile'); // Track active section
    const navigate = useNavigate();
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [messagesData, setMessagesData] = useState([]); // For fetched messages
    const [notification, setNotification] = useState(''); // For success/error notifications
    const [activeChat, setActiveChat] = useState(null); // Track active chat user
    const [newMessage, setNewMessage] = useState('');
    const [recipientUsername, setRecipientUsername] = useState('');
    const [newRecipient, setNewRecipient] = useState(''); // Track new recipient
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const [allowMessages, setAllowMessages] = useState(null);
    const [receiveEmailAnnouncements, setReceiveEmailAnnouncements] = useState(false);
    const [showVotes, setShowVotes] = useState(null);
    const [showRequests, setShowRequests] = useState(null);
    const [showUsername, setShowUsername] = useState(null);
    const [sendEmailNotifications, setSendEmailNotifications] = useState(null);
    const [showDiscordInfo, setShowDiscordInfo] = useState(false);
    const [systemMessages, setSystemMessages] = useState([]);
    const [activeBadge, setActiveBadge] = useState(null);
    const isMobile = window.matchMedia("(max-width: 480px)").matches;
    const [unreadSystemMessageCount, setUnreadSystemMessageCount] = useState(0);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
    const [aboutMe, setAboutMe] = useState("");
    const [isEditingAboutMe, setIsEditingAboutMe] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [showAllConversations, setShowAllConversations] = useState(false);
    const [savedTables, setSavedTables] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Set how many items to show per page
    const totalPages = Math.ceil(savedTables.length / itemsPerPage);

    const fetchSavedTables = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/api/auth/saved-tables', {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Access `savedTables` array from the response
            setSavedTables(response.data.savedTables || []);
        } catch (err) {
            console.error('Error fetching saved tables:', err);
            setSavedTables([]); // Fallback to an empty array on error
        }
    };
     
    const paginatedTables = savedTables.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );    

    


    useEffect(() => {
        if (activeSection === 'savedTables') {
            fetchSavedTables();
        }
    }, [activeSection]);
    

    // Listen for theme changes
    useEffect(() => {
        const handleThemeChange = () => {
            const storedTheme = localStorage.getItem("theme");
            setTheme(storedTheme);
        };

        window.addEventListener("themeChange", handleThemeChange);

        return () => {
            window.removeEventListener("themeChange", handleThemeChange);
        };
    }, []);

    const handleToggleEmailNotifications = async () => {
        try {
            const token = localStorage.getItem("token");
            const updatedValue = !sendEmailNotifications; // Toggle the current value
    
            await axios.put(
                "/api/auth/send-email-notifications",
                { sendEmailNotifications: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setSendEmailNotifications(updatedValue); // Update state after success
            setNotification({ type: "success", message: "Email notification settings updated successfully." });
        } catch (err) {
            console.error("Error updating email notification settings:", err);
            setNotification({ type: "error", message: "Failed to update email notification settings." });
        }
    };

    const handleToggleEmailAnnouncements = async () => {
        try {
            const token = localStorage.getItem('token');
            const updatedValue = !receiveEmailAnnouncements;
    
            await axios.put(
                '/api/auth/email-announcements',
                { receiveEmailAnnouncements: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setReceiveEmailAnnouncements(updatedValue);
            setNotification({
                type: 'success',
                message: 'Email announcements setting updated successfully.',
            });
        } catch (err) {
            console.error('Error updating email announcements setting:', err);
            setNotification({
                type: 'error',
                message: 'Failed to update email announcements setting.',
            });
        }
    };

    // Handle Table Download
    const handleTableDownload = async (table) => {
        try {
            const response = await axios.get(`/api/admin/download-table/${table._id}`, {
                responseType: 'blob', // Expect binary data (e.g., files)
            });

            // Create a blob URL for the downloaded data
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            // Set the file name dynamically
            link.setAttribute('download', `${table.category.slug}-v${table.version}.CT`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Optionally, refresh tables to update the download count
            fetchSavedTables();
        } catch (error) {
            console.error('Error downloading table:', error);
            setNotification({
                type: 'error',
                message: 'Failed to download the table.',
            });
        }
    };


    const handleToggleMessages = async () => {
        try {
            const token = localStorage.getItem('token');
            const updatedValue = !allowMessages; // Toggle the current value
            await axios.put(
                '/api/auth/allow-messages',
                { allowMessages: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setAllowMessages(updatedValue); // Update state after success
            setNotification({
                type: 'success',
                message: 'Message settings updated successfully.',
            });
        } catch (err) {
            console.error('Error updating message settings:', err);
            setNotification({
                type: 'error',
                message: 'Failed to update message settings.',
            });
        }
    };
    
    function convertLinksToAnchors(text) {
        const urlRegex = /(\bhttps?:\/\/[^\s/$.?#].[^\s]*)/gi; // Matches URLs
        return text
            .split("\n") // Split text by newlines
            .map((line) => {
                return line.replace(
                    urlRegex,
                    (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
                );
            })
            .join("<br />"); // Ensure proper line breaks
    };
    
    const getLimitedConversations = () => {
        const conversations = getConversationsSorted().filter(
            (conversation) => conversation.user.username !== "Announcements" // Exclude announcements
        );
        return showAllConversations ? conversations : conversations.slice(0, 5); // Show all or limit to 5
    };

    const handleEmojiSelect = (emoji) => {
        setNewMessage((prevMessage) => prevMessage + emoji.native); // Append selected emoji to the message
    };

    
    const toggleTooltip = (badge) => {
        if (isMobile) {
            setActiveBadge((prev) => (prev === badge ? null : badge));
        }
    };

    const handleShowVotesToggle = async () => {
        try {
            const token = localStorage.getItem("token");
            const updatedValue = !showVotes; // Toggle the current value
    
            const response = await axios.put(
                "/api/auth/show-votes",
                { showVotes: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setShowVotes(updatedValue); // Update the state
            setNotification({ type: "success", message: "Votes visibility updated successfully." });
        } catch (err) {
            console.error("Error updating votes visibility:", err);
            setNotification({ type: "error", message: "Failed to update votes visibility." });
        }
    };
    
    const getAvatarUrl = (avatar, username = "User") =>
        avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&bold=true`;
    

    const handleShowRequestsToggle = async () => {
        try {
            const token = localStorage.getItem('token');
            const updatedValue = !showRequests; // Toggle the current value
    
            await axios.put(
                '/api/auth/show-requests',
                { showRequests: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setShowRequests(updatedValue); // Update the state after success
            setNotification({ type: 'success', message: 'Requests visibility updated successfully.' });
        } catch (err) {
            console.error('Error updating requests visibility:', err);
            setNotification({ type: 'error', message: 'Failed to update requests visibility.' });
        }
    };
    
    // Fetch unread counts
    const fetchUnreadCount = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('/api/auth/messages/unread', {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            // Unread count from the API
            const totalUnreadCount = response.data.unreadCount;
            const systemUnreadCount = response.data.unreadCountSystem; // Assuming the backend sends a systemUnreadCount for system messages
            const privateUnreadCount = totalUnreadCount - systemUnreadCount; // Calculate the unread private messages count
    
            // Set the total unread count for system messages and private messages
            setUnreadMessageCount(privateUnreadCount); // Set private messages unread count
            setUnreadSystemMessageCount(systemUnreadCount); // Set system messages unread count
    
        } catch (err) {
            console.error('Error fetching unread counts:', err);
            if (err.response?.status === 401) {
                await refreshAccessToken();
            }
        }
    };
    
    const handleEnable2FA = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                '/api/auth/enable-2fa',
                {},
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setQrCode(response.data.qrCode); // QR code returned by the backend
        } catch (err) {
            console.error('Error enabling 2FA:', err);
            setNotification({ type: 'error', message: 'Failed to enable 2FA.' });
        }
    };
    
    const handleVerify2FA = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                '/api/auth/verify-2fa',
                { code: verificationCode },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setNotification({ type: 'success', message: '2FA enabled successfully!' });
            setQrCode(null); // Clear QR code
        } catch (err) {
            console.error('Error verifying 2FA:', err);
            setNotification({ type: 'error', message: 'Invalid verification code.' });
        }
    };
    
    const handleDisable2FA = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                '/api/auth/disable-2fa',
                { code: verificationCode }, // Include the 2FA code in the request
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setNotification({ type: 'success', message: '2FA disabled successfully!' });
            setVerificationCode(''); // Clear the input field
            return true; // Indicate success
        } catch (err) {
            console.error('Error disabling 2FA:', err);
            setNotification({ type: 'error', message: 'Invalid 2FA code or failed to disable 2FA.' });
            return false; // Indicate failure
        }
    };
    

    const handleToggleUsername = async () => {
        try {
            const token = localStorage.getItem('token');
            const updatedValue = !showUsername; // Toggle the current value
            await axios.put(
                '/api/auth/show-username',
                { showUsername: updatedValue },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setShowUsername(updatedValue); // Update the state after success
            setNotification({
                type: 'success',
                message: 'Your username visibility preference has been updated.',
            });
        } catch (err) {
            console.error('Error updating username visibility:', err);
            setNotification({
                type: 'error',
                message: 'Failed to update username visibility preference.',
            });
        }
    };
    

    const handleBlockUser = async (usernameToBlock) => {
        if (!usernameToBlock) {
            console.error("Invalid username to block:", usernameToBlock);
            setNotification({ type: 'error', message: 'Invalid user to block.' });
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                '/api/auth/block',
                { usernameToBlock }, // Send the username to the backend
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setNotification({ type: 'success', message: 'User blocked successfully.' });
            fetchUserData(); // Refresh user data after blocking
        } catch (err) {
            console.error('Error blocking user:', err);
            setNotification({ type: 'error', message: 'Failed to block user.' });
        }
    };
    
    
    const handleUnblockUser = async (usernameToUnblock) => {
        if (!usernameToUnblock) {
            console.error("Invalid username to unblock:", usernameToUnblock);
            setNotification({ type: 'error', message: 'Invalid user to unblock.' });
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
            const res = await axios.post(
                '/api/auth/unblock',
                { usernameToUnblock }, // Send the username to the backend
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setNotification({ type: 'success', message: 'User unblocked successfully.' });
    
            // Update the blocked users in the local state
            setUserData((prevData) => ({
                ...prevData,
                blockedUsers: res.data.blockedUsers, // Use the updated blocked users from the response
            }));
        } catch (err) {
            console.error('Error unblocking user:', err);
            setNotification({ type: 'error', message: 'Failed to unblock user.' });
        }
    };
    
    
    
    // Fetch messages when the "Messages" tab is selected
    useEffect(() => {
        fetchUserData();
        if (activeSection === 'messages') {
            fetchMessages();
            fetchUnreadCount();
            fetchUsers();
        }
    }, [activeSection]);

    useEffect(() => {
        const chatContainer = document.getElementById('chatMessagesContainer');
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight; // Scroll to the bottom
        }
    }, [messagesData]);
    

    // Fetch messages
    const fetchMessages = async () => {
        try {
            const token = localStorage.getItem('token');
            const [privateMessagesRes, systemMessagesRes] = await Promise.all([
                axios.get('/api/auth/messages', {
                    headers: { Authorization: `Bearer ${token}` },
                }),
                axios.get('/api/auth/system-messages', {
                    headers: { Authorization: `Bearer ${token}` },
                }),
            ]);
    
            // Set private messages
            setMessagesData(privateMessagesRes.data);
    
            // Set system messages
            setSystemMessages(systemMessagesRes.data);
    
            // Call fetchUnreadCount to update the unread counts after messages are fetched
            await fetchUnreadCount();  // Ensure unread counts are updated
    
        } catch (err) {
            console.error('Error fetching messages:', err);
            if (err.response?.status === 401) {
                await refreshAccessToken();
            }
        }
    };
    


    const getConversationsSorted = () => {
        const conversations = getConversations();
    
        // Sort conversations by the latest message timestamp
        conversations.sort((a, b) => {
            const lastMessageA = a.messages[a.messages.length - 1];
            const lastMessageB = b.messages[b.messages.length - 1];
    
            return new Date(lastMessageB.createdAt) - new Date(lastMessageA.createdAt);
        });
    
        return conversations;
    };
     

    // Fetch all users
    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const res = await axios.get('/api/auth/users', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUsers(res.data);
        } catch (err) {
        }
    };
    
    const markMessagesAsReadForUser = async (username) => {
        try {
            const token = localStorage.getItem("token");
    
            if (username === "system") {
                // Mark system messages as read
                await axios.post(
                    "/api/auth/messages/mark-as-read",
                    { systemMessages: true },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
    
                // Update system messages in state
                setSystemMessages((prevMessages) =>
                    prevMessages.map((msg) => ({
                        ...msg,
                        readBy: msg.readBy ? [...msg.readBy, userData.id] : [userData.id], // Add user ID to readBy
                    }))
                );
    
                // Update unread count for system messages
                setUnreadSystemMessageCount(0);
            } else if (username) {
                // Mark user messages as read
                await axios.post(
                    "/api/auth/messages/mark-as-read",
                    { senderUsername: username },
                    { headers: { Authorization: `Bearer ${token}` } }
                );
    
                // Update private messages in state
                setMessagesData((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.sender.username === username && msg.recipient.username === userData.username
                            ? { ...msg, read: true }
                            : msg
                    )
                );
    
                // Update unread count for private messages
                const updatedUnreadCount = messagesData.filter(
                    (msg) =>
                        msg.sender.username === username &&
                        msg.recipient.username === userData.username &&
                        !msg.read
                ).length;
                setUnreadMessageCount((prevCount) => prevCount - updatedUnreadCount);
            } else {
                console.error("Invalid username or system message flag");
            }
    
            // Dispatch custom event to refresh UI if necessary
            const messageReadEvent = new Event("messageRead");
            window.dispatchEvent(messageReadEvent);
        } catch (err) {
            console.error("Error marking messages as read:", err);
        }
    };
    
    
    // Handle sending messages
    const handleSendMessage = async (e) => {
        e.preventDefault();
    
        if (!activeChat || !newMessage.trim()) {
            setError('Select a user and type a message.');
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                '/api/auth/messages',
                { recipientUsername: activeChat.username, content: newMessage },
                { headers: { Authorization: `Bearer ${token}` } }
            );
    
            setNotification({ type: 'success', message: 'Message sent successfully!' });
            setNewMessage('');
            fetchMessages(); // Refresh messages after sending
        } catch (err) {
            console.error('Error sending message:', err);
            setNotification({
                type: 'error',
                message: err.response?.data?.message || 'Failed to send message.',
            });
        }
    };

    // Group messages by conversations
    const getConversations = () => {
        const grouped = {};
    
        messagesData.forEach((msg) => {
            const otherUser =
                msg.sender.username === userData.username ? msg.recipient : msg.sender;
    
            if (!grouped[otherUser.username]) {
                grouped[otherUser.username] = {
                    user: otherUser,
                    messages: [],
                    unreadCount: 0, // Track unread messages
                };
            }
    
            grouped[otherUser.username].messages.push(msg);
    
            // Increment unread count for messages sent by the other user that are not read
            if (!msg.read && msg.sender.username === otherUser.username) {
                grouped[otherUser.username].unreadCount++;
            }
        });
    
        return Object.values(grouped); // Return grouped conversations as an array
    };

    // Clear error/success messages automatically
    useEffect(() => {
        if (notification.message) {
            const timer = setTimeout(() => setNotification(''), 5000);
            return () => clearTimeout(timer);
        }
    }, [error, notification.message]);

    const handleUpdateAboutMe = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.put(
                "/api/auth/update-about-me",
                { aboutMe },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setNotification({ type: "success", message: response.data.message });
        } catch (err) {
            const errorMessage = err.response?.data?.message || "Failed to update About Me.";
            setNotification({ type: "error", message: errorMessage });
        }
    };    

    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) throw new Error("No token found");
    
            // Verify or refresh token
            if (!verifyToken(token)) {
                const newToken = await refreshAccessToken();
                if (!newToken) throw new Error("Token refresh failed");
            }
    
            const res = await axios.get("/api/auth/dashboard", {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            const data = res.data;
    
            setUserData({
                username: data.username,
                email: data.email,
                role: data.role,
                avatar: data.avatar,
                discordLinked: data.discordLinked,
                discord: data.discord,
                blockedUsers: data.blockedUsers,
                allowMessages: data.allowMessages,
                badges: data.badges,
                showVotes: data.showVotes,
                showRequests: data.showRequests,
                showUsername: data.showUsername,
                twoFactorEnabled: data.twoFactorEnabled,
                sendEmailNotifications: data.sendEmailNotifications, // Fetch email notification setting
                receiveEmailAnnouncements: data.receiveEmailAnnouncements, // Fetch email announcements setting
                savedTables: data.savedTables,
                aboutMe: data.aboutMe,
            });
    
            // Update toggles
            setAllowMessages(data.allowMessages);
            setAboutMe(res.data.aboutMe || "");
            setShowVotes(data.showVotes);
            setShowRequests(data.showRequests);
            setShowUsername(data.showUsername);
            setSendEmailNotifications(data.sendEmailNotifications); // Set email notifications state
            setReceiveEmailAnnouncements(data.receiveEmailAnnouncements); // Set email announcements state
    
            setLogs(data.logs); // Set the logs from the response
            setNotifications(data.notifications); // Set notifications from the response
            setLoading(false);
        } catch (err) {
            console.error("Error fetching user data:", err);
            setError("Session expired. Please log in again.");
            handleLogout();
        }
    };
    
    
    useEffect(() => {
        fetchUserData(); // Fetch user data and unread messages count on load
    }, []);
    
    useEffect(() => {
        // Automatically fetch messages periodically to keep the count in sync
        const interval = setInterval(() => {
            fetchMessages();
        }, 100000); // Fetch every 10 seconds
    
        return () => clearInterval(interval); // Clean up on component unmount
    }, []);
    
    useEffect(() => {
        fetchMessages();
    }, []); // Fetch messages once when the component mounts
    
    useEffect(() => {
        const handleMessageRead = () => {
            fetchMessages(); // Refresh messages and unread count
        };
    
        // Listen for the custom event
        window.addEventListener('messageRead', handleMessageRead);
    
        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('messageRead', handleMessageRead);
        };
    }, []);
    

    // Token Management
    const verifyToken = (token) => {
        const decoded = decodeToken(token);
        if (!decoded) return false;
    
        const currentTime = Math.floor(Date.now() / 1000);
        return decoded.exp > currentTime; // Valid if token expiration is in the future
    };
    
    const decodeToken = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (err) {
            console.error("Invalid token format:", err);
            return null;
        }
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            if (!refreshToken) {
                console.error("No refresh token found in local storage.");
                throw new Error("No refresh token available.");
            }
    
            const response = await axios.post(
                "/api/auth/refresh-token",
                {},
                {
                    headers: {
                        "x-refresh-token": refreshToken,
                    },
                }
            );
    
            const { accessToken, refreshToken: newRefreshToken } = response.data;
    
            // Update local storage with new tokens
            localStorage.setItem("token", accessToken);
            localStorage.setItem("refreshToken", newRefreshToken);
    
            return accessToken;
        } catch (err) {
            console.error("Token refresh failed:", err);
            handleLogout(); // Log out the user if refresh fails
            return null;
        }
    };
    

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("avatar"); // Remove avatar from localStorage on logout
        localStorage.removeItem("redirectAfterLogin");
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };

    const handleAvatarUpdate = async (e) => {
        e.preventDefault();
        if (!avatarFile) {
            setError('Please select a file before updating.');
            return;
        }

        setUpdating(true);
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('avatar', avatarFile);

            await axios.put('/api/auth/update-avatar', formData, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
            });

            setNotification('Avatar updated successfully!');
            setAvatarFile(null);
            fetchUserData();
        } catch (err) {
            setError(err.response?.data?.message || 'Error updating avatar.');
        } finally {
            setUpdating(false);
        }
    };

    // Utility to format Discord username
    const formatDiscordUsername = (discordUsername) => {
        if (!discordUsername || discordUsername === 'Not Connected') return discordUsername;
        const [base, discriminator] = discordUsername.split('#');
        if (discriminator === '0' || discriminator === '000') return base;
        return discordUsername;
    };

    const handleProfileUpdate = async (e) => {
        e.preventDefault();
        setNotification('');
        setError('');
    
        if (!currentPassword && (!newUsername && !newEmail)) {
            setError('Please enter your current password to update your username or email.');
            return;
        }
    
        setUpdating(true);
    
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('username', newUsername);
            formData.append('email', newEmail);
            formData.append('currentPassword', currentPassword);
    
            const res = await axios.put('/api/auth/update', formData, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (res.data.pendingEmail) {
                // If email update requires verification
                setNotification(`Verification email sent to ${res.data.pendingEmail}. Please check your inbox.`);
            } else {
                // For other successful updates
                setNotification('Profile updated successfully!');
            }
    
            // Clear inputs after successful update
            setNewUsername('');
            setNewEmail('');
            setCurrentPassword('');
            fetchUserData();
        } catch (err) {
            // Display the error message from the backend response
            setError(err.response?.data?.message || 'Error updating profile.');
        } finally {
            setUpdating(false);
        }
    };
    
    
    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        setNotification('');
        setError('');

        if (!currentPassword || !newPassword || !retypePassword) {
            setError('Please fill in all the fields.');
            return;
        }

        if (newPassword !== retypePassword) {
            setError('New passwords do not match.');
            return;
        }

        setUpdating(true);

        try {
            const token = localStorage.getItem('token');
            await axios.put('/api/auth/update-password', {
                currentPassword,
                newPassword,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setNotification('Password updated successfully!');
            setCurrentPassword('');
            setNewPassword('');
            setRetypePassword('');
        } catch (err) {
            setError(err.response?.data?.message || 'Error updating password.');
        } finally {
            setUpdating(false);
        }
    };

    const handleConnectDiscord = async () => {
        try {
            const token = localStorage.getItem('token');
            const userId = JSON.parse(atob(token.split('.')[1])).userId;

            const res = await axios.get(`/api/auth/discord/url?userId=${userId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            window.location.href = res.data.url;
        } catch (err) {
            setError('Failed to connect Discord.');
        }
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>User Dashboard - Sintrix.net</title>
                <meta
                    name="description"
                    content="Manage your account, connect with Discord, and update your profile information on your Sintrix.net dashboard."
                />
            </Helmet>

            <div className="sidebar">
                <h2>Dashboard</h2>
                <div className="menu">
                    <button
                        className={activeSection === 'profile' ? 'active' : ''}
                        onClick={() => setActiveSection('profile')}
                    >
                        <FontAwesomeIcon icon={faUser} className="sidebar-icon" />
                        Profile
                    </button>
                    <button
                        className={activeSection === 'security' ? 'active' : ''}
                        onClick={() => setActiveSection('security')}
                    >
                        <FontAwesomeIcon icon={faShieldAlt} className="sidebar-icon" />
                        Security
                    </button>
                    <button
                        className={activeSection === 'messages' ? 'active' : ''}
                        onClick={() => setActiveSection('messages')}
                    >
                        <FontAwesomeIcon icon={faEnvelope} className="sidebar-icon" />
                        Messages
                        {(unreadMessageCount + unreadSystemMessageCount) > 0 && (
                            <span className="unread-count">
                                {unreadMessageCount + unreadSystemMessageCount}
                            </span>
                        )}
                    </button>
                    <button
                        className={activeSection === 'savedTables' ? 'active' : ''}
                        onClick={() => setActiveSection('savedTables')}
                    >
                        <FontAwesomeIcon icon={faFloppyDisk} className="sidebar-icon" />
                        Saved Tables
                    </button>
                    <button
                        className={activeSection === 'notifications' ? 'active' : ''}
                        onClick={() => setActiveSection('notifications')}
                    >
                        <FontAwesomeIcon icon={faBell} className="sidebar-icon" />
                        Table Notifications
                    </button>
                    <button
                        className={activeSection === 'logs' ? 'active' : ''}
                        onClick={() => setActiveSection('logs')}
                    >
                        <FontAwesomeIcon icon={faListAlt} className="sidebar-icon" />
                        Votes & Requests
                    </button>
                    <button
                        className={activeSection === 'blocked' ? 'active' : ''}
                        onClick={() => setActiveSection('blocked')}
                    >
                        <FontAwesomeIcon icon={faUserSlash} className="sidebar-icon" />
                        Blocked Users
                    </button>
                    <button
                        className={activeSection === 'settings' ? 'active' : ''}
                        onClick={() => setActiveSection('settings')}
                    >
                        <FontAwesomeIcon icon={faCog} className="sidebar-icon" />
                        Settings
                    </button>
                    <button onClick={handleLogout} className="logout-button">
                        <FontAwesomeIcon icon={faSignOutAlt} className="sidebar-icon" />
                        Logout
                    </button>
                </div>
            </div>

            <div className="main-content">
                {/* Error and Success Messages */}
                {notification.message && (
                    <div className={`alert ${notification.type === 'error' ? 'alert-error' : 'alert-success'}`}>
                        <span className="alert-icon">
                            {notification.type === 'error' ? '❌' : '✅'}
                        </span>
                        <span className="alert-message">{notification.message}</span>
                        <button
                            className="alert-close"
                            onClick={() => setNotification('')} // Clear the notification when the button is clicked
                            aria-label="Close notification"
                        >
                            &times;
                        </button>
                    </div>
                )}

                {activeSection === 'profile' && (
                    <div className="card profile-section">
                        <div className="profile-header">
                            <div className="avatar-and-info">
                                <div className="avatar-container">
                                    <img
                                        src={getAvatarUrl(userData.avatar, userData.username)}
                                        alt="User Avatar"
                                        className="avatar-image"
                                    />
                                </div>
                                <h3>
                                    {userData.username}{' '}
                                    <span className={`badge ${userData.role.toLowerCase()}`}>
                                        {userData.role}
                                    </span>
                                </h3>
                                {/* Badge Section */}
                                <div className="badge-icons">
                                    {userData?.badges?.map((badge) => (
                                        <div
                                            key={badge}
                                            className={`badge-icon-wrapper ${activeBadge === badge ? "active" : ""}`}
                                            onClick={() => toggleTooltip(badge)}
                                            onMouseLeave={() => isMobile && setActiveBadge(null)} // Close tooltip on mouse leave for mobile
                                        >
                                            <span
                                                className="badge-icon"
                                                role="img"
                                                aria-label={BADGE_ICONS[badge]?.tooltip}
                                            >
                                                {BADGE_ICONS[badge]?.icon || "🏅"}
                                            </span>
                                            <div className="pbtooltip">{BADGE_ICONS[badge]?.tooltip}</div>
                                        </div>
                                    ))}
                                </div>
                                <form onSubmit={handleAvatarUpdate}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => setAvatarFile(e.target.files[0])}
                                        className="avatar-upload-input"
                                    />
                                    <button
                                        type="submit"
                                        className="avatar-update-button"
                                        disabled={updating}
                                    >
                                        {updating ? 'Updating...' : 'Update Avatar'}
                                    </button>
                                </form>
                            </div>
                            <div className="profile-info">
                                <div className="email-container">
                                    <span className="email-icon">
                                        <FaEnvelope />
                                    </span>
                                    <span className="email-text">
                                        {userData.email || 'Not linked'}
                                    </span>
                                </div>
                                <div className="discord-name">
                                    <span className="discord-icon">
                                        <FaDiscord />
                                    </span>
                                    <span className="discord-username">
                                        {userData.discord !== 'Not Connected'
                                            ? formatDiscordUsername(userData.discord)
                                            : 'Not Connected'}
                                    </span>
                                </div>
                                <div className="about-me-section">
                                    <h4 className="about-me-title">About Me</h4>
                                    {isEditingAboutMe ? (
                                        <div className="about-me-edit">
                                            <textarea
                                                value={aboutMe}
                                                onChange={(e) => setAboutMe(e.target.value)}
                                                placeholder="Tell us something about yourself..."
                                                maxLength={500}
                                            />
                                            <div className="about-me-buttons">
                                                <button
                                                    className="save"
                                                    onClick={() => {
                                                        handleUpdateAboutMe();
                                                        setIsEditingAboutMe(false);
                                                    }}
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    className="cancel"
                                                    onClick={() => setIsEditingAboutMe(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="about-me-display">
                                            <p>{aboutMe || "Tell us something about yourself..."}</p>
                                            <button onClick={() => setIsEditingAboutMe(true)}>Edit</button>
                                        </div>
                                    )}
                                </div>
                                {userData.discord === 'Not Connected' && (
                                    <div className="discord-connect-wrapper">
                                        <div className="discord-connect-container">
                                            <button
                                                className="connect-discord-button"
                                                onClick={handleConnectDiscord}
                                            >
                                                Connect Discord
                                            </button>
                                        </div>
                                        <div className="info-button-container">
                                            <span className="info-button">?</span>
                                            <div className="dtooltip">
                                                <h4>Why Link Your Discord?</h4>
                                                <p>By linking your Discord account, you'll unlock the following perks:</p>
                                                <ul>
                                                    <li>Member role on Discord and the website.</li>
                                                    <li>Top priority in the cheat requests queue.</li>
                                                    <li>Higher cheat request limit of 4 per hour (compared to the standard 2).</li>
                                                    <li>Faster support from staff on Discord.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {activeSection === 'settings' && (
                    <div className="card settings-option-container">
                        {/* Privacy Settings */}
                        <div className="settings-category">
                            <h3>Privacy Settings</h3>
                            {[
                                {
                                    label: "Allow private messages",
                                    id: "allowMessagesToggle",
                                    checked: allowMessages,
                                    onChange: handleToggleMessages,
                                },
                                {
                                    label: "Display username on requests",
                                    id: "showUsernameToggle",
                                    checked: showUsername,
                                    onChange: handleToggleUsername,
                                },
                                {
                                    label: "Display votes publicly",
                                    id: "showVotesToggle",
                                    checked: showVotes,
                                    onChange: handleShowVotesToggle,
                                },
                                {
                                    label: "Display submitted requests",
                                    id: "showRequestToggle",
                                    checked: showRequests,
                                    onChange: handleShowRequestsToggle,
                                },
                            ].map(({ label, id, checked, onChange }) => (
                                <div key={id} className="settings-option">
                                    <span>{label}</span>
                                    <label className="toggle-switch" htmlFor={id}>
                                        <input
                                            type="checkbox"
                                            id={id}
                                            checked={checked}
                                            onChange={onChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            ))}
                        </div>

                        {/* Notification Settings */}
                        <div className="settings-category">
                            <h3>Notification Settings</h3>
                            {[
                                {
                                    label: "Receive emails for new messages",
                                    id: "sendEmailNotificationsToggle",
                                    checked: sendEmailNotifications,
                                    onChange: handleToggleEmailNotifications,
                                },
                                {
                                    label: "Receive emails about announcements",
                                    id: "receiveEmailAnnouncementsToggle",
                                    checked: receiveEmailAnnouncements,
                                    onChange: handleToggleEmailAnnouncements,
                                },
                            ].map(({ label, id, checked, onChange }) => (
                                <div key={id} className="settings-option">
                                    <span>{label}</span>
                                    <label className="toggle-switch" htmlFor={id}>
                                        <input
                                            type="checkbox"
                                            id={id}
                                            checked={checked}
                                            onChange={onChange}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {activeSection === 'notifications' && (
                    <div id="notifications" className="card notifications-section">
                        <h3>
                            <FaBell /> Notifications
                        </h3>
                        <ul>
                            {notifications.length ? (
                                notifications.map((notif, i) => (
                                    <li key={i}>
                                        <a
                                            href={`/game/${notif.slug}`}
                                            className="notification-link"
                                        >
                                            {notif.name}
                                        </a>
                                    </li>
                                ))
                            ) : (
                                <p>No notifications available.</p>
                            )}
                        </ul>
                    </div>
                )}

                {activeSection === 'security' && (
                    <div className="card security-section">
                        <h3>Security</h3>

                        <div className="security-form">
                            <h4>Update Profile</h4>
                            <form onSubmit={handleProfileUpdate}>
                                <input
                                    type="text"
                                    placeholder="New Username"
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    autoComplete="off"
                                />
                                <input
                                    type="email"
                                    placeholder="New Email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    autoComplete="off"
                                />
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    autoComplete="off"
                                />
                                <button type="submit" disabled={updating}>
                                    {updating ? 'Updating...' : 'Update Profile'}
                                </button>
                            </form>
                        </div>

                        <div className="security-form">
                            <h4>Update Password</h4>
                            <form onSubmit={handlePasswordUpdate}>
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    autoComplete="off"
                                />
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    autoComplete="off"
                                />
                                <input
                                    type="password"
                                    placeholder="Re-Type New Password"
                                    value={retypePassword}
                                    onChange={(e) => setRetypePassword(e.target.value)}
                                    autoComplete="off"
                                />
                                <button type="submit" disabled={updating}>
                                    {updating ? 'Updating...' : 'Update Password'}
                                </button>
                            </form>
                        </div>

                        <div className="security-form">
                            <h4>Two-Factor Authentication</h4>
                            {!userData?.twoFactorEnabled ? (
                                <>
                                    <p>Enable 2FA for an additional layer of security.</p>
                                    <button
                                        onClick={async () => {
                                            await handleEnable2FA();
                                            setVerificationCode(""); // Clear input field
                                            fetchUserData(); // Refresh user data to update button
                                        }}
                                    >
                                        Enable 2FA
                                    </button>
                                    {qrCode && (
                                        <div className="qr-code-container">
                                            <p>Scan the QR code with your authenticator app:</p>
                                            <img src={qrCode} alt="QR Code for 2FA" />
                                            <p>Enter the code below to verify:</p>
                                            <input
                                                type="text"
                                                placeholder="Verification Code"
                                                value={verificationCode}
                                                onChange={(e) => setVerificationCode(e.target.value)}
                                            />
                                            <button
                                                onClick={async () => {
                                                    await handleVerify2FA();
                                                    setVerificationCode(""); // Clear input field
                                                    fetchUserData(); // Refresh user data to update button
                                                }}
                                            >
                                                Verify
                                            </button>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <>
                                    <p>2FA is currently enabled on your account.</p>
                                    <div className="disable-2fa-container">
                                        <p>Enter your 2FA code to disable:</p>
                                        <input
                                            type="text"
                                            placeholder="Enter 2FA Code"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                        <button
                                            onClick={async () => {
                                                const success = await handleDisable2FA();
                                                if (success) {
                                                    setVerificationCode(""); // Clear input field
                                                    fetchUserData(); // Refresh user data
                                                }
                                            }}
                                        >
                                            Disable 2FA
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {activeSection === 'messages' && (
                    <div id="messages" className="card messages-section">
                        <h3>
                            <FaEnvelope /> Messages
                        </h3>

                        {/* Display a warning if allowMessages is disabled */}
                        {!allowMessages && (
                            <div className="alert alert-warning">
                                You have disabled private messages. Other users cannot send messages to you until this is enabled in the settings.
                            </div>
                        )}

                        <div className="messages-wrapper">
                            <div className="conversations-sidebar">
                                <ul>
                                    {/* System Messages Entry - Announcements */}
                                    <li
                                        className={`conversation announcements ${activeChat === "system" ? "active" : ""} ${
                                            unreadSystemMessageCount > 0 ? "unread" : ""
                                        }`}
                                        onClick={() => setActiveChat("system")}
                                    >
                                        <div className="conversation-avatar">
                                            <img
                                                src="https://sintrix.net/sintrix2.png" // Replace with an appropriate icon
                                                alt="System Messages"
                                                className="user-avatar"
                                            />
                                        </div>
                                        <div className="conversation-details">
                                            <span className="conversation-username">Announcements</span>
                                        </div>
                                        {/* Show unread count as badge */}
                                        {unreadSystemMessageCount > 0 && (
                                            <span className="unread-count">
                                                {unreadSystemMessageCount}
                                            </span>
                                        )}
                                    </li>

                                    {/* Sorted User Conversations */}
                                    {getLimitedConversations().map((conversation) => (
                                        <li
                                            key={conversation.user._id}
                                            className={`conversation ${
                                                activeChat?.username === conversation.user.username ? "active" : ""
                                            } ${conversation.unreadCount > 0 ? "unread" : ""}`}
                                            onClick={() => {
                                                setActiveChat(conversation.user);
                                                markMessagesAsReadForUser(conversation.user.username); // Mark as read
                                            }}
                                        >
                                            <div className="conversation-avatar">
                                                <img
                                                    src={getAvatarUrl(conversation.user.avatar, conversation.user.username)}
                                                    alt={`${conversation.user.username}'s avatar`}
                                                    className="user-avatar"
                                                />
                                            </div>
                                            <div className="conversation-details">
                                                <span
                                                    className="conversation-username"
                                                    data-full-username={conversation.user.username}
                                                >
                                                    {conversation.user.username}
                                                </span>
                                                <span
                                                    className={`role-badge ${conversation.user.role?.toLowerCase()}`}
                                                >
                                                    {conversation.user.role}
                                                </span>
                                            </div>
                                            <button
                                                className="block-button"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering the click event for the conversation
                                                    handleBlockUser(conversation.user.username);
                                                }}
                                            >
                                                Block
                                            </button>
                                        </li>
                                    ))}

                                    {/* Show Older / Hide Older Buttons */}
                                    {getConversationsSorted().length > 5 && (
                                        <button
                                            className="toggle-older-button"
                                            onClick={() => setShowAllConversations((prev) => !prev)}
                                        >
                                            {showAllConversations ? "Hide Older" : "Show Older"}
                                        </button>
                                    )}

                                </ul>

                                {/* Start New Chat */}
                                <div className="new-message-section">
                                    <h4>Start a New Chat</h4>
                                    <input
                                        type="text"
                                        placeholder="Search users..."
                                        value={newRecipient}
                                        onChange={(e) => setNewRecipient(e.target.value)}
                                        className="user-search-input"
                                    />
                                    <ul className="user-search-results">
                                        {users
                                            .filter((user) =>
                                                user.username.toLowerCase().includes(newRecipient.toLowerCase())
                                            )
                                            .map((user) => (
                                                <li
                                                    key={user._id}
                                                    className="user-result-item"
                                                    onClick={() => {
                                                        setActiveChat(user);
                                                        setNewRecipient(""); // Clear search after selection
                                                    }}
                                                >
                                                    <div className="conversation-avatar">
                                                        <img
                                                            src={getAvatarUrl(user.avatar, user.username)}
                                                            alt={`${user.username}'s avatar`}
                                                            className="nchat-avatar-image"
                                                        />
                                                    </div>
                                                    <div className="user-result-details">
                                                        <span
                                                            className="user-result-name"
                                                            data-full-username={user.username}
                                                        >
                                                            {user.username}
                                                        </span>
                                                        <span className={`nchat-role-badge ${user.role?.toLowerCase()}`}>
                                                            {user.role}
                                                        </span>
                                                    </div>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>


                            {/* Chat Section */}
                            <div className="chat-section">
                            {activeChat === "system" ? (
                                <>
                                    <h4>Announcements</h4>
                                    <div
                                        className="chat-messages"
                                        id="chatMessagesContainer"
                                        onMouseEnter={() => markMessagesAsReadForUser("system")} // Mark system messages as read on hover
                                    >
                                        {systemMessages
                                            .slice() // Create a copy of the array to avoid mutating the original
                                            .reverse() // Reverse the array to show the latest messages at the bottom
                                            .map((message) => (
                                                <div
                                                    key={message.id}
                                                    className={`chat-message system ${message.readBy?.includes(userData.username) ? "" : "unread"}`}
                                                >
                                                    <div className="chat-message-header">
                                                        {/* Custom Role Badge for Announcements */}
                                                        <span className="role-badge system-badge">Announcements</span>
                                                        <span className="timestamp">
                                                            {new Date(message.createdAt).toLocaleString()}
                                                        </span>
                                                    </div>
                                                    <h5 className="message-title">{message.title}</h5> {/* Display the title */}
                                                    <div
                                                        className="message-content"
                                                        dangerouslySetInnerHTML={{
                                                            __html: convertLinksToAnchors(message.content),
                                                        }}
                                                    ></div>
                                                </div>
                                            ))}
                                    </div>
                                    <div className="chat-input disabled">
                                        <p></p>
                                    </div>
                                </>
                                ) : activeChat ? (
                                    <>
                                        <h4>Chat with {activeChat.username}</h4>
                                        <div className="chat-messages" id="chatMessagesContainer">
                                            {messagesData
                                                .filter(
                                                    (msg) =>
                                                        (msg.sender.username === activeChat.username &&
                                                            msg.recipient.username === userData.username) ||
                                                        (msg.sender.username === userData.username &&
                                                            msg.recipient.username === activeChat.username)
                                                )
                                                .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                                                .map((msg) => (
                                                    <div
                                                        className={`chat-message ${
                                                            msg.sender.username === userData.username ? 'sent' : 'received'
                                                        }`}
                                                        key={msg._id}
                                                    >
                                                        <div className="chat-message-header">
                                                            <span className={`role-badge ${msg.sender.role?.toLowerCase() || 'default'}`}>
                                                                {msg.sender.role || 'User'}
                                                            </span>
                                                            <Link
                                                                to={`/profile/${msg.sender.username}`}
                                                                className="profile-link"
                                                            >
                                                                {msg.sender.username === userData.username ? 'You' : msg.sender.username}
                                                            </Link>
                                                            <span className="timestamp">
                                                                {new Date(msg.createdAt).toLocaleString()}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="chat-message-content"
                                                            dangerouslySetInnerHTML={{
                                                                __html: convertLinksToAnchors(msg.content),
                                                            }}
                                                        ></div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="chat-input">
                                            <textarea
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                onFocus={() => {
                                                    if (activeChat) {
                                                        markMessagesAsReadForUser(activeChat.username);
                                                    }
                                                }}
                                                placeholder="Type a message..."
                                            />

                                            <div className="emoji-picker-wrapper">
                                                <button
                                                    className="emoji-button"
                                                    onClick={() => setShowEmojiPicker((prev) => !prev)}
                                                >
                                                    😊
                                                </button>

                                                {showEmojiPicker && (
                                                    <div className="emoji-picker">
                                                        <Picker
                                                            data={data}
                                                            onEmojiSelect={handleEmojiSelect}
                                                            theme={theme} // Set the theme dynamically
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <button onClick={handleSendMessage}>Send</button>
                                        </div>
                                    </>
                                ) : (
                                    <p>Select a user to start a conversation.</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}


                {activeSection === 'blocked' && (
                    <div id="blocked-users" className="card blocked-users-section">
                        <h3>Blocked Users</h3>
                        <ul>
                            {userData?.blockedUsers?.length > 0 ? (
                                userData.blockedUsers.map((user) => (
                                    <li key={user._id}>
                                        <span className="blocked-user-name">{user.username}</span>
                                        <button
                                            onClick={() => handleUnblockUser(user.username)} // Ensure correct user ID is passed
                                            className="unblock-button"
                                        >
                                            Unblock
                                        </button>
                                    </li>
                                ))
                            ) : (
                                <p>You have not blocked any users.</p>
                            )}
                        </ul>
                    </div>
                )}

                {activeSection === 'savedTables' && (
                    <div id="saved-tables" className="card saved-tables-section">
                        <h3>Saved Tables</h3>
                        {Array.isArray(savedTables) && savedTables.length > 0 ? (
                            <>
                                <ul>
                                    {paginatedTables.map((table) => (
                                        <li key={table._id} className="saved-table-item">
                                            <div>
                                                <strong>{table.category.name}</strong> (v{table.version})
                                            </div>
                                            <button
                                                onClick={() => handleTableDownload(table)}
                                                className="download-table-button"
                                            >
                                                Download
                                            </button>
                                            <button
                                                onClick={async () => {
                                                    try {
                                                        const slug = table.category.slug;
                                                        const token = localStorage.getItem('token');
                                                        await axios.post(
                                                            `/api/auth/categories/${slug}/unsave-latest-table`,
                                                            {},
                                                            { headers: { Authorization: `Bearer ${token}` } }
                                                        );
                                                        fetchSavedTables();
                                                        setNotification({
                                                            type: 'success',
                                                            message: `${table.category.name} (v${table.version}) unsaved successfully.`,
                                                        });
                                                    } catch (err) {
                                                        console.error('Error unsaving table:', err);
                                                        setNotification({ type: 'error', message: 'Failed to unsave the table.' });
                                                    }
                                                }}
                                                className="remove-table-button"
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>

                                {/* Enhanced Pagination Controls */}
                                <div className="pagination-controls">
                                    {/* Previous Button */}
                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                        className="pagination-button"
                                    >
                                        Previous
                                    </button>

                                    {/* Display First Page */}
                                    {currentPage > 2 && (
                                        <>
                                            <button
                                                onClick={() => setCurrentPage(1)}
                                                className="pagination-button"
                                            >
                                                1
                                            </button>
                                            {currentPage > 3 && (
                                                <span className="pagination-ellipsis">...</span>
                                            )}
                                        </>
                                    )}

                                    {/* Current, Previous, and Next Pages */}
                                    {Array.from({ length: 5 }, (_, i) => currentPage - 2 + i).map((page) => {
                                        if (page > 0 && page <= totalPages) {
                                            return (
                                                <button
                                                    key={page}
                                                    onClick={() => setCurrentPage(page)}
                                                    className={`pagination-button ${
                                                        currentPage === page ? 'active' : ''
                                                    }`}
                                                >
                                                    {page}
                                                </button>
                                            );
                                        }
                                        return null;
                                    })}

                                    {/* Display Last Page */}
                                    {currentPage < totalPages - 1 && (
                                        <>
                                            {currentPage < totalPages - 2 && (
                                                <span className="pagination-ellipsis">...</span>
                                            )}
                                            <button
                                                onClick={() => setCurrentPage(totalPages)}
                                                className="pagination-button"
                                            >
                                                {totalPages}
                                            </button>
                                        </>
                                    )}

                                    {/* Next Button */}
                                    <button
                                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                        className="pagination-button"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <p>No saved tables found.</p>
                        )}
                    </div>
                )}


                {activeSection === 'logs' && (
                    <div id="logs" className="card logs-section">
                        <h3>Votes & Submitted Requests</h3>
                        {logs.length > 0 ? (
                            <ul>
                                {logs.map((log, i) => (
                                    <li key={i}>
                                        <strong>{log.activity}</strong>
                                        {log.gameName && (
                                            <>
                                                {log.status === "completed" ? (
                                                    <a
                                                        href={`/game/${log.slug || log.gameName.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-|-$/g, "")}`}
                                                        className="game-name"
                                                    >
                                                        {log.gameName}
                                                    </a>
                                                ) : (
                                                    <span className="game-name">{log.gameName}</span> // Non-clickable for non-completed
                                                )}
                                            </>
                                        )}
                                        {log.status ? (
                                            <span
                                                className={`status-badge ${log.status.toLowerCase()}`}
                                            >
                                                {log.status}
                                            </span>
                                        ) : (
                                            <span className="status-badge placeholder">&nbsp;</span> // Placeholder for alignment
                                        )}
                                        <span className="timestamp">
                                            {new Date(log.timestamp).toLocaleString()}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No logs available.</p>
                        )}


                    </div>
                )}
            </div>
        </div>
    );
}

export default UserDashboard;
