import React from 'react';

const PrivacyPolicy = () => (
  <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
    <h1>Privacy Policy</h1>
    <p>Last Updated: March 2, 2025</p>

    <h2>1. Introduction</h2>
    <p>
      Welcome to sintrix.net ("we," "us," or "our"). We are committed to safeguarding your privacy and ensuring the security of your personal data. 
      This Privacy Policy outlines how our website ("Site") collects, uses, shares, and protects your information through cookies, account management, 
      third-party logins, and related technologies. This policy applies to all users globally and complies with applicable data protection laws, 
      including but not limited to the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).
    </p>

    <h2>2. Data We Collect</h2>
    <p>
      We collect only the information necessary to provide you with a seamless, secure, and personalized experience on our Site. The categories of data we collect include:
    </p>
    <ul>
      <li>
        <strong>Cookies and Session Data:</strong> We use cookies and similar technologies to manage login sessions, store user preferences (e.g., dark mode, NSFW settings), 
        and ensure Site functionality. These include authentication tokens to keep you logged in securely. You may manage cookie preferences via our consent banner on your first visit.
      </li>
      <li>
        <strong>Account Information:</strong> When you create or update an account, we collect your username, email address, and password. Passwords are encrypted using 
        industry-standard hashing algorithms (e.g., bcrypt) and are never stored in plain text.
      </li>
      <li>
        <strong>Security and Usage Data:</strong> We collect IP addresses, browser type, device information, and last login details for security purposes, account management, 
        and troubleshooting. This helps us detect and prevent unauthorized access or abuse of our services.
      </li>
    </ul>

    <h2>3. Legal Basis for Processing</h2>
    <p>
      We process your personal data based on the following legal grounds:
    </p>
    <ul>
      <li><strong>Consent:</strong> For cookies (except strictly necessary ones) and optional third-party account connections.</li>
      <li><strong>Contractual Necessity:</strong> To provide account services and Site functionality as requested by you.</li>
      <li><strong>Legitimate Interests:</strong> For security measures, troubleshooting, and improving our services, provided your rights are not overridden.</li>
      <li><strong>Legal Obligation:</strong> To comply with applicable laws or regulations (e.g., tax or reporting requirements).</li>
    </ul>

    <h2>4. Third-Party Logins and Account Connections</h2>
    <p>
      We offer third-party login options for your convenience. Here’s how they work:
    </p>
    <ul>
      <li>
        <strong>Steam Login:</strong> Authentication is handled by Steam via OAuth. We do not access or store your Steam credentials or personal data beyond a temporary 
        session token, which is deleted when you log out.
      </li>
      <li>
        <strong>Discord Account Connection:</strong> Connecting your Discord account for perks requires your consent. We store only your Discord username and no other personal data.
      </li>
    </ul>

    <h2>5. Cookies and Similar Technologies</h2>
    <p>
      Cookies are small text files stored on your device to recognize you, remember settings, and enhance your experience. We use:
    </p>
    <ul>
      <li><strong>Strictly Necessary Cookies:</strong> Essential for Site operation (e.g., login authentication).</li>
      <li><strong>Preference Cookies:</strong> To save your settings (e.g., dark mode).</li>
      <li><strong>Analytics Cookies:</strong> To understand Site usage and improve performance (optional, with consent).</li>
      <li><strong>Advertising Cookies:</strong> Managed by third parties for personalized ads (optional, with consent).</li>
    </ul>
    <p>
      You can manage or revoke consent for non-essential cookies at any time via our cookie settings tool.
    </p>

    <h2>6. Advertising Cookies and Third-Party Vendors</h2>
    <p>
      We partner with third-party vendors, such as Google, to display ads on our Site. These vendors may:
    </p>
    <ul>
      <li>
        <strong>Use Cookies:</strong> To serve personalized ads based on your prior visits to our Site or other websites.
      </li>
      <li>
        <strong>Share Data:</strong> With their partners for ad targeting, subject to their privacy policies.
      </li>
    </ul>
    <p>
      For California residents, you may opt out of the "sale" of your personal information under the CCPA by clicking "Do Not Sell My Personal Information" in our footer or cookie settings.
    </p>

    <h2>7. Your Choices and Opting Out</h2>
    <p>
      You have control over your data:
    </p>
    <ul>
      <li>Adjust cookie preferences via our consent tool.</li>
      <li>Opt out of personalized ads via <a href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer">Google Ads Settings</a>, 
        <a href="https://www.aboutads.info/" target="_blank" rel="noopener noreferrer">www.aboutads.info</a>, or the 
        <a href="https://www.networkadvertising.org/" target="_blank" rel="noopener noreferrer">Network Advertising Initiative</a>.</li>
      <li>Disable third-party account connections in your account settings.</li>
    </ul>
    <p>
      Note: Opting out of personalized ads does not stop all ads or data collection for essential purposes.
    </p>

    <h2>8. Data Subject Rights</h2>
    <p>
      Depending on your location, you may have the following rights:
    </p>
    <ul>
      <li><strong>Access:</strong> Request a copy of your personal data.</li>
      <li><strong>Rectification:</strong> Correct inaccurate or incomplete data.</li>
      <li><strong>Erasure:</strong> Request deletion of your data (subject to legal exceptions).</li>
      <li><strong>Restriction:</strong> Limit processing of your data in certain cases.</li>
      <li><strong>Objection:</strong> Object to processing based on legitimate interests or for direct marketing.</li>
      <li><strong>Data Portability:</strong> Receive your data in a structured, machine-readable format.</li>
      <li><strong>Withdraw Consent:</strong> Revoke consent where applicable (e.g., cookies).</li>
    </ul>
    <p>
      To exercise these rights, contact us at <a href="mailto:support@sintrix.net"> support@sintrix.net</a>. We will respond within 30 days (or as required by law).
      For GDPR-related complaints, you may also contact your local data protection authority.
    </p>

    <h2>9. Data Retention and Security</h2>
    <p>
      We retain your data only as long as necessary for the purposes outlined in this policy or as required by law. For example:
    </p>
    <ul>
      <li>Account data is kept while your account is active and for a reasonable period after closure.</li>
      <li>Session data is deleted upon logout or after a short period of inactivity.</li>
    </ul>
    <p>
      We implement technical and organizational measures (e.g., encryption, access controls) to protect your data from unauthorized access, loss, or alteration.
    </p>

    <h2>10. International Data Transfers</h2>
    <p>
      Your data may be transferred to and processed in countries outside your jurisdiction, including the United States. We ensure compliance with applicable laws 
      (e.g., GDPR’s Standard Contractual Clauses or CCPA requirements) and use safeguards to protect your data during transfers.
    </p>

    <h2>11. Children’s Privacy</h2>
    <p>
      Our Site is not intended for individuals under 16 (or the applicable age of consent in your jurisdiction). We do not knowingly collect data from children. 
      If you believe we have such data, please contact us immediately.
    </p>

    <h2>12. Changes to This Privacy Policy</h2>
    <p>
      We may update this policy to reflect changes in our practices, technologies, or legal requirements. Updates will be posted here with a revised "Last Updated" date. 
      Significant changes will be notified via email or a prominent Site notice where required by law.
    </p>

    <h2>13. Contact Us</h2>
    <p>
      For questions, concerns, or to exercise your rights, please reach out to:  
      <a href="mailto:support@sintrix.net"> support@sintrix.net</a>
    </p>
  </div>
);

export default PrivacyPolicy;