import React, { useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { UserProvider } from './UserContext'; // Add this import
import TosCheck from './TosCheck'; // Add this import
import Home from "./Home";
import AdminPanel from "./AdminPanel";
import ModeratorPanel from "./ModeratorPanel";
import Login from "./components/Login";
import Signup from "./components/Signup";
import CheatRequests from "./components/CheatRequests";
import UserDashboard from "./components/UserDashboard";
import PublicProfile from "./components/PublicProfile";
import Tables from "./components/Games";
import Logout from "./components/Logout";
import Navbar from "./components/Navbar";
import CategoryDetail from "./components/CategoryDetail";
import DiscordButton from "./components/DiscordButton";
import AppPanel from "./components/AppPanel";
import ErrorPage from "./components/ErrorPage";
import Credits from "./components/Credits";
import Support from "./components/Support";
import ToolsPage from "./components/ToolsPage";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPass from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ContactUs from "./components/ContactUs";
import TermsOfService from "./components/TermsOfService";
import Footer from "./components/Footer";
import CookieConsent from "react-cookie-consent";
import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem("theme") === "dark");

  useEffect(() => {
    document.body.className = isDarkMode ? "dark" : "light";
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const isLoginError = error.config.url.includes("/api/auth/login");
        if (error.response && error.response.status === 403 && !isLoginError) {
          handleLogout();
        }
        return Promise.reject(error);
      }
    );
    return () => axios.interceptors.response.eject(interceptor);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  };

  useEffect(() => {
    const headTags = document.querySelectorAll('head meta[name="description"], head meta[property^="og:"], head title');
    headTags.forEach(tag => {
      if (tag.getAttribute('data-react-helmet') === null) {
        tag.parentNode.removeChild(tag);
      }
    });
  }, []);

  useEffect(() => {
    console.log(
      `%cWelcome to Sintrix 🎉\n\n\n\n` +
      `%cHappy hacking! 🚀\n\n\n\n`,
      "color: #8319d4; font-size: 20px; font-weight: bold; text-shadow: 2px 2px #6a0dab;",
      "font-size: 15px; font-weight: bold; text-shadow: 2px 2px #6a0dab;"
    );
  }, []);

  return (
    <UserProvider>
      <Router>
        <div>
          <Navbar />
          <TosCheck>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPass />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/requests" element={<CheatRequests />} />
              <Route path="/games" element={<Tables />} />
              <Route path="/tools" element={<ToolsPage />} />
              <Route path="/dashboard" element={<UserDashboard />} />
              <Route path="/profile/:username" element={<PublicProfile />} />
              <Route path="/admin" element={<AdminPanel />} />
              <Route path="/mod" element={<ModeratorPanel />} />
              <Route path="/app" element={<AppPanel />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/game/:id" element={<CategoryDetail />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/redpill" element={<Credits />} />
              <Route path="/support" element={<Support />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/contact" element={<ContactUs />} />
            </Routes>
          </TosCheck>
          <DiscordButton />
          <Footer />
          <CookieConsent
            location="bottom"
            buttonText="I Understand"
            declineButtonText="Decline"
            cookieName="cookieConsent"
            style={{
              background: "var(--section-background)",
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              padding: "20px 100px 20px 20px",
              boxSizing: "border-box",
              zIndex: 9999,
              boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            buttonStyle={{
              color: "var(--button-text)",
              fontSize: "14px",
              padding: "10px 20px",
              background: "var(--button-background)",
              border: "none",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
              transition: "background 0.3s ease",
              marginLeft: "10px",
            }}
            declineButtonStyle={{
              color: "var(--text-color)",
              fontSize: "14px",
              padding: "10px 20px",
              background: "transparent",
              border: "1px solid var(--button-background)",
              borderRadius: "8px",
              cursor: "pointer",
              transition: "background 0.3s ease",
              marginLeft: "10px",
            }}
            buttonWrapperClasses="cookie-consent-buttons"
            enableDeclineButton
            expires={150}
            onDecline={() => {
              console.log("User declined cookies");
            }}
          >
            <span style={{ color: "var(--text-color)", fontSize: "14px" }}>
              We use cookies to enhance your browsing experience, personalize content, ads, and analyze our traffic in compliance with applicable data protection laws.
              By continuing to use our site, you consent to our use of cookies. For more information, please review our{" "}
              <Link
                to="/privacy-policy"
                style={{ color: "var(--link-color)", textDecoration: "underline" }}
              >
                Privacy Policy
              </Link>.
            </span>
          </CookieConsent>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;