import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import './CheatRequests.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

function CheatRequests() {
    const [steamLink, setSteamLink] = useState("");
    const [cheatDetails, setCheatDetails] = useState("");
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [voteLoading, setVoteLoading] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [formVisible, setFormVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const requestsPerPage = 5; // Number of requests per page
    const navigate = useNavigate();
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [provideAccountKey, setProvideAccountKey] = useState(false);
    const [offerReward, setOfferReward] = useState(false);


    useEffect(() => {
        fetchRequests();
        const tokenCheckInterval = setInterval(() => {
            const updatedToken = localStorage.getItem("token");
            setToken(updatedToken);
        }, 60000);
        return () => clearInterval(tokenCheckInterval);
    }, []);

    useEffect(() => {
        filterRequests();
    }, [searchQuery, activeTab, requests]);

    useEffect(() => {
        setCurrentPage(1); // Reset to the first page when filters change
    }, [searchQuery, activeTab]);

    const filterRequests = () => {
        const filtered = requests.filter(request => 
            request.steamGameDetails?.name && request.steamGameDetails.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredRequests(filterRequestsByStatus(filtered));
    };

    const paginatedRequests = filteredRequests.slice(
        (currentPage - 1) * requestsPerPage,
        currentPage * requestsPerPage
    );

    const totalPages = Math.ceil(filteredRequests.length / requestsPerPage);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const location = useLocation();

    useEffect(() => {
        try {
          const ins = document.querySelector('.adsbygoogle');
          if (ins && ins.innerHTML) ins.innerHTML = ''; // Clear existing ad
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("AdSense error:", e);
        }
      }, [location.pathname]);

    const fetchRequests = async () => {
        setLoading(true);
        try {
            const res = await axios.get("/api/requests");
            setRequests(res.data);
            setFilteredRequests(res.data);
        } catch (error) {
            console.error("Error fetching cheat requests:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;
    
            localStorage.setItem("token", accessToken);
            return accessToken;
        } catch (err) {
            console.error("Failed to refresh access token. Logging out...");
            
            handleLogout();
            return null;
        }
    };

    const submitRequest = async () => {
        setErrorMessage("");
    
        // Check if the token is expired and try to refresh it
        if (!token || isTokenExpired(token)) {
            const refreshedToken = await refreshAccessToken();
            if (!refreshedToken) return; // Stop execution if token refresh fails
        }
    
        if (!steamLink) {
            setErrorMessage("Please enter a valid Steam store link.");
            return;
        }
    
        if (!isValidSteamLink(steamLink)) {
            setErrorMessage("Please enter a valid Steam store link.");
            return;
        }
    
        try {
            setLoading(true);
    
            // Extract the app ID from the Steam link
            const appIdMatch = steamLink.match(/\/app\/(\d+)/);
            if (!appIdMatch) {
                setErrorMessage("Unable to extract the app ID from the provided link.");
                return;
            }
    
            const appId = appIdMatch[1];
    
            const requestData = {
                steamLink: `https://store.steampowered.com/app/${appId}/`, // Correct link
                userId: JSON.parse(atob(token.split('.')[1])).userId,
            };
            if (cheatDetails.trim()) requestData.cheatDetails = cheatDetails;
    
            await axios.post("/api/requests", requestData, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            setSteamLink("");
            setCheatDetails("");
            fetchRequests();
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || "There was an error submitting the request. Please try again later."
            );
            console.error("Error submitting cheat request:", error);
        } finally {
            setLoading(false);
        }
    };

    const voteOnRequest = async (requestId) => {
        setErrorMessage("");

        // Check if the token is expired and try to refresh it
        if (!token) {
            const refreshedToken = await refreshAccessToken();
            if (!refreshedToken) return; // Stop execution if token refresh fails
        }

        try {
            setVoteLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/requests/vote/${requestId}`, {}, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchRequests();
        } catch (error) {
            setErrorMessage(
                error.response?.data?.message || "There was an error voting. Please try again later."
            );
            console.error("Error voting on cheat request:", error);
        } finally {
            setVoteLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const toggleForm = () => {
        setFormVisible(!formVisible);
    };

    const isValidSteamLink = (link) => /^https:\/\/store\.steampowered\.com\/app\/\d+/.test(link);

    const isTokenExpired = (token) => {
        try {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;
        } catch (error) {
            return true;
        }
    };

    const filterRequestsByStatus = (requestsList = requests) => {
        if (activeTab === 'approved') {
            return requestsList.filter(request => request.status === 'approved' || request.status === 'completed');
        } else if (activeTab === 'rejected') {
            return requestsList.filter(request => request.status === 'rejected');
        } else if (activeTab === 'pending') {
            return requestsList.filter(request => request.status === 'pending');
        }
        return requestsList.sort((a, b) => {
            const statusOrder = { 'pending': 1, 'approved': 2, 'completed': 3, 'rejected': 4 };
            return statusOrder[a.status] - statusOrder[b.status];
        });
    };

    return (
        <div className="cheatrequests-container">
            <Helmet key="cheat-requests-helmet">
                <title>Cheat Requests - Sintrix.net</title>
                <meta name="description" content="Submit and vote on cheat requests for games." />
                <meta property="og:title" content="Cheat Requests - Sintrix.net" />
                <meta property="og:description" content="Submit and vote on cheat requests for games." />
                <meta property="og:image" content="https://sintrix.net/sintrix2.png" />
                <meta property="og:url" content="https://sintrix.net/requests" />
            </Helmet>

            <h2 className="cheatrequests-title">Cheat Requests</h2>
            <div className="ad-container">
                <ins
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-3980892685014254"
                    data-ad-slot="4227089420"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                />
            </div>
            <input 
                type="text" 
                placeholder="Search requests..." 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                className="search-input"
            />
            <div className="tab-buttons">
                <button className={`tab-button ${activeTab === 'all' ? 'active' : ''}`} onClick={() => setActiveTab('all')}>All Requests</button>
                <button className={`tab-button ${activeTab === 'approved' ? 'active' : ''}`} onClick={() => setActiveTab('approved')}>Approved</button>
                <button className={`tab-button ${activeTab === 'rejected' ? 'active' : ''}`} onClick={() => setActiveTab('rejected')}>Rejected</button>
                <button className={`tab-button ${activeTab === 'pending' ? 'active' : ''}`} onClick={() => setActiveTab('pending')}>Pending</button>
                <button onClick={toggleForm} className="cheatrequests-toggle-button">
                    <FontAwesomeIcon icon={faSquarePlus} size="1x" />
                </button>
            </div>

            {errorMessage && <p className="global-error">{errorMessage}</p>}
            {formVisible && (
                <div className="cheatrequests-input-container">
                    <input type="text" placeholder="Steam Store Link" value={steamLink} onChange={(e) => setSteamLink(e.target.value)} className="cheatrequests-input" />
                    <input type="text" placeholder="Extra Details (optional)" value={cheatDetails} onChange={(e) => setCheatDetails(e.target.value)} className="cheatrequests-input" />
                    <button onClick={submitRequest} disabled={loading} className="cheatrequests-button">{loading ? "Submitting..." : "Submit Request"}</button>
                </div>
            )}

            {loading && <p>Loading requests...</p>}
            {paginatedRequests.length === 0 && !loading ? (
                <p>No requests match your search.</p>
            ) : (
                paginatedRequests.map((request) => (
                    <div key={request._id} className={`cheatrequests-card ${request.status}`}>
                        <div className="game-info">
                            <p>
                                <strong>Game:</strong>{" "}
                                {request.steamGameDetails?.name ? (
                                    <a
                                        href={`https://store.steampowered.com/app/${request.gameId}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="steam-link"
                                    >
                                        {request.steamGameDetails.name}
                                    </a>
                                ) : (
                                    "Unknown Game"
                                )}
                            </p>
                            <p><strong>SteamDB Link:</strong>{" "}
                                <a className="steam-link" href={request.steamDBLink} target="_blank" rel="noopener noreferrer">{request.steamDBLink}</a>
                            </p>
                            <p className="request-by">
                                <strong>Request by:</strong>{" "}
                                {request.username === "Anonymous" ? (
                                    <span className="anonymous-request">Anonymous</span>
                                ) : (
                                    <Link 
                                        to={`/profile/${request.username}`} 
                                        className="profile-link-request"
                                    >
                                        {request.username}
                                    </Link>
                                )}
                            </p>
                            {request.status === 'rejected' && request.rejectionReason && (
                                <p><strong>Rejection Reason:</strong> {request.rejectionReason}</p>
                            )}
                            {request.status === 'completed' && (
                                <p>This request is complete! You can find the table in <a className="steam-link" href="/games">Tables</a>.</p>
                            )}
                        </div>
                        <div className="vote-section">
                            <p><strong>Votes:</strong> {request.votes}</p>
                            {request.status === 'pending' && (
                                <button onClick={() => voteOnRequest(request._id)} disabled={voteLoading[request._id]} className="cheatrequests-vote-button">
                                    {voteLoading[request._id] ? "Voting..." : "Vote"}
                                </button>
                            )}
                        </div>
                    </div>
                ))
            )}

            {/* Pagination Controls */}
            <div className="pagination-controls">
                <button 
                    onClick={() => handlePageChange(currentPage - 1)} 
                    disabled={currentPage === 1} 
                    className="pagination-button"
                >
                    Previous
                </button>
                <span className="pagination-text">Page {currentPage} of {totalPages}</span>
                <button 
                    onClick={() => handlePageChange(currentPage + 1)} 
                    disabled={currentPage === totalPages} 
                    className="pagination-button"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default CheatRequests;
