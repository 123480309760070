// components/ContactUs.js
import React from 'react';
import './ContactUs.css'; // Optional: Create and import your CSS file for additional styling

const ContactUs = () => (
  <div className="contact-container">
    <div className="contact-section">
      <h2>Contact Us</h2>
      <p>
        We're here to help. If you have any questions, need support, or would simply like to share your feedback,
        please feel free to reach out to us using the options below.
      </p>
      <ul>
        <li>
          Email:{" "}
          <a href="mailto:support@sintrix.net" className="support-link">
            support@sintrix.net
          </a>
        </li>
        <li>
          Join our{" "}
          <a
            href="https://discord.gg/zjvdGQBrBc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Discord server
          </a>{" "}
          for real-time assistance and community support.
        </li>
      </ul>
      <p>
        We value your feedback and look forward to assisting you.
      </p>
    </div>
  </div>
);

export default ContactUs;
