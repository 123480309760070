import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Home.css";

function Home() {
    const navigate = useNavigate();
    const [recentGames, setRecentGames] = useState([]);
    const [gameImages, setGameImages] = useState({});
    const [theme, setTheme] = useState("default");
    const [fallingItems, setFallingItems] = useState([]);
    const [popularGames, setPopularGames] = useState([]);
    localStorage.setItem("redirectAfterLogin", window.location.pathname);

    // Calculate Easter Sunday for a given year (using the Meeus/Jones/Butcher algorithm)
    const getEasterSunday = (year) => {
        const a = year % 19;
        const b = Math.floor(year / 100);
        const c = year % 100;
        const d = Math.floor(b / 4);
        const e = b % 4;
        const f = Math.floor((b + 8) / 25);
        const g = Math.floor((b - f + 1) / 3);
        const h = (19 * a + b - d - g + 15) % 30;
        const i = Math.floor(c / 4);
        const k = c % 4;
        const l = (32 + 2 * e + 2 * i - h - k) % 7;
        const m = Math.floor((a + 11 * h + 22 * l) / 451);
        const month = Math.floor((h + l - 7 * m + 114) / 31);
        const day = ((h + l - 7 * m + 114) % 31) + 1;
        return new Date(year, month - 1, day);
    };

    // Calculate Thanksgiving (fourth Thursday of November) for a given year
    const getThanksgivingDate = (year) => {
        const novemberFirst = new Date(year, 10, 1); // November 1st
        const dayOfWeek = novemberFirst.getDay();
        // Calculate the first Thursday: 1st + (4 - dayOfWeek + 7) % 7
        const firstThursday = 1 + ((4 - dayOfWeek + 7) % 7);
        // Fourth Thursday is 3 weeks after the first Thursday
        const thanksgivingDay = firstThursday + 21;
        return new Date(year, 10, thanksgivingDay);
    };

    // Determine the current theme based on the date
    const determineTheme = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // Months are 0-indexed
        const day = now.getDate();

        // Calculate Easter Sunday for the current year
        const easterSunday = getEasterSunday(year);
        const easterMonth = easterSunday.getMonth() + 1;
        const easterDay = easterSunday.getDate();
        const easterStart = new Date(easterSunday);
        easterStart.setDate(easterDay - 7); // One week before Easter
        const easterEnd = new Date(easterSunday);
        easterEnd.setDate(easterDay + 1); // Day after Easter

        // Calculate Thanksgiving for the current year
        const thanksgiving = getThanksgivingDate(year);
        const thanksgivingMonth = thanksgiving.getMonth() + 1;
        const thanksgivingDay = thanksgiving.getDate();
        const thanksgivingStart = new Date(thanksgiving);
        thanksgivingStart.setDate(thanksgivingDay - 7); // One week before Thanksgiving
        const thanksgivingEnd = new Date(thanksgiving);
        thanksgivingEnd.setDate(thanksgivingDay + 1); // Day after Thanksgiving

        // Halloween: October 24th to October 31st
        if ((month === 10 && day >= 24) && (month === 10 && day <= 31)) {
            return "halloween";
        }
        // New Year: December 12th to January 1st
        else if ((month === 12 && day >= 12) || (month === 1 && day <= 1)) {
            return "newyear";
        }
        // Easter: One week before Easter Sunday to the day after
        else if (now >= easterStart && now <= easterEnd) {
            return "easter";
        }
        // Thanksgiving: One week before Thanksgiving to the day after
        else if (now >= thanksgivingStart && now <= thanksgivingEnd) {
            return "thanksgiving";
        }
        else {
            return "default";
        }
    };

    // Generate falling items for each theme
    const generateFallingItems = (theme) => {
        const items = [];
        const numberOfItems = 75; // Number of falling items
        let contentOptions = [];

        switch (theme) {
            case "halloween":
                contentOptions = ["🎃", "👻", "🦇"];
                break;
            case "newyear":
                contentOptions = ["✻"];
                break;
            case "easter":
                contentOptions = ["🥚", "🐰"];
                break;
            case "thanksgiving":
                contentOptions = ["🍂", "🦃"];
                break;
            default:
                contentOptions = [];
        }

        for (let i = 0; i < numberOfItems; i++) {
            items.push({
                id: i,
                content: contentOptions[Math.floor(Math.random() * contentOptions.length)],
                style: {
                    left: `${Math.random() * 100}vw`,
                    animationDuration: `${Math.random() * 8 + 12}s`,
                    animationDelay: `${Math.random() * -5}s`,
                    fontSize: `${Math.random() * 20 + 10}px`,
                },
            });
        }

        setFallingItems(items);
    };

    const location = useLocation();

    useEffect(() => {
        try {
            const ins = document.querySelector('.adsbygoogle');
            if (ins && ins.innerHTML) ins.innerHTML = ''; // Clear existing ad
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            console.error("AdSense error:", e);
        }
    }, [location.pathname]);

    useEffect(() => {
        const fetchPopularGames = async () => {
            try {
                const response = await axios.get("/api/auth/games/popular");
                setPopularGames(response.data);
            } catch (error) {
                console.error("Error fetching popular games:", error);
            }
        };

        fetchPopularGames();
    }, []);

    useEffect(() => {
        // Set theme based on date
        const theme = determineTheme();
        setTheme(theme);

        // Generate falling items based on the theme
        generateFallingItems(theme);

        // Fetch recent games
        const fetchRecentGames = async () => {
            try {
                const response = await axios.get("/api/auth/games/recent");
                if (response.data && Array.isArray(response.data)) {
                    setRecentGames(response.data);
                    response.data.forEach((game) => {
                        if (game.appid) {
                            fetchGameImage(game.appid, game.id);
                        }
                    });
                } else {
                    console.error("Fetched games are not an array:", response.data);
                }
            } catch (error) {
                console.error("Error fetching recent games:", error);
            }
        };

        // Fetch game image
        const fetchGameImage = async (steamAppId, gameId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;
            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setGameImages((prev) => ({ ...prev, [gameId]: imageUrl }));
            };
            img.onerror = () => {
                setGameImages((prev) => ({ ...prev, [gameId]: "default-placeholder.jpg" }));
            };
        };

        fetchRecentGames();
    }, []);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className={`home-container theme-${theme}`}>
            <Helmet>
                <title>Home - Sintrix.net</title>
            </Helmet>

            <div className="falling-items-container">
                {fallingItems.map((item) => (
                    <div key={item.id} className="falling-item" style={item.style}>
                        {item.content}
                    </div>
                ))}
            </div>

            <div className="home-content">
                <img
                    src="/logo192.png"
                    alt="HomeLogo"
                    className="homelogo"
                    onDoubleClick={() => navigate("/redpill")}
                />
                <h1 className="home-title">Welcome to Sintrix</h1>

                <p className="home-description">
                    Welcome to the Sintrix website! We provide a user-friendly platform for our Cheat Engine tables.  
                    Playing a game and feeling like you're progressing too slowly? Want a little extra freedom in your gaming experience?  
                    We offer well-crafted, continually tested, and updated tables—and best of all, they are completely free!
                </p>

                <p>
                    Feel free to browse our <strong>tables page</strong> and discover all the ready-made tables we offer.  
                    Just download, open, and use them to your heart's content!
                </p>

                <p>
                    <strong>Would you like us to take a look at a game we don’t have yet? </strong>  
                    Register an account with us and gain access to useful features such as game requests, table bookmarking,  
                    profile customization, and messaging other members and staff.  
                    If you link your Discord, you’ll also be able to enable Discord notifications for game updates  
                    and gain access to our members-only section in the Discord server.
                </p>

                <p>
                    <strong>Interested in our community?</strong> Feel free to check out our  
                    <a href="https://discord.gg/zjvdGQBrBc" target="_blank"> Discord server </a> to ask for support,  
                    chat with our staff and other users, or just hang out with our 4,700+ members!
                </p>

                <div className="home-buttons">
                    <Link to="/requests" className="home-button">
                        Cheat Requests
                    </Link>
                    <Link to="/games" className="home-button">
                        Tables
                    </Link>
                </div>

                <div className="ad-container">
                    <ins
                        className="adsbygoogle"
                        style={{ display: "block" }}
                        data-ad-client="ca-pub-3980892685014254"
                        data-ad-slot="9113462902"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    />
                </div>

                <hr className="separator-line" />
                <div className="recent-games-slider">
                    <h2 className="recent-games-title">Most Recent Tables</h2>
                    {Array.isArray(recentGames) && recentGames.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {recentGames.map((game) => (
                                <div key={game._id} className="slider-item">
                                    <Link to={`/game/${game.slug}`}>
                                        <img
                                            src={`https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${game.appid}/header.jpg`}
                                            alt={`${game.name}`}
                                            className="game-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default-placeholder.jpg";
                                            }}
                                        />
                                    </Link>
                                    <h3 className="game-title">{game.name}</h3>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-games-message">No recent games available.</p>
                    )}
                </div>

                <hr className="separator-line recent-games" />

                <div className="popular-games-slider">
                    <h2 className="popular-games-title">Most Popular Tables</h2>
                    {popularGames.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {popularGames.map((game) => (
                                <div key={game._id} className="slider-item">
                                    <Link to={`/game/${game.slug}`}>
                                        <img
                                            src={`https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${game.appid}/header.jpg`}
                                            alt={game.name}
                                            className="game-image"
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "default-placeholder.jpg";
                                            }}
                                        />
                                    </Link>
                                    <h3 className="game-title">{game.name}</h3>
                                </div>
                            ))}
                        </Slider>
                    ) : (
                        <p className="no-games-message">No popular games available.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;