import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';

const TosCheck = ({ children }) => {
  const { user, loading, acceptTos, error } = useContext(UserContext);
  const location = useLocation();

  // Define routes where ToS modal should NOT appear
  const excludedRoutes = ['/terms-of-service', '/privacy-policy', '/contact', '/support'];

  if (loading) {
    return <div style={{ color: 'var(--text-color, #000)' }}>Loading...</div>;
  }

  // Show modal if user is logged in, hasn't accepted ToS, and isn't on an excluded route
  if (user && !user.tosAccepted && !excludedRoutes.includes(location.pathname)) {
    return (
      <div style={modalOverlayStyle}>
        <div style={modalStyle}>
          <h3 style={modalTitleStyle}>Terms of Service</h3>
          <p style={modalDescriptionStyle}>
            Please review and accept our{' '}
            <a href="/terms-of-service" target="_blank" style={linkStyle}>
              Terms of Service
            </a>
            .
          </p>
          {error && <p style={errorStyle}>{error}</p>}
          <button onClick={acceptTos} style={buttonStyle}>
            Accept
          </button>
        </div>
      </div>
    );
  }

  // Render the app content if ToS is accepted, user isn’t logged in, or on an excluded route
  return children;
};

// Inline styles
const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9999,
};

const modalStyle = {
  backgroundColor: 'var(--card-background, #fff)',
  padding: '30px',
  borderRadius: '10px',
  width: '90%',
  maxWidth: '400px',
  textAlign: 'center',
};

const modalTitleStyle = {
  fontSize: '20px',
  marginBottom: '10px',
  color: 'var(--text-color, #000)', // Ensure title contrasts in dark mode
};

const modalDescriptionStyle = {
  marginBottom: '20px',
  color: 'var(--text-color, #333)', // Changed from text-color-light to text-color for better contrast
  fontSize: '16px', // Slightly larger for readability
};

const linkStyle = {
  color: 'var(--link-color, #007bff)',
  textDecoration: 'underline',
};

const buttonStyle = {
  padding: '12px 20px',
  backgroundColor: 'var(--button-background, #007bff)',
  color: 'var(--button-text, #fff)',
  border: 'none',
  borderRadius: '6px',
  cursor: 'pointer',
  width: '100%',
  fontSize: '16px',
};

const errorStyle = {
  color: 'var(--error-color, #ff0000)', // Use a variable for error color, default to red
  marginBottom: '10px',
};

export default TosCheck;