import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch user data on mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const res = await axios.get('/api/auth/dashboard', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(res.data); // Backend returns user data directly (e.g., { tosAccepted: boolean, ... })
        }
      } catch (err) {
        console.error('Error fetching user:', err);
        setError('Failed to load user data.');
      } finally {
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  // Function to accept ToS
  const acceptTos = async () => {
    try {
      await axios.post(
        '/api/auth/accept-tos',
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }
      );
      setUser({ ...user, tosAccepted: true });
      setError(null); // Clear any previous errors
    } catch (err) {
      console.error('Error accepting ToS:', err);
      setError('Failed to accept ToS. Please try again.');
    }
  };

  return (
    <UserContext.Provider value={{ user, loading, acceptTos, error }}>
      {children}
    </UserContext.Provider>
  );
};