import React from 'react';

const TermsOfService = () => (
  <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
    <h1>Terms of Service</h1>
    <p>Last Updated: March 2, 2025</p>

    <h2>1. Introduction</h2>
    <p>
      Welcome to sintrix.net ("we," "us," or "our"). By accessing or using our website ("Site"), you agree to be bound by these Terms of Service ("Terms"). 
      These Terms govern your use of our Site, services, and content, including account creation, third-party integrations, and community interactions. 
      If you do not agree with these Terms, please do not use our Site. These Terms apply to all users globally and are designed to comply with applicable laws.
    </p>

    <h2>2. Eligibility</h2>
    <p>
      To use our Site, you must:
    </p>
    <ul>
      <li>Be at least 16 years old (or the age of consent in your jurisdiction).</li>
      <li>Provide accurate and complete information during account registration.</li>
      <li>Not be prohibited from using our services under applicable laws or prior bans from our Site.</li>
    </ul>
    <p>
      We do not knowingly allow individuals under 16 to create accounts. If we discover such an account, it will be terminated.
    </p>

    <h2>3. Account Registration and Security</h2>
    <p>
      To access certain features, you must create an account. You agree to:
    </p>
    <ul>
      <li>Provide a valid email address, username, and password.</li>
      <li>Keep your account credentials confidential and not share them with others.</li>
      <li>Notify us immediately at <a href="mailto:support@sintrix.net">support@sintrix.net</a> if you suspect unauthorized use of your account.</li>
    </ul>
    <p>
      Passwords are encrypted using industry-standard hashing (e.g., bcrypt). You are responsible for all activities under your account. Unverified accounts may be deleted after 24 hours.
    </p>

    <h2>4. Acceptable Use</h2>
    <p>
      You agree to use our Site in a lawful and respectful manner. You must not:
    </p>
    <ul>
      <li>Use the Site to violate any local, national, or international laws or regulations.</li>
      <li>Post, upload, or share content that is illegal, harmful, abusive, defamatory, obscene, or infringes on others’ rights (e.g., intellectual property, privacy).</li>
      <li>Engage in spamming, phishing, or distributing malware.</li>
      <li>Attempt to gain unauthorized access to our systems, accounts, or data (e.g., hacking, scraping).</li>
      <li>Use automated tools (e.g., bots) to interact with the Site without our prior written consent.</li>
      <li>Harass, threaten, or impersonate other users or staff.</li>
    </ul>
    <p>
      We reserve the right to remove content or suspend/terminate accounts violating these rules at our sole discretion.
    </p>

    <h2>5. Third-Party Integrations</h2>
    <p>
      Our Site offers integrations with third-party services for your convenience:
    </p>
    <ul>
      <li>
        <strong>Steam Login:</strong> Authentication is handled by Steam via OAuth. We store only a temporary session token, deleted upon logout.
      </li>
      <li>
        <strong>Discord Connection:</strong> Connecting your Discord account for perks requires consent. We store only your Discord username.
      </li>
    </ul>
    <p>
      These services are subject to their own terms and policies, which you must also comply with.
    </p>

    <h2>6. Intellectual Property</h2>
    <p>
      All content on the Site (e.g., text, graphics, code), excluding user-generated content, is owned by us or our licensors and protected by copyright, trademark, and other laws. You may not:
    </p>
    <ul>
      <li>Copy, distribute, or modify our content without prior written permission.</li>
      <li>Use our trademarks (e.g., "Sintrix") without consent.</li>
    </ul>
    <p>
      By uploading content (e.g., posts, files), you grant us a non-exclusive, worldwide, royalty-free license to use, store, and display it as needed to provide our services. You represent that you own or have rights to share such content.
    </p>

    <h2>7. User-Generated Content</h2>
    <p>
      You are responsible for any content you upload or share. We do not endorse or verify user content and are not liable for its accuracy or legality. We may:
    </p>
    <ul>
      <li>Monitor, review, or remove content at our discretion.</li>
      <li>Take action against content violating these Terms or applicable laws.</li>
    </ul>

    <h2>8. Privacy</h2>
    <p>
      Your use of the Site is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which details how we collect, use, and protect your data. By using the Site, you consent to such practices.
    </p>

    <h2>9. Termination and Suspension</h2>
    <p>
      We may suspend or terminate your account or access to the Site at our sole discretion, with or without notice, for reasons including:
    </p>
    <ul>
      <li>Violating these Terms.</li>
      <li>Engaging in fraudulent or illegal activity.</li>
      <li>Protecting the security or integrity of our Site or users.</li>
    </ul>
    <p>
      You may terminate your account at any time by contacting <a href="mailto:support@sintrix.net">support@sintrix.net</a>. Upon termination, your data will be handled per our Privacy Policy.
    </p>

    <h2>10. Disclaimer of Warranties</h2>
    <p>
      The Site is provided "as is" and "as available" without warranties of any kind, express or implied, including fitness for a particular purpose or non-infringement. We do not guarantee:
    </p>
    <ul>
      <li>Uninterrupted or error-free access.</li>
      <li>The accuracy or completeness of content.</li>
    </ul>

    <h2>11. Limitation of Liability</h2>
    <p>
      To the fullest extent permitted by law, we (including our affiliates, officers, and agents) are not liable for any indirect, incidental, consequential, or punitive damages arising from your use of the Site, including:
    </p>
    <ul>
      <li>Loss of data, profits, or opportunities.</li>
      <li>Damages from third-party content or actions.</li>
    </ul>

    <h2>12. Indemnification</h2>
    <p>
      You agree to indemnify and hold us harmless from any claims, damages, or expenses (including legal fees) arising from:
    </p>
    <ul>
      <li>Your use of the Site.</li>
      <li>Your violation of these Terms.</li>
      <li>Your content or actions affecting others.</li>
    </ul>

    <h2>13. Governing Law and Dispute Resolution</h2>
    <p>
        These Terms are governed by the laws of Canada and the Province of Quebec, without regard to conflict of law principles.
    </p>
    <ul>
      <li><strong>Informal Negotiation:</strong> Contact us first at <a href="mailto:support@sintrix.net">support@sintrix.net</a>.</li>
      <li><strong>Arbitration:</strong> If unresolved, disputes will be settled by binding arbitration under the rules of the Canadian Arbitration Association (CAA) or a similar body, unless you’re in a jurisdiction requiring otherwise (e.g., EU small claims).</li>
    </ul>
    <p>
      You waive any right to participate in class actions against us.
    </p>

    <h2>14. Changes to These Terms</h2>
    <p>
      We may update these Terms to reflect changes in our practices or legal requirements. Updates will be posted here with a revised "Last Updated" date. Significant changes will be notified via email or a Site notice where required by law. Continued use after changes constitutes acceptance.
    </p>

    <h2>15. Contact Us</h2>
    <p>
      For questions, concerns, or to report violations, please reach out to:  
      <a href="mailto:support@sintrix.net">support@sintrix.net</a>
    </p>
  </div>
);

export default TermsOfService;