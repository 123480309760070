import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import './AdminPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes,faBullhorn,faFileAlt,faExclamationCircle,faTasks,faUsers,faWrench,faChartLine, } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import { FaEllipsisV } from "react-icons/fa";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { BADGE_ICONS } from "./constants/badges";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


function AdminPanel() {
    const [steamSearchQuery, setSteamSearchQuery] = useState("");
    const [steamResults, setSteamResults] = useState([]);
    const [loadingSteam, setLoadingSteam] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [selectedAppId, setSelectedAppId] = useState("");
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [requests, setRequests] = useState([]);
    const [rejectedRequests, setRejectedRequests] = useState([]);
    const [users, setUsers] = useState([]);
    const [tables, setTables] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [loadingTables, setLoadingTables] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingRequests, setLoadingRequests] = useState(false);
    const [actionLoading, setActionLoading] = useState({});
    const [message, setMessage] = useState("");
    const [tableFile, setTableFile] = useState(null);
    const [tableName, setTableName] = useState("");
    const [tableVersion, setTableVersion] = useState("");
    const [activeTab, setActiveTab] = useState("content"); 
    const [isAdmin, setIsAdmin] = useState(false); 
    const [loading, setLoading] = useState(true); 
    const [description, setDescription] = useState("");
    const [version, setVersion] = useState("");
    const [releaseDate, setReleaseDate] = useState("");
    const [discontinued, setDiscontinued] = useState(false); 
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState("");
    const navigate = useNavigate();
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTable, setEditingTable] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const gamesPerPage = 5; // Set games per page
    const totalPages = Math.ceil(categories.length / gamesPerPage);
    const startIndex = (currentPage - 1) * gamesPerPage;
    const endIndex = startIndex + gamesPerPage;
    const currentGames = categories.slice(startIndex, endIndex);
    const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
    const [currentUserPage, setCurrentUserPage] = useState(1); // Track current page for users
    const [usersPerPage, setUsersPerPage] = useState(7); // Default value
    const totalUserPages = Math.ceil(filteredUsers.length / usersPerPage);
    const startUserIndex = (currentUserPage - 1) * usersPerPage;
    const endUserIndex = startUserIndex + usersPerPage;
    const currentUsers = filteredUsers.slice(startUserIndex, endUserIndex);
    const [userSearchQuery, setUserSearchQuery] = useState(""); // State for search query
    const [showActionMenu, setShowActionMenu] = useState(null);
    const [uniqueVisitorsSummary, setUniqueVisitorsSummary] = useState(0);
    const [totalRequestsSummary, setTotalRequestsSummary] = useState(0);    
    const [chartData, setChartData] = useState(null);
    const [timeRange, setTimeRange] = useState("24h"); // Default to 24-hour data
    const [voterVisibility, setVoterVisibility] = useState({}); // Track voter list visibility for each request
    const [showVoters, setShowVoters] = useState({});
    const [showRequestActionMenu, setShowRequestActionMenu] = useState(null);
    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [showTableForm, setShowTableForm] = useState(false);
    const [transitioning, setTransitioning] = useState(false);
    const [reports, setReports] = useState([]);
    const [loadingReports, setLoadingReports] = useState(false);
    const [currentRequestPage, setCurrentRequestPage] = useState(1);
    const requestsPerPage = 5; // Adjust this value based on your requirements
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const [actionMenu, setActionMenu] = useState(null); // For user actions menu
    const [ipHistoryMenu, setIpHistoryMenu] = useState(null); // For IP history toggle
    const [selectedRoleFilter, setSelectedRoleFilter] = useState("all"); // Default to showing all roles
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [announcements, setAnnouncements] = useState([]);
    const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);
    const [editingAnnouncement, setEditingAnnouncement] = useState(null);
    const [newAnnouncement, setNewAnnouncement] = useState({ title: "", content: "" });
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const [allowedIPs, setAllowedIPs] = useState([]);
    const [newIP, setNewIP] = useState("");
    const [loadingMaintenance, setLoadingMaintenance] = useState(false);
    const [showReports, setShowReports] = useState({});
    const [isNSFW, setIsNSFW] = useState(false);
    const [tools, setTools] = useState([]);
    const [toolName, setToolName] = useState("");
    const [toolDescription, setToolDescription] = useState("");
    const [toolDownloadUrl, setToolDownloadUrl] = useState("");
    const [editingTool, setEditingTool] = useState(null);
    const [loadingTools, setLoadingTools] = useState(false);
    const [toolGroups, setToolGroups] = useState([]);
    const [toolCategory, setToolCategory] = useState('');
    const [error, setError] = useState('');
    const [showBanDetailsModal, setShowBanDetailsModal] = useState(false);
    const [selectedUserBanDetails, setSelectedUserBanDetails] = useState(null);
    const [showBadgeModal, setShowBadgeModal] = useState(false);
    const [selectedUserForBadges, setSelectedUserForBadges] = useState(null);
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [logSearchQuery, setLogSearchQuery] = useState("");
    const [methodFilter, setMethodFilter] = useState("all");
    const [statusFilter, setStatusFilter] = useState("all");
    const [timeFilter, setTimeFilter] = useState("all");
    const [knownUsersFilter, setKnownUsersFilter] = useState(false);
    const [pathFilter, setPathFilter] = useState("all");
    const [ipFilter, setIpFilter] = useState("all");
    const [durationFilter, setDurationFilter] = useState("all");
    const [currentLogPage, setCurrentLogPage] = useState(1);
    const [logsPerPage, setLogsPerPage] = useState(10);
    const [sortConfig, setSortConfig] = useState({ key: "timestamp", direction: "desc" });
    const [loadingLogs, setLoadingLogs] = useState(false);
    const [totalLogs, setTotalLogs] = useState(0);
    const [totalPagesLogs, setTotalPagesLogs] = useState(0);

    const pathCounts = filteredLogs.reduce((acc, log) => {
        acc[log.path] = (acc[log.path] || 0) + 1;
        return acc;
    }, {});

    const getAvatarUrl = (avatar, username = "User") =>
        avatar || `https://ui-avatars.com/api/?name=${encodeURIComponent(username)}&background=random&bold=true`;


    // Fetch logs when timeFilter, logsPerPage, or currentLogPage changes
    useEffect(() => {
        console.log("Fetching logs due to change in timeFilter, logsPerPage, or currentLogPage:", {
            timeFilter,
            logsPerPage,
            currentLogPage,
        });
        fetchLogs();
    }, [timeFilter, logsPerPage, currentLogPage]);

    // Apply filters when filter-related states change (excluding logs)
    useEffect(() => {
        console.log("Applying filters due to change in filter states:", {
            logSearchQuery,
            methodFilter,
            statusFilter,
            knownUsersFilter,
            pathFilter,
            ipFilter,
            durationFilter,
            sortConfig,
        });
        applyFilters(
            logs,
            logSearchQuery,
            methodFilter,
            statusFilter,
            timeFilter,
            knownUsersFilter,
            pathFilter,
            ipFilter,
            durationFilter
        );
        setCurrentLogPage(1); // Reset to first page when filters change
    }, [logSearchQuery, methodFilter, statusFilter, knownUsersFilter, pathFilter, ipFilter, durationFilter, sortConfig]);

    // Apply filters when logs change (e.g., after fetchLogs completes), but don't reset page
    useEffect(() => {
        console.log("Applying filters due to change in logs:", logs);
        applyFilters(
            logs,
            logSearchQuery,
            methodFilter,
            statusFilter,
            timeFilter,
            knownUsersFilter,
            pathFilter,
            ipFilter,
            durationFilter
        );
    }, [logs]);

    // HTTP Status Code Descriptions
    const statusCodeMessages = {
        200: "OK - The request was successful.",
        201: "Created - The resource was successfully created.",
        204: "No Content - The request was successful but there’s no content to return.",
        400: "Bad Request - The server couldn’t understand the request due to invalid syntax.",
        401: "Unauthorized - Authentication is required and has failed or not been provided.",
        403: "Forbidden - The client does not have access rights to the content.",
        404: "Not Found - The server can’t find the requested resource.",
        429: "Too Many Requests - The user has sent too many requests in a given amount of time.",
        500: "Internal Server Error - The server encountered an unexpected condition.",
        502: "Bad Gateway - The server received an invalid response from an upstream server.",
        503: "Service Unavailable - The server is temporarily unable to handle the request.",
        504: "Gateway Timeout - The server did not receive a timely response from an upstream server.",
    };

    const fetchLogs = async () => {
        setLoadingLogs(true);
        try {
            console.log(`Fetching logs for page ${currentLogPage} with limit ${logsPerPage}`);
            const response = await axios.get(`/api/logs?time=${timeFilter}&page=${currentLogPage}&limit=${logsPerPage}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            const { logs: logsData, totalLogs, totalPages } = response.data;
            const logsArray = Array.isArray(logsData) ? logsData : [];
            console.log("Server response:", { logsData, totalLogs, totalPages });
            setLogs(logsArray);
            setTotalLogs(totalLogs);
            setTotalPagesLogs(totalPages); // Store total pages
            applyFilters(
                logsArray,
                logSearchQuery,
                methodFilter,
                statusFilter,
                timeFilter,
                knownUsersFilter,
                pathFilter,
                ipFilter,
                durationFilter
            );
        } catch (error) {
            console.error("Error fetching logs:", error);
            setMessage({
                text: "Error loading logs.",
                type: "error",
            });
            setLogs([]);
            setFilteredLogs([]);
        } finally {
            setLoadingLogs(false);
        }
    };
    
    const applyFilters = (
        logsData,
        search = "",
        method = "all",
        status = "all",
        time = "all",
        knownUsers = false,
        path = "all",
        ip = "all",
        duration = "all"
    ) => {
        let filtered = [...logsData];
    
        // Search filter (path, IP, or username)
        if (search) {
            filtered = filtered.filter(
                (log) =>
                    log.path.toLowerCase().includes(search.toLowerCase()) ||
                    (log.ipAddress && log.ipAddress.toLowerCase().includes(search.toLowerCase())) ||
                    (log.username && log.username.toLowerCase().includes(search.toLowerCase()))
            );
        }
    
        // Method filter
        if (method !== "all") {
            filtered = filtered.filter((log) => log.method === method);
        }
    
        // Status filter
        if (status !== "all") {
            const [min, max] = status.split("-").map(Number);
            filtered = filtered.filter((log) => log.status >= min && log.status <= max);
        }
    
        // Known Users filter
        if (knownUsers) {
            filtered = filtered.filter((log) => log.username);
        }
    
        // Path filter
        if (path !== "all") {
            filtered = filtered.filter((log) => log.path === path);
        }
    
        // IP filter
        if (ip !== "all") {
            filtered = filtered.filter((log) => log.ipAddress === ip);
        }
    
        // Duration filter
        if (duration !== "all") {
            if (duration === "1000+") {
                filtered = filtered.filter((log) => log.duration >= 1000);
            } else {
                const [min, max] = duration.split("-").map(Number);
                filtered = filtered.filter((log) => log.duration >= min && log.duration <= max);
            }
        }
    
        // Apply sorting
        filtered.sort((a, b) => {
            if (sortConfig.key === "timestamp") {
                return sortConfig.direction === "asc"
                    ? new Date(a.timestamp) - new Date(b.timestamp)
                    : new Date(b.timestamp) - new Date(a.timestamp);
            } else if (sortConfig.key === "status" || sortConfig.key === "duration") {
                return sortConfig.direction === "asc"
                    ? a[sortConfig.key] - b[sortConfig.key]
                    : b[sortConfig.key] - a[sortConfig.key];
            }
            return 0;
        });
    
        setFilteredLogs(filtered);
        // Removed: setCurrentLogPage(1); // No longer reset the page here
    };

    const tabTitles = {
        content: 'Content',
        requests: 'Requests',
        users: 'Users',
        analytics: 'Analytics',
        reports: 'Reports',
        announcements: 'Announcements',
        maintenance: 'Maintenance',
        logs: 'Logs',
    };
    
    
    const [confirmationDialog, setConfirmationDialog] = useState({
        visible: false,
        action: null,
        userId: null
    });
    // Calculate filtered pagination details
    const filteredTotalUserPages = Math.ceil(filteredUsers.length / usersPerPage);
    const filteredStartUserIndex = (currentUserPage - 1) * usersPerPage;
    const filteredEndUserIndex = filteredStartUserIndex + usersPerPage;
    const filteredCurrentUsers = filteredUsers.slice(filteredStartUserIndex, filteredEndUserIndex);
    const [selectedFilter, setSelectedFilter] = useState("pending");
    // Recalculate filteredRequests and paginatedRequests when filter or page changes
    const filteredRequests = React.useMemo(() => {
        return requests.filter((req) => {
            switch (selectedFilter) {
                case "pending":
                    return req.status === "pending";
                case "approved":
                    return req.status === "approved";
                case "rejected":
                    return req.status === "rejected";
                case "completed":
                    return req.status === "completed";
                case "all":
                default:
                    return true; // Show all requests
            }
        });
    }, [selectedFilter, requests]);

    // Dynamically calculate total pages based on the filteredRequests
    const totalRequestPages = Math.ceil(filteredRequests.length / requestsPerPage);

    // Paginate the filtered requests
    const paginatedRequests = filteredRequests.slice(
        (currentRequestPage - 1) * requestsPerPage,
        currentRequestPage * requestsPerPage
    );

    const fetchTools = async () => {
        setLoadingTools(true);
        try {
            const response = await axios.get("/api/admin/tools", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setTools(response.data);
        } catch (error) {
            console.error("Error fetching tools:", error);
        } finally {
            setLoadingTools(false);
        }
    };

    useEffect(() => {
        fetchToolGroups();
    }, []);
    
    // Fetch tool groups from the backend
    const fetchToolGroups = async () => {
        try {
            const response = await axios.get('/api/admin/tool-groups'); // Replace with your actual endpoint
            setToolGroups(response.data);
        } catch (error) {
            console.error('Error fetching tool groups:', error);
        }
    };

    tools.reduce((grouped, tool) => {
        grouped[tool.category] = grouped[tool.category] || [];
        grouped[tool.category].push(tool);
        return grouped;
    }, {});    

    const saveTool = async (updatedTool) => {
        try {
            // Make an API call to update the tool
            const response = await axios.put(`/api/tools/${updatedTool._id}`, updatedTool);
    
            // Update the tools list in state
            setTools((prevTools) =>
                prevTools.map((tool) =>
                    tool._id === updatedTool._id ? response.data.tool : tool
                )
            );
    
            // Reset the form after saving
            resetToolForm();
    
            // Optionally, set a success message
            setError(''); // Clear any error messages
        } catch (err) {
            console.error('Error saving tool:', err);
            setError('Failed to save tool. Please try again.');
        }
    };

    const addTool = async (newTool) => {
        try {
          const response = await axios.post('/api/admin/tools', newTool);
          // If successful, update tools state
          setTools((prevTools) => [...prevTools, response.data.tool]);
          // Clear the input fields
          setToolName('');
          setToolDescription('');
          setToolDownloadUrl('');
          setToolCategory('');
          setError(''); // Clear any error
        } catch (err) {
          console.error('Error adding tool:', err);
          setError('Error adding tool. Please try again.');
        }
      };      

    // Delete tool
    const deleteTool = async (toolId) => {
        try {
            await axios.delete(`/api/admin/tools/${toolId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            fetchTools(); // Refresh tools list
        } catch (error) {
            console.error("Error deleting tool:", error);
        }
    };

    // Select tool for editing
    const editTool = (tool) => {
        setToolName(tool.name);
        setToolDescription(tool.description);
        setToolDownloadUrl(tool.downloadUrl);
        setEditingTool(tool);
    };

    // Reset tool form
    const resetToolForm = () => {
        setToolName("");
        setToolDescription("");
        setToolDownloadUrl("");
        setEditingTool(null);
    };
    
    // Fetch current maintenance mode and allowed IPs
    const fetchMaintenanceSettings = async () => {
        try {
            setLoadingMaintenance(true);
            const response = await axios.get("/api/admin/maintenance-settings", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMaintenanceMode(response.data.maintenanceMode);
            setAllowedIPs(response.data.allowedIPs || []);
        } catch (error) {
            setMessage({
                text: "Error fetching maintenance settings.",
                type: "error",
            });
            console.error("Error fetching maintenance settings:", error);
        } finally {
            setLoadingMaintenance(false);
        }
    };

    const toggleReportsVisibility = (userId) => {
        setShowReports((prev) => ({
            ...prev,
            [userId]: !prev[userId], // Toggle visibility
        }));
    };

    // Toggle maintenance mode
    const toggleMaintenanceMode = async () => {
        try {
            const response = await axios.post(
                "/api/admin/maintenance",
                { maintenanceMode: !maintenanceMode },
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );
            setMaintenanceMode(response.data.maintenanceMode);
            setMessage({
                text: response.data.maintenanceMode
                    ? "Maintenance mode enabled."
                    : "Maintenance mode disabled.",
                type: "success",
            });
        } catch (error) {
            setMessage({
                text: "Error toggling maintenance mode.",
                type: "error",
            });
            console.error("Error toggling maintenance mode:", error);
        }
    };

    // Add an allowed IP
    const addAllowedIP = async () => {
        if (!newIP) {
            setMessage({
                text: "Please enter a valid IP.",
                type: "error",
            });
            return;
        }
        try {
            const response = await axios.post(
                "/api/admin/allowed-ip",
                { ip: newIP },
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );
            setAllowedIPs(response.data.allowedIPs);
            setNewIP(""); // Clear the input
            setMessage({
                text: "IP added successfully.",
                type: "success",
            });
        } catch (error) {
            setMessage({
                text: "Error adding allowed IP.",
                type: "error",
            });
            console.error("Error adding allowed IP:", error);
        }
    };

    // Remove an allowed IP
    const removeAllowedIP = async (ip) => {
        try {
            const response = await axios.delete("/api/admin/allowed-ip", {
                data: { ip },
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setAllowedIPs(response.data.allowedIPs);
            setMessage({
                text: "IP removed successfully.",
                type: "success",
            });
        } catch (error) {
            setMessage({
                text: "Error removing allowed IP.",
                type: "error",
            });
            console.error("Error removing allowed IP:", error);
        }
    };


    // Automatically clear the message after a few seconds
    useEffect(() => {
        if (message) {
            const timeout = setTimeout(() => {
                setMessage(""); // Clear the message
            }, 5000); // 5000ms = 5 seconds

            return () => clearTimeout(timeout); // Cleanup timeout on unmount or when message changes
        }
    }, [message]);
    
    function convertToSlug(text) {
        return text
            .toLowerCase()                      // Convert to lowercase
            .replace(/[\s\W-]+/g, '-')           // Replace spaces and non-word characters with hyphens
            .replace(/^-+/, '')                  // Remove leading hyphens
            .replace(/-+$/, '');                 // Remove trailing hyphens
    }    

    const toggleRequestActionMenu = (requestId) => {
        setShowRequestActionMenu((prev) => (prev === requestId ? null : requestId));
    };
    
    // Toggle the action menu visibility for a specific user
    const toggleActionMenu = (userId) => {
        setShowActionMenu((prev) => (prev === userId ? null : userId));
    };

    // Close the dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check for clicks outside of both dropdowns
            if (showActionMenu && !event.target.closest(".context-menu")) {
                setShowActionMenu(null);
            }
            if (showRequestActionMenu && !event.target.closest(".dropdown-menu")) {
                setShowRequestActionMenu(null);
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showActionMenu, showRequestActionMenu]);
    
    const fetchAnnouncements = async () => {
        setLoadingAnnouncements(true);
        try {
            const response = await axios.get("/api/admin/system-messages", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setAnnouncements(response.data);
        } catch (error) {
            setMessage({
                text: "Error fetching announcements.",
                type: "error",
            });
            console.error("Error fetching announcements:", error);
        } finally {
            setLoadingAnnouncements(false);
        }
    };
    
    const addOrUpdateAnnouncement = async () => {
        const url = editingAnnouncement
            ? `/api/admin/system-messages/${editingAnnouncement._id}`
            : "/api/admin/system-messages";
        const method = editingAnnouncement ? "put" : "post";
    
        try {
            await axios[method](url, newAnnouncement, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: editingAnnouncement
                    ? "Announcement updated successfully."
                    : "Announcement added successfully.",
                type: "success",
            });
            setNewAnnouncement({ title: "", content: "" });
            setEditingAnnouncement(null);
            fetchAnnouncements(); // Refresh announcements
        } catch (error) {
            setMessage({
                text: "Error saving announcement.",
                type: "error",
            });
            console.error("Error saving announcement:", error);
        }
    };
    
    const deleteAnnouncement = async (id) => {
        try {
            await axios.delete(`/api/admin/system-messages/${id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Announcement deleted successfully.",
                type: "success",
            });
            fetchAnnouncements();
        } catch (error) {
            setMessage({
                text: "Error deleting announcement.",
                type: "error",
            });
            console.error("Error deleting announcement:", error);
        }
    };
    
    const handleClearStatus = async (slug) => {
        try {
            const response = await axios.delete(
                `/api/admin/categories/${slug}/status`,
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );
    
            if (response.status === 200) {
                setMessage({
                    text: `Status for ${slug} cleared successfully.`,
                    type: "success",
                });
                fetchReports(); // Refresh reports to reflect the cleared status
            } else {
                setMessage({
                    text: "Error clearing status.",
                    type: "error",
                });
            }
        } catch (error) {
            console.error("Error clearing status:", error);
            setMessage({
                text: "Error clearing status.",
                type: "error",
            });
        }
    };
    
    const handleFileDrop = (e) => {
        e.preventDefault(); // Prevent default drag-and-drop behavior
        const file = e.dataTransfer.files[0]; // Get the dragged file
    
        if (!file) {
            setMessage({
                text: "No file detected.",
                type: "error",
            });
            return;
        }
    
        if (file.name.split('.').pop().toLowerCase() !== 'ct') {
            setMessage({
                text: "Only .ct files are allowed.",
                type: "error",
            });
            setTableFile(null);
            return;
        }
    
        setTableFile(file);
        setTableName(file.name);
        setMessage({
            text: "File accepted successfully.",
            type: "success",
        });
    };
    
    const handleStatusChange = async (slug, newStatus) => {
        if (!["green", "yellow", "red"].includes(newStatus)) {
            alert("Invalid status selected");
            return;
        }
    
        try {
            const response = await axios.put(
                `/api/admin/categories/${slug}/status`,
                { status: newStatus },
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );
    
            if (response.status === 200) {
                setMessage({
                    text: `Status updated to ${newStatus} successfully.`,
                    type: "success",
                });
                fetchReports(); // Refresh reports after status update
            } else {
                setMessage({
                    text: "Error updating status.",
                    type: "error",
                });
            }
        } catch (error) {
            console.error("Error updating status:", error);
            setMessage({
                text: "Error updating status.",
                type: "error",
            });
        }
    };
    
    const updateUserBadges = async (userId, badges) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.put(
                `/api/admin/users/${userId}/badges`,
                { badges },
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
            setMessage({
                text: "User badges updated successfully.",
                type: "success",
            });
            fetchUsers(); // Refresh the user list
            setShowBadgeModal(false); // Close the modal
            setSelectedUserForBadges(null); // Clear the selected user
        } catch (error) {
            setMessage({
                text: "Error updating user badges.",
                type: "error",
            });
            console.error("Error updating user badges:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    const toggleVoterVisibility = (requestId) => {
        setVoterVisibility((prev) => ({
            ...prev,
            [requestId]: !prev[requestId],
        }));
    };
    
    // Define the verifyUser function
    const verifyUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.post(`/api/admin/users/${userId}/verify`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User verified successfully.");
            fetchUsers(); // Refresh the user list
        } catch (error) {
            setMessage("Error verifying user.");
            console.error("Error verifying user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    // Function to open the confirmation dialog
    const openConfirmationDialog = (action, userId) => {
        setConfirmationDialog({ visible: true, action, userId });
    };

    const fetchReports = async () => {
        setLoadingReports(true);
        try {
            const response = await axios.get("/api/admin/reports", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setReports(Array.isArray(response.data) ? response.data : []); // Ensure reports is an array
        } catch (error) {
            console.error("Error fetching reports:", error);
            setReports([]); // Fallback to empty array on error
            setMessage({
                text: "Error loading reports.",
                type: "error",
            });
        } finally {
            setLoadingReports(false);
        }
    };
    
    const resolveReports = async (categoryId) => {
        try {
            await axios.post(`/api/admin/reports/${categoryId}/resolve`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Reports resolved successfully.",
                type: "success",
            });
            fetchReports(); // Refresh reports
        } catch (error) {
            console.error("Error resolving reports:", error);
            setMessage({
                text: "Error resolving reports.",
                type: "error",
            });
        }
    };
    

    // Function to close the confirmation dialog
    const closeConfirmationDialog = () => {
        setConfirmationDialog({ visible: false, action: null, userId: null });
    };

    // Function to confirm an action in the dialog
    const confirmAction = async () => {
        const { action, userId } = confirmationDialog;
        if (action && userId) {
            await action(userId); // Execute the action (like delete, ban, or unban)
        }
        closeConfirmationDialog();
    };

    // Wrapper functions for actions that need confirmation
    const deleteUserWithConfirmation = (userId) => {
        openConfirmationDialog(deleteUser, userId);
    };

    const banUserWithConfirmation = (userId) => {
        openConfirmationDialog(banUser, userId);
    };

    const unbanUserWithConfirmation = (userId) => {
        openConfirmationDialog(unbanUser, userId);
    };
    
    
    
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        setCurrentRequestPage(1); // Reset to first page
    };

    // Function to change tabs
    const changeTab = (tab) => {
        setActiveTab(tab);
    };

    // Fetch initial data when component loads
    useEffect(() => {
        checkAdminStatus();
        fetchUsers();
        fetchCategories();
    }, [navigate]);


    // Filter users based on search query for username, discord username, email, ipAddress, or ipHistory
    useEffect(() => {
        setFilteredUsers(
            users.filter((user) => {
                // Check role filter
                const matchesRole = selectedRoleFilter === "all" || user.role === selectedRoleFilter;
    
                // Check search query
                const matchesSearchQuery =
                    user.username.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
                    (user.discordUsername && user.discordUsername.toLowerCase().includes(userSearchQuery.toLowerCase())) ||
                    (user.email && user.email.toLowerCase().includes(userSearchQuery.toLowerCase())) ||
                    (user.ipAddress && user.ipAddress.toLowerCase().includes(userSearchQuery.toLowerCase())) ||
                    (user.ipHistory && user.ipHistory.some(ip => ip.toLowerCase().includes(userSearchQuery.toLowerCase())));
    
                return matchesRole && matchesSearchQuery;
            })
        );
        setCurrentUserPage(1); // Reset to the first page when search query or role filter changes
    }, [userSearchQuery, users, selectedRoleFilter]);
    
    const clearReports = async (username) => {
        try {
            const confirmClear = window.confirm(`Are you sure you want to clear all reports for ${username}?`);
            if (!confirmClear) return;
    
            // Send a request to the backend to clear reports
            await axios.post("/api/admin/users/clear-reports", { username });

            setMessage({
                text: "Reports cleared successfully.",
                type: "success",
            });
            // Optional: Refresh the user list to reflect updated data
            fetchUsers(); 
        } catch (err) {
            console.error("Failed to clear reports:", err);
            setMessage({
                text: "Failed to clear reports. Please try again.",
                type: "error",
            });
        }
    };
    

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;
            localStorage.setItem("token", accessToken);
            return accessToken;
        } catch (error) {
            console.error("Error refreshing access token:", error);
            navigate("/login");
            throw error;
        }
    };

    
    const fetchAnalytics = async () => {
        try {
            const response = await axios.get(`/api/admin/cloudflare-analytics?range=${timeRange}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            
            const zones = response.data?.zones || [];
            const labels = zones.map(zone => new Date(zone.dimensions.timeslot).toLocaleString());
            const uniqueVisitorsData = zones.map(zone => zone.uniq.uniques);
            const requestsData = zones.map(zone => zone.sum.requests);
    
            // Calculate summaries
            const uniqueVisitorsTotal = uniqueVisitorsData.reduce((a, b) => a + b, 0);
            const totalRequests = requestsData.reduce((a, b) => a + b, 0);
            
            setUniqueVisitorsSummary(uniqueVisitorsTotal);
            setTotalRequestsSummary(totalRequests);
    
            // Set the data in the required format for Chart.js
            setChartData({
                uniqueVisitors: {
                    labels,
                    datasets: [{
                        label: "Unique Visitors",
                        data: uniqueVisitorsData,
                        borderColor: "rgba(54, 162, 235, 1)",
                        fill: true,
                        tension: 0.1,
                    }]
                },
                requests: {
                    labels,
                    datasets: [{
                        label: "Total Requests",
                        data: requestsData,
                        borderColor: "rgba(75, 192, 192, 1)",
                        fill: true,
                        tension: 0.1,
                    }]
                }
            });
        } catch (error) {
            console.error("Error fetching analytics data:", error.response || error.message);
            setMessage({
                text: "Error loading analytics data.",
                type: "error",
            });
        }
    };
    
    useEffect(() => {
        if (activeTab === "tools") {
            fetchTools();
        }
    }, [activeTab]);    

    useEffect(() => {
        if (activeTab === 'reports') {
            fetchReports();
        }
    }, [activeTab]);
    
    useEffect(() => {
        if (activeTab === 'analytics') {
            fetchAnalytics();
        }
    }, [activeTab, timeRange]);
    
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: `Unique Visitors and Requests for ${timeRange}` },
        },
        scales: {
            x: { title: { display: true, text: "Time" } },
            y: { title: { display: true, text: "Count" } },
        },
    };
    
    


    const isTokenExpired = (token) => {
        try {
            const decoded = JSON.parse(atob(token.split(".")[1]));
            const currentTime = Math.floor(Date.now() / 1000);
            return decoded.exp < currentTime;
        } catch (error) {
            return true;
        }
    };

    // Check admin status directly from backend
    const checkAdminStatus = async () => {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");

        if (!token) {
            navigate("/login");
            return;
        }

        try {
            if (isTokenExpired(token)) {
                if (refreshToken) {
                    const newAccessToken = await refreshAccessToken(refreshToken);
                } else {
                    navigate("/login");
                    return;
                }
            }

            // Check admin status via backend
            const res = await axios.get("/api/auth/check-admin", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });

            if (res.data.isAdmin) {
                setIsAdmin(true); // Set isAdmin to true if verified
                fetchRequests();
                fetchUsers();
            } else {
                navigate("/login");
            }

        } catch (error) {
            console.error("Error verifying admin status:", error);
            navigate("/login");
        } finally {
            setLoading(false); // Stop loading after check
        }
    };

    const completeRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/admin/complete/${requestId}`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Request marked as completed successfully.",
                type: "success",
            });
            fetchRequests(); // Refresh the requests after completion
        } catch (error) {
            setMessage({
                text: "Error marking request as completed.",
                type: "error",
            });
            console.error("Error completing request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };
    

    const fetchRequests = async () => {
        setLoadingRequests(true);
        try {
            // Step 1: Fetch all requests
            const res = await axios.get("/api/requests", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
    
            // Step 2: Collect all unique user IDs (requesters and voters)
            const userIds = new Set();
            res.data.forEach((request) => {
                if (request.userId) userIds.add(request.userId);
                (request.voters || []).forEach((voterId) => userIds.add(voterId));
            });
    
            // Step 3: Batch-fetch all user details in a single API call
            const userResponse = await axios.post(
                "/api/admin/users/batch", // A new endpoint to fetch users in bulk
                { userIds: Array.from(userIds) }, // Send all unique user IDs
                { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
            );
    
            // Step 4: Map user IDs to usernames for quick lookup
            const usersMap = userResponse.data.reduce((map, user) => {
                map[user._id] = user.username || "Unknown User";
                return map;
            }, {});
    
            // Step 5: Enrich requests with usernames and voter names
            const enrichedRequests = res.data.map((request) => ({
                ...request,
                username: usersMap[request.userId] || "Unknown User", // Map requester's username
                voters: (request.voters || []).map((voterId) => usersMap[voterId] || "Unknown Voter"), // Map voter usernames
            }));
    
            setRequests(enrichedRequests); // Update the state with enriched requests
        } catch (error) {
            setMessage({
                text: "Error fetching requests.",
                type: "error",
            });
            console.error("Error fetching requests:", error);
        } finally {
            setLoadingRequests(false);
        }
    };
    
    
    
    
    const handleTransitionToTableForm = () => {
        setTransitioning(true);
        setTimeout(() => {
            setShowCategoryForm(false);
            setShowTableForm(true);
            setTransitioning(false);
        }, 500); // Match this with CSS transition time
    };
    
    const handleTransitionToCategoryForm = () => {
        setTransitioning(true);
        setTimeout(() => {
            setShowCategoryForm(true);
            setShowTableForm(false);
            setTransitioning(false);
        }, 500); // Match this with CSS transition time
    };
     

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/api/admin/categories', {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setCategories(response.data); // Ensure the response includes `isNSFW`
        } catch (error) {
            console.error("Error fetching categories: ", error);
        }
    };    

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const fetchUsers = async () => {
        setLoadingUsers(true);
        try {
            const res = await axios.get("/api/admin/users", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setUsers(res.data); // Assuming lastLogin is included in the response
            setFilteredUsers(res.data); // Initialize filtered users with all users
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoadingUsers(false);
        }
    };

    // Function to open the ban details modal
    const openBanDetailsModal = (user) => {
        setSelectedUserBanDetails({
            username: user.username,
            banReason: user.banReason,
            banDate: user.banDate,
            bannedBy: user.bannedBy?.username || "Unknown Admin"
        });
        setShowBanDetailsModal(true);
    };

    // Function to close the ban details modal
    const closeBanDetailsModal = () => {
        setShowBanDetailsModal(false);
        setSelectedUserBanDetails(null);
    };

    const fetchTables = async (category) => {
        if (!category || !category.slug) {
            setTables([]); // Reset tables if no valid category is selected
            return;
        }
        setLoadingTables(true);
        try {
            // Fetch tables by slug, not by _id
            const response = await axios.get(`/api/admin/tables/${category.slug}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setTables(response.data);
        } catch (error) {
            setMessage({
                text: "Error fetching tables.",
                type: "error",
            });
            console.error("Error fetching tables:", error);
            setTables([]); // Reset tables if error occurs
        } finally {
            setLoadingTables(false);
        }
    };
    
    
    const handleModifyTable = (table) => {
        // Set the table being edited
        setEditingTable(table);
        setTableName(table.name); // Pre-fill the name
        setTableVersion(table.version); // Pre-fill the version
        setDescription(table.description); // Pre-fill the description
    };
    
    const handleUpdateTable = async (e) => {
        e.preventDefault();
        
        if (!editingTable || !tableName || !tableVersion || !description) {
            setMessage({
                text: "Please fill in all fields.",
                type: "error",
            });
            return;
        }
    
        const updatedTableData = {
            name: tableName,
            version: tableVersion,
            description,
        };
    
        try {
            setLoadingTables(true);
            await axios.put(`/api/admin/tables/${editingTable._id}`, updatedTableData, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setMessage({
                text: "Table updated successfully.",
                type: "success",
            });
            setEditingTable(null); // Exit edit mode
            fetchTables(selectedCategory); // Refresh the tables list
        } catch (error) {
            setMessage({
                text: "Error updating table.",
                type: "error",
            });
            console.error("Error updating table:", error);
        } finally {
            setLoadingTables(false);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setNewCategoryName(category.name);
        setSelectedAppId(category.appid);
        setDiscontinued(category.discontinued || false);
        setVideo(category.video || "");
        setImages(category.images ? category.images.join(', ') : "");
        setIsNSFW(category.isNSFW || false); // Set the NSFW status correctly
    };    
    
    
    const handleCancelEditCategory = () => {
        setEditingCategory(null); 
        setNewCategoryName("");       
        setSelectedAppId("");   
        setVideo("");
        setImages("");  
        setDiscontinued(false); // Reset discontinued status
    };
    

    const handleCancelEdit = () => {
        // Reset all form fields
        setEditingTable(null);  // Exit edit mode
        setTableName("");       // Clear table name
        setTableVersion("");    // Clear table version
        setDescription("");     // Clear description
    };
    
    

    const handleAddOrUpdateCategory = async (e) => {
        e.preventDefault();
    
        // Ensure required fields are filled
        if (!newCategoryName || !selectedAppId) {
            setMessage({
                text: "Please enter all required fields.",
                type: "error",
            });
            return;
        }
    
        let appId = selectedAppId; // Declare and initialize appId at once
        const steamAppIdRegex = /(?:https?:\/\/)?(?:store\.)?steam(?:community|powered)?\.com\/app\/(\d+)/i;
        const match = steamSearchQuery.match(steamAppIdRegex);
    
        if (match) {
            appId = match[1]; // Extracted App ID from the URL if match is found
        } else if (!/^\d+$/.test(appId)) {
            setMessage({
                text: "Please enter a valid Steam App ID or URL.",
                type: "error",
            });
            return;
        }
    
        // Convert the category name to a slug before saving
        const slug = convertToSlug(newCategoryName);
    
        const categoryData = {
            name: newCategoryName,
            slug: slug, // Added slug
            appid: appId,
            video: video || "",
            images: images ? images.split(",").map((img) => img.trim()) : [],
            discontinued: discontinued, // Include discontinued status
            isNSFW: isNSFW, // Include NSFW status
        };
    
        try {
            setLoadingCategories(true);
            if (editingCategory) {
                await axios.put(`/api/admin/categories/${editingCategory.slug}`, categoryData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setMessage({
                    text: "Category updated successfully.",
                    type: "success",
                });
            } else {
                await axios.post("/api/admin/categories", categoryData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setMessage({
                    text: "Category added successfully.",
                    type: "success",
                });
            }
    
            // Clear form and reset state
            setEditingCategory(null);
            setNewCategoryName("");
            setSelectedAppId("");
            setVideo("");
            setImages("");
            setDiscontinued(false); // Reset discontinued status
            setIsNSFW(false); // Reset NSFW status
            fetchCategories(); // Refresh category list
    
        } catch (error) {
            setMessage({
                text: "Error saving category.",
                type: "error",
            });
            console.error("Error saving category:", error);
        } finally {
            setLoadingCategories(false);
        }
    };
    
    
    
    
    const deleteCategory = async (categoryId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [categoryId]: true }));
            await axios.delete(`/api/admin/categories/${categoryId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Category deleted successfully.",
                type: "success",
            });
            fetchCategories();
        } catch (error) {
            setMessage({
                text: "Error deleting category: " + error.message,
                type: "error",
            });
            console.error("Error deleting category:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [categoryId]: false }));
        }
    };

    const approveRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/admin/approve/${requestId}`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Request approved successfully.",
                type: "success",
            });
            fetchRequests();
        } catch (error) {
            setMessage({
                text: "Error approving request.",
                type: "error",
            });
            console.error("Error approving request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const rejectRequest = async (requestId) => {
        try {
            // Prompt the admin for a rejection reason
            const rejectionReason = prompt("Please enter a reason for rejecting this request:");
    
            // If the admin cancels the prompt or leaves it empty, do not proceed
            if (!rejectionReason) {
                alert("Rejection reason is required.");
                return;
            }
    
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
    
            // Send the rejection reason to the backend
            await axios.post(`/api/admin/reject/${requestId}`, 
            { rejectionReason },  // Include the reason in the request body
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Request rejected successfully.",
                type: "success",
            });
            fetchRequests();  // Refresh the requests after rejection
        } catch (error) {
            setMessage({
                text: "Error rejecting request.",
                type: "error",
            });
            console.error("Error rejecting request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };
    
    const deleteRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.delete(`/api/admin/delete/${requestId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Request deleted successfully.",
                type: "success",
            });
            fetchRequests();
        } catch (error) {
            setMessage({
                text: "Error deleting request.",
                type: "error",
            });
            console.error("Error deleting request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
    
        if (file && file.name.split('.').pop().toLowerCase() !== 'ct') {
            setMessage({
                text: "Only .ct files are allowed.",
                type: "error",
            });
            setTableFile(null);
            return;
        }
    
        setTableFile(file);
        setTableName(file.name);
        setMessage({
            text: "File accepted successfully.",
            type: "success",
        });
    };

    const handleTableUpload = async (e) => {
        e.preventDefault();
        if (!tableFile || !selectedCategory || !tableName || !tableVersion || !description) {
            setMessage({
                text: "Please select a category, table file, and provide a version, name, and description.",
                type: "error",
            });
            return;
        }
    
        const formData = new FormData();
        formData.append("tableFile", tableFile);
        formData.append("category", selectedCategory._id);
        formData.append("name", tableName);
        formData.append("version", tableVersion);
        formData.append("description", description);
    
        try {
            setLoadingTables(true);
            await axios.post("/api/admin/upload-table", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            setMessage({
                text: "Table uploaded successfully.",
                type: "success",
            });
            setTableFile(null);
            setTableName("");
            setTableVersion("");
            setDescription("");  // Reset description after upload
            fetchTables(selectedCategory);
        } catch (error) {
            setMessage({
                text: "Error uploading table.",
                type: "error",
            });
            console.error("Error uploading table:", error);
        } finally {
            setLoadingTables(false);
        }
    };
    
    useEffect(() => {
        if (activeTab === "maintenance") {
            fetchMaintenanceSettings();
        }
    }, [activeTab]);
    

    const deleteTable = async (tableId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [tableId]: true }));
            await axios.delete(`/api/admin/delete-table/${tableId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "Table deleted successfully.",
                type: "success",
            });
            fetchTables(selectedCategory);
        } catch (error) {
            console.error("Error deleting table:", error.response?.data || error.message);
            setMessage({
                text: "Error deleting table.",
                type: "error",
            });
        } finally {
            setActionLoading((prev) => ({ ...prev, [tableId]: false }));
        }
    };

    const changeUserRole = async (userId, newRole) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.put(`/api/admin/users/${userId}`, { role: newRole }, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "User role updated successfully.",
                type: "success",
            });
            fetchUsers();
        } catch (error) {
            setMessage({
                text: "Error updating user role.",
                type: "error",
            });
            console.error("Error updating user role:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };
    
    const banUser = async (userId) => {
        try {
            // Prompt the admin for a ban reason
            const banReason = prompt("Please enter a reason for banning this user:");
    
            // If the admin cancels the prompt or leaves it empty, do not proceed
            if (!banReason) {
                alert("Ban reason is required.");
                return;
            }
    
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
    
            // Send the ban reason to the backend
            await axios.post(
                `/api/admin/users/${userId}/ban`,
                { reason: banReason }, // Include the reason in the request body
                {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                }
            );
    
            setMessage({
                text: "User banned successfully.",
                type: "success",
            });
            fetchUsers(); // Refresh the user list
        } catch (error) {
            setMessage({
                text: "Error banning user.",
                type: "error",
            });
            console.error("Error banning user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };
    
    const unbanUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.post(`/api/admin/users/${userId}/unban`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "User unbanned successfully.",
                type: "success",
            });
            fetchUsers();
        } catch (error) {
            setMessage({
                text: "Error unbanning user.",
                type: "error",
            });
            console.error("Error unbanning user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };
    
    const deleteUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.delete(`/api/admin/users/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage({
                text: "User deleted successfully.",
                type: "success",
            });
            fetchUsers();
        } catch (error) {
            setMessage({
                text: "Error deleting user.",
                type: "error",
            });
            console.error("Error deleting user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };
    
    const searchSteamGames = async () => {
        if (!steamSearchQuery) {
            setMessage({
                text: "Please enter a game name or Steam link to search.",
                type: "error",
            });
            return;
        }
    
        const steamAppIdRegex = /(?:https?:\/\/)?(?:store\.)?steam(?:community|powered)?\.com\/app\/(\d+)/i;
        const match = steamSearchQuery.match(steamAppIdRegex);
        const appId = match ? match[1] : null;
    
        setLoadingSteam(true);
        setMessage(null); // Clear previous messages
    
        try {
            const searchQuery = appId || steamSearchQuery;
            const response = await axios.get(`/api/steam/search?query=${searchQuery}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
    
            setSteamResults(response.data);
    
            if (response.data.length === 0) {
                setMessage({
                    text: "No games found. Try entering the game name or App ID.",
                    type: "error",
                });
            } else {
                setMessage({
                    text: "Games fetched successfully.",
                    type: "success",
                });
            }
        } catch (error) {
            setMessage({
                text: "Error searching Steam games.",
                type: "error",
            });
            console.error("Error searching Steam games:", error);
        } finally {
            setLoadingSteam(false);
        }
    };

    // Render only if the user is an admin
    if (loading) {
        return <p>Loading...</p>;
    }

    if (!isAdmin) {
        return null;
    }

    return (
        
        <div className="adminpanel-layout">
            {/* Sidebar Navigation */}
            <div className="adminpanel-sidebar">
                <h2 className="adminpanel-title">Admin Panel</h2>
                <ul className="sidebar-tabs">
                    <li
                        className={`sidebar-tab ${activeTab === 'announcements' ? 'active' : ''}`}
                        onClick={() => {
                            changeTab('announcements');
                            fetchAnnouncements();
                        }}
                    >
                        <FontAwesomeIcon icon={faBullhorn} className="sidebar-icon" />
                        Announcements
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'content' ? 'active' : ''}`}
                        onClick={() => changeTab('content')}
                    >
                        <FontAwesomeIcon icon={faFileAlt} className="sidebar-icon" />
                        Manage Content
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === "tools" ? "active" : ""}`}
                        onClick={() => changeTab("tools")}
                    >
                        <FontAwesomeIcon icon={faWrench} className="sidebar-icon" />
                        Manage Tools
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'reports' ? 'active' : ''}`}
                        onClick={() => changeTab('reports')}
                    >
                        <FontAwesomeIcon icon={faExclamationCircle} className="sidebar-icon" />
                        Manage Reports
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'requests' ? 'active' : ''}`}
                        onClick={() => changeTab('requests')}
                    >
                        <FontAwesomeIcon icon={faTasks} className="sidebar-icon" />
                        Manage Requests
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'users' ? 'active' : ''}`}
                        onClick={() => changeTab('users')}
                    >
                        <FontAwesomeIcon icon={faUsers} className="sidebar-icon" />
                        Manage Users
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'maintenance' ? 'active' : ''}`}
                        onClick={() => changeTab('maintenance')}
                    >
                        <FontAwesomeIcon icon={faWrench} className="sidebar-icon" />
                        Maintenance
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'logs' ? 'active' : ''}`}
                        onClick={() => {
                            changeTab('logs');
                            fetchLogs(); // Fetch logs when tab is clicked
                        }}
                    >
                        <FontAwesomeIcon icon={faFileAlt} className="sidebar-icon" />
                        Logs
                    </li>
                    <li
                        className={`sidebar-tab ${activeTab === 'analytics' ? 'active' : ''}`}
                        onClick={() => changeTab('analytics')}
                    >
                        <FontAwesomeIcon icon={faChartLine} className="sidebar-icon" />
                        Analytics
                    </li>
                </ul>
            </div>

        <div className="adminpanel-container">

            {/** Maintenance Mode Message */}
            {maintenanceMode && (
                <div className="maintenance-message">
                    <p>
                        <strong>Maintenance Mode is Active:</strong> Only allowed IPs have access to the site.
                    </p>
                </div>
            )}

            <Helmet>
                <title>{`Admin - ${tabTitles[activeTab]} - Sintrix`}</title>
            </Helmet>

            {message && (
                <p className={`adminpanel-${message.type}`}>
                    {message.text}
                </p>
            )}


            {/* Analytics */}
            {activeTab === 'analytics' && (
                <div>
                    <h3>Last 24h Analytics</h3>

                    {/* Unique Visitors Summary and Chart */}
                    <div className="analytics-chart-wrapper">
                        <h4>Unique Visitors</h4>
                        <p className="analytics-summary-number">{uniqueVisitorsSummary}</p>
                        <div className="analytics-chart-container">
                            {chartData?.uniqueVisitors ? (
                                <Line 
                                    data={chartData.uniqueVisitors} 
                                    options={{ 
                                        responsive: true, 
                                        maintainAspectRatio: false, 
                                        plugins: { legend: { display: false } }, 
                                        scales: { 
                                            x: { display: false }, 
                                            y: { beginAtZero: true } 
                                        } 
                                    }} 
                                />
                            ) : (
                                <p>Loading unique visitors data...</p>
                            )}
                        </div>
                    </div>

                    {/* Total Requests Summary and Chart */}
                    <div className="analytics-chart-wrapper">
                        <h4>Total Requests</h4>
                        <p className="analytics-summary-number">{totalRequestsSummary}</p>
                        <div className="analytics-chart-container">
                            {chartData?.requests ? (
                                <Line 
                                    data={chartData.requests} 
                                    options={{ 
                                        responsive: true, 
                                        maintainAspectRatio: false, 
                                        plugins: { legend: { display: false } }, 
                                        scales: { 
                                            x: { display: false }, 
                                            y: { beginAtZero: true } 
                                        } 
                                    }} 
                                />
                            ) : (
                                <p>Loading requests data...</p>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {activeTab === "maintenance" && (
                <div>
                    <h3>Maintenance Mode</h3>
                    {loadingMaintenance ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            <div className="maintenance-mode-toggle">
                                <input
                                    type="checkbox"
                                    id="maintenance-checkbox"
                                    checked={maintenanceMode}
                                    onChange={toggleMaintenanceMode}
                                />
                                <label htmlFor="maintenance-checkbox" className="custom-checkbox-label">
                                    Enable Maintenance Mode
                                </label>
                            </div>

                            <h4>Allowed IPs</h4>
                            <ul className="allowed-ips-list">
                                {allowedIPs.map((ip, index) => (
                                    <li key={index}>
                                        {ip}{" "}
                                        <button
                                            onClick={() => removeAllowedIP(ip)}
                                            className="remove-ip-button"
                                        >
                                            Remove
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="add-ip-section">
                                <input
                                    type="text"
                                    placeholder="Enter IP address"
                                    value={newIP}
                                    onChange={(e) => setNewIP(e.target.value)}
                                    className="upload-input"
                                />
                                <button onClick={addAllowedIP} className="add-ip-button">
                                    Add IP
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}

            {/** Tab Content for Logs */}
            {activeTab === 'logs' && (
                <div>
                    <h3>API Logs</h3>

                    {/* Filter Controls */}
                    <div className="logs-filter-container">
                        {/* Search by Path, IP, or Username */}
                        <input
                            type="text"
                            placeholder="Search by path, IP, or username..."
                            value={logSearchQuery}
                            onChange={(e) => setLogSearchQuery(e.target.value)}
                            className="logs-search-input"
                        />

                        {/* Method Filter */}
                        <select
                            value={methodFilter}
                            onChange={(e) => setMethodFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All Methods</option>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="DELETE">DELETE</option>
                        </select>

                        {/* Status Filter */}
                        <select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All Statuses</option>
                            <option value="200-299">Success (200-299)</option>
                            <option value="400-499">Client Errors (400-499)</option>
                            <option value="500-599">Server Errors (500-599)</option>
                        </select>

                        {/* Time Filter */}
                        <select
                            value={timeFilter}
                            onChange={(e) => setTimeFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All Time</option>
                            <option value="1h">Last 1 Hour</option>
                            <option value="24h">Last 24 Hours</option>
                            <option value="7d">Last 7 Days</option>
                        </select>

                        {/* Known Users Filter */}
                        <div className="logs-filter-checkbox">
                            <input
                                type="checkbox"
                                id="known-users-filter"
                                checked={knownUsersFilter}
                                onChange={(e) => setKnownUsersFilter(e.target.checked)}
                            />
                            <label htmlFor="known-users-filter">Known Users Only</label>
                        </div>

                        {/* Path Filter */}
                        <select
                            value={pathFilter}
                            onChange={(e) => setPathFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All Paths</option>
                            {[...new Set(logs.map(log => log.path))].sort().map(path => (
                                <option key={path} value={path}>{path}</option>
                            ))}
                        </select>

                        {/* IP Filter */}
                        <select
                            value={ipFilter}
                            onChange={(e) => setIpFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All IPs</option>
                            {[...new Set(logs.map(log => log.ipAddress).filter(ip => ip))].sort().map(ip => (
                                <option key={ip} value={ip}>{ip}</option>
                            ))}
                        </select>

                        {/* Duration Filter */}
                        <select
                            value={durationFilter}
                            onChange={(e) => setDurationFilter(e.target.value)}
                            className="logs-filter-select"
                        >
                            <option value="all">All Durations</option>
                            <option value="0-100">0-100 ms</option>
                            <option value="100-500">100-500 ms</option>
                            <option value="500-1000">500-1000 ms</option>
                            <option value="1000+">1000+ ms</option>
                        </select>
                        <button
                            onClick={() => {
                                setLogSearchQuery("");
                                setMethodFilter("all");
                                setStatusFilter("all");
                                setTimeFilter("all");
                                setKnownUsersFilter(false);
                                setPathFilter("all");
                                setIpFilter("all");
                                setDurationFilter("all");
                            }}
                            className="logs-filter-reset"
                        >
                            Reset Filters
                        </button>
                    </div>

                    {/* Logs Display */}
                    {loadingLogs ? (
                        <p>Loading logs...</p>
                    ) : filteredLogs.length === 0 ? (
                        <p>No logs match the current filters.</p>
                    ) : (
                        <>
                            <div className="logs-grid-container">
                                {/* Calculate path counts for highlighting2 */}
                                {(() => {
                                    const pathCounts = filteredLogs.reduce((acc, log) => {
                                        acc[log.path] = (acc[log.path] || 0) + 1;
                                        return acc;
                                    }, {});

                                    // Group logs into pairs
                                    const pairedLogs = [];
                                    for (let i = 0; i < filteredLogs.length; i += 2) {
                                        pairedLogs.push(filteredLogs.slice(i, i + 2));
                                    }

                                    // No client-side slicing since server handles pagination
                                    const paginatedPairs = pairedLogs;

                                    // Debug logging
                                    console.log("Current Page:", currentLogPage);
                                    console.log("Logs Per Page:", logsPerPage);
                                    console.log("Total Logs:", totalLogs);
                                    console.log("Total Pages:", totalPagesLogs);
                                    console.log("Filtered Logs:", filteredLogs);

                                    return (
                                        <div className="logs-grid">
                                            {paginatedPairs.map((pair, pairIndex) => (
                                                <div key={pairIndex} className="log-pair">
                                                    {pair.map((log) => (
                                                        <div
                                                            key={log._id}
                                                            className={`log-card ${pathCounts[log.path] > 5 ? "highlight-pattern" : ""}`}
                                                        >
                                                            <div className="log-header">
                                                                <span
                                                                    className="log-method"
                                                                    style={{
                                                                        backgroundColor:
                                                                            log.method === "GET"
                                                                                ? "#4CAF50"
                                                                                : log.method === "POST"
                                                                                ? "#2196F3"
                                                                                : log.method === "PUT"
                                                                                ? "#FF9800"
                                                                                : log.method === "DELETE"
                                                                                ? "#F44336"
                                                                                : "#9E9E9E",
                                                                    }}
                                                                >
                                                                    {log.method}
                                                                </span>
                                                                <span
                                                                    className="log-status"
                                                                    style={{
                                                                        backgroundColor:
                                                                            log.status >= 200 && log.status < 300
                                                                                ? "#4CAF50"
                                                                                : log.status >= 400 && log.status < 500
                                                                                ? "#FF9800"
                                                                                : log.status >= 500
                                                                                ? "#F44336"
                                                                                : "#9E9E9E",
                                                                    }}
                                                                    data-tooltip={statusCodeMessages[log.status] || `Status ${log.status}`}
                                                                    aria-label={`Status ${log.status}: ${statusCodeMessages[log.status] || "Unknown status"}`}
                                                                >
                                                                    {log.status}
                                                                </span>
                                                            </div>
                                                            <div className="log-content">
                                                                <div className="log-field">
                                                                    <span className="log-label">Path:</span>
                                                                    <span className="log-value">{log.path}</span>
                                                                </div>
                                                                <div className="log-field">
                                                                    <span className="log-label">Timestamp:</span>
                                                                    <span className="log-value">{new Date(log.timestamp).toLocaleString()}</span>
                                                                </div>
                                                                <div className="log-field">
                                                                    <span className="log-label">Duration:</span>
                                                                    <span className="log-value">{log.duration} ms</span>
                                                                </div>
                                                                <div className="log-field">
                                                                    <span className="log-label">IP:</span>
                                                                    <span className="log-value">
                                                                        {log.ipAddress || 'N/A'}
                                                                        {log.username && (
                                                                            <span className="log-username">
                                                                                {' (User: '}
                                                                                <Link to={`/profile/${log.username}`} className="profile-link">
                                                                                    {log.username}
                                                                                </Link>
                                                                                {')'}
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    );
                                })()}
                            </div>

                            {/* Pagination and Per-Page Selector */}
                            <div className="pagination-controls">
                                <button
                                    onClick={() => setCurrentLogPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentLogPage === 1}
                                    className="pagination-button"
                                >
                                    Previous
                                </button>
                                <span className="pagination-text">
                                    Page {currentLogPage} of {totalPagesLogs}
                                </span>
                                <button
                                    onClick={() => setCurrentLogPage((prev) => Math.min(prev + 1, totalPagesLogs))}
                                    disabled={currentLogPage === totalPagesLogs}
                                    className="pagination-button"
                                >
                                    Next
                                </button>
                                <div className="logs-per-page-selector">
                                    <label htmlFor="logs-per-page">Logs per page:</label>
                                    <select
                                        id="logs-per-page"
                                        value={logsPerPage}
                                        onChange={(e) => {
                                            setLogsPerPage(Number(e.target.value));
                                            setCurrentLogPage(1); // Reset to first page when changing logs per page
                                        }}
                                        className="logs-per-page-select"
                                    >
                                        <option value={4}>4 (2 pairs)</option>
                                        <option value={10}>10 (5 pairs)</option>
                                        <option value={20}>20 (10 pairs)</option>
                                        <option value={50}>50 (25 pairs)</option>
                                        <option value={100}>100 (50 pairs)</option>
                                    </select>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}

            {/* Tab Content for Manage Content */}
            {activeTab === 'content' && (
                <div>
                    {/* Manage Content Header */}
                    <h3>Manage Content</h3>

                    {/* Dropdown for Categories */}
                    <div className="dropdown-container">
                    <Select
                            options={categories.map((category) => ({
                                value: category.slug,  // Use slug as value
                                label: category.name,
                            }))}
                            onChange={(selectedOption) => {
                                const selected = categories.find(
                                    (cat) => cat.slug === selectedOption.value  // Match by slug
                                );
                                setSelectedCategory(selected);
                                setEditingCategory(selected); // Set editingCategory for form state
                                setNewCategoryName(selected?.name || "");
                                setSelectedAppId(selected?.appid || "");
                                setVideo(selected?.video || "");
                                setImages(selected?.images?.join(", ") || "");
                                setDiscontinued(selected?.discontinued || false);
                                setIsNSFW(selected?.isNSFW || false);
                                fetchTables(selected); // Fetch tables for the selected category
                            }}
                            placeholder="Select a category..."
                            className="category-dropdown"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--dropdown-background)",
                                    color: "var(--dropdown-text-color)",
                                    border: "1px solid var(--border-color)",
                                    borderRadius: "8px",
                                    padding: "6px",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-text-color)",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--dropdown-background)",
                                    border: "1px solid var(--border-color)",
                                    borderRadius: "8px",
                                }),
                                option: (base, { isFocused, isSelected }) => ({
                                    ...base,
                                    backgroundColor: isSelected
                                        ? "var(--link-color)"
                                        : isFocused
                                        ? "var(--dropdown-hover-background)"
                                        : "var(--dropdown-background)",
                                    color: isSelected
                                        ? "var(--button-text)"
                                        : "var(--dropdown-text-color)",
                                    cursor: "pointer",
                                    padding: "10px",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-placeholder-color)",
                                }),
                                input: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-text-color)", // Ensure typed text is visible
                                }),
                            }}
                        />
                    </div>

                    <div className="form-switch-buttons" style={{ marginTop: "20px" }}>
                        <button onClick={handleTransitionToCategoryForm} className="switch-button">
                            Manage Games
                        </button>
                        <button onClick={handleTransitionToTableForm} className="switch-button">
                            Manage Tables
                        </button>
                    </div>

                {/* Conditionally Render Forms */}
                {showCategoryForm && (
                    <div className={`form-container ${transitioning ? "transitioning" : ""}`}>
                        {/* Manage Games Section */}
                        <h3>{editingCategory ? "Modify Game" : "Manage Games"}</h3>

                        {/* Steam Game Search */}
                        <div className="steam-search">
                            <input
                                type="text"
                                placeholder="Search for a Steam game by name, App ID or link"
                                value={steamSearchQuery}
                                onChange={(e) => setSteamSearchQuery(e.target.value)}
                                className="search-input"
                            />
                            <button
                                onClick={searchSteamGames}
                                className="search-button"
                                disabled={loadingSteam}
                            >
                                {loadingSteam ? "Searching..." : "Search Steam"}
                            </button>
                        </div>

                        {/* Display Search Results */}
                        {loadingSteam ? (
                            <p>Loading results...</p>
                        ) : (
                            steamResults.length > 0 && (
                                <div className="steam-results">
                                    <h4>Search Results</h4>
                                    <ul>
                                        {steamResults.map((game) => (
                                            <li
                                                key={game.appid}
                                                onClick={() => {
                                                    setNewCategoryName(game.name);
                                                    setSelectedAppId(game.appid);
                                                }}
                                                className="steam-result-item"
                                            >
                                                <strong>{game.name}</strong> (App ID: {game.appid})
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        )}

                        {/* Manage Games Form */}
                        <form onSubmit={handleAddOrUpdateCategory}>
                            <input
                                type="text"
                                placeholder="Game Name"
                                value={newCategoryName}
                                onChange={(e) => setNewCategoryName(e.target.value)}
                                className="upload-input"
                                required
                            />
                            <input
                                type="text"
                                placeholder="App ID"
                                value={selectedAppId}
                                onChange={(e) => setSelectedAppId(e.target.value)}
                                className="upload-input"
                                required
                            />
                            <input
                                type="text"
                                placeholder="Video URL (optional)"
                                value={video}
                                onChange={(e) => setVideo(e.target.value)}
                                className="upload-input"
                            />
                            <input
                                type="text"
                                placeholder="Image URLs (comma-separated)"
                                value={images}
                                onChange={(e) => setImages(e.target.value)}
                                className="upload-input"
                            />
                            {/* NSFW Checkbox */}
                            <div className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    id="isNSFW"
                                    checked={isNSFW}
                                    onChange={(e) => setIsNSFW(e.target.checked)}
                                />
                                <label htmlFor="isNSFW" className="custom-checkbox"></label>
                                <label htmlFor="isNSFW">NSFW</label>
                            </div>
                            {/* Discontinued Checkbox */}
                            <div className="checkbox-wrapper">
                                <input
                                    type="checkbox"
                                    id="discontinued"
                                    checked={discontinued}
                                    onChange={(e) => setDiscontinued(e.target.checked)}
                                />
                                <label htmlFor="discontinued" className="custom-checkbox"></label>
                                <label htmlFor="discontinued">Discontinued</label>
                            </div>
                            <button type="submit" className="upload-button" disabled={loadingCategories}>
                                {loadingCategories
                                    ? editingCategory
                                        ? "Updating..."
                                        : "Adding..."
                                    : editingCategory
                                    ? "Update Category"
                                    : "Add Category"}
                            </button>

                            {editingCategory && (
                                
                                <>
                                    <div className="status-management">
                                        <label htmlFor="category-status">Select Status:</label>
                                        <select
                                            id="category-status"
                                            value={editingCategory.status || "green"}
                                            onChange={(e) => handleStatusChange(editingCategory.slug, e.target.value)}
                                            className="status-dropdown"
                                        >
                                            <option value="green">Working (Green)</option>
                                            <option value="yellow">Possible Issues (Yellow)</option>
                                            <option value="red">Not Working (Red)</option>
                                        </select>
                                        <button
                                            onClick={() => handleClearStatus(editingCategory.slug)}
                                            className="clear-status-button"
                                        >
                                            Clear Manual Status
                                        </button>
                                    </div>
                                    <button type="button" className="cancel-button" onClick={handleCancelEditCategory}>
                                        Cancel Edit
                                    </button>
                                    <button
                                        type="button"
                                        className="delete-button"
                                        onClick={() => setShowDeleteConfirmation(true)}
                                        disabled={actionLoading[selectedCategory._id]}
                                    >
                                        {actionLoading[selectedCategory._id] ? "Deleting..." : "Delete Category"}
                                    </button>
                                    {showDeleteConfirmation && (
                                        <div className="confirmation-modal">
                                            <div className="modal-content">
                                                <p>Are you sure you want to delete this category? This action cannot be undone.</p>
                                                <div className="modal-button-container">
                                                    <button
                                                        onClick={() => {
                                                            deleteCategory(selectedCategory.slug); // Call the delete function
                                                            setShowDeleteConfirmation(false); // Hide the dialog
                                                        }}
                                                        className="confirm-button"
                                                    >
                                                        Yes, Delete
                                                    </button>
                                                    <button
                                                        onClick={() => setShowDeleteConfirmation(false)}
                                                        className="cancel-button"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </>
                            )}
                        </form>
                    </div>
                )}

                    {showTableForm && (
                        <div className={`form-container ${transitioning ? "transitioning" : ""}`}>
                            {/* Manage Tables Section */}
                            <h3>Manage Tables</h3>

                            {selectedCategory && (
                                <p className="selected-category-info">
                                    <strong>Selected Game:</strong> {selectedCategory.name}
                                </p>
                            )}

                            {editingTable ? (
                                <form onSubmit={handleUpdateTable} className="Table-Form">
                                    <h3>Modify Table</h3>
                                    <input
                                        type="text"
                                        value={tableName}
                                        onChange={(e) => setTableName(e.target.value)}
                                        placeholder="Table Name"
                                        className="upload-input"
                                    />
                                    <input
                                        type="text"
                                        value={tableVersion}
                                        onChange={(e) => setTableVersion(e.target.value)}
                                        placeholder="Table Version"
                                        className="upload-input"
                                    />
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Table Description"
                                        className="upload-input"
                                    />
                                    <button type="submit" className="upload-button">Update Table</button>
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={handleCancelEdit}
                                    >
                                        Cancel
                                    </button>
                                </form>
                            ) : (
                                <form onSubmit={handleTableUpload}>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="upload-input"
                                        accept=".ct"
                                        onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
                                        onDrop={handleFileDrop} // Handle file drop
                                    />
                                    <input
                                        type="text"
                                        value={tableName}
                                        onChange={(e) => setTableName(e.target.value)}
                                        placeholder="Table Name"
                                        className="upload-input"
                                    />
                                    <input
                                        type="text"
                                        value={tableVersion}
                                        onChange={(e) => setTableVersion(e.target.value)}
                                        placeholder="Table Version"
                                        className="upload-input"
                                    />
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Table Description"
                                        className="upload-input"
                                    />
                                    <button type="submit" className="upload-button" disabled={loadingTables}>
                                        {loadingTables ? "Uploading..." : "Upload Table"}
                                    </button>
                                </form>
                            )}

                            {loadingTables ? (
                                <p>Loading tables...</p>
                            ) : (
                                tables.length > 0 && tables.map((table, index) => (
                                    <div key={`${table._id}-${index}`} className="adminpanel-card">
                                        <p><strong>Table Name:</strong> {table.name}</p>
                                        <p><strong>Version:</strong> {table.version}</p>
                                        <p><strong>Description:</strong> {table.description || "No description provided"}</p>
                                        <button onClick={() => handleModifyTable(table)} className="adminpanel-button modify">
                                            Modify
                                        </button>
                                        <button onClick={() => deleteTable(table._id)} disabled={actionLoading[table._id]} className="adminpanel-button delete">
                                            {actionLoading[table._id] ? "Deleting..." : "Delete Table"}
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            )}

            {/* Tab Content for Reports */}
            {activeTab === 'reports' && (
                <div>
                    <h3>Manage Reports by Game</h3>

                    {loadingReports ? (
                        <p>Loading reports...</p>
                    ) : (
                        reports.length === 0 ? (
                            <p className="no-reports">No reports to display</p>
                        ) : (
                            Object.entries(
                                (Array.isArray(reports) ? reports : []).reduce((acc, report) => {
                                    const gameName = report.name || 'Unknown Game';
                                    if (!acc[gameName]) acc[gameName] = [];
                                    acc[gameName].push({
                                        ...report,
                                        slug: report.slug, // Ensure slug is included
                                    });
                                    return acc;
                                }, {})
                            ).map(([gameName, gameReports]) => (
                                <div key={gameName} className="game-section">
                                    <h4>
                                        {/* Link the game name to the slug */}
                                        <a
                                            href={`/game/${gameReports[0]?.slug}`}
                                            className="game-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {gameName}
                                        </a>
                                        {/* Status Dropdown */}
                                        {gameReports[0]?.slug && (
                                            <select
                                                defaultValue={gameReports[0]?.status || 'green'}
                                                onChange={(e) =>
                                                    handleStatusChange(gameReports[0].slug, e.target.value)
                                                }
                                                className="status-dropdown"
                                            >
                                                <option value="green">Working (Green)</option>
                                                <option value="yellow">Possible Issues (Yellow)</option>
                                                <option value="red">Not Working (Red)</option>
                                            </select>
                                        )}
                                        {gameReports[0]?.slug && (
                                            <button
                                                onClick={() => handleClearStatus(gameReports[0].slug)}
                                                className="clear-status-button"
                                            >
                                                Clear Status
                                            </button>
                                        )}
                                    </h4>
                                    <p>Total Reports: {gameReports.length}</p>

                                    {gameReports.map((report, index) => (
                                        <div key={`${report?._id || index}`} className="report-item">
                                            <p><strong>Status:</strong> {report?.status || 'Unknown Status'}</p>
                                            {Array.isArray(report?.reports) && report.reports.length > 0 ? (
                                                report.reports.map((singleReport, idx) => (
                                                    <div key={`${singleReport?._id || idx}`}>
                                                        <p>
                                                            <strong>Reporter:</strong>{" "}
                                                            {singleReport?.userId?.username ? (
                                                                <Link to={`/profile/${singleReport.userId.username}`} className="profile-link">
                                                                    {singleReport.userId.username}
                                                                </Link>
                                                            ) : (
                                                                "Unknown User"
                                                            )}
                                                        </p>
                                                        <p><strong>Description:</strong> {singleReport?.description || 'No description provided'}</p>
                                                        <p><strong>Date:</strong> {new Date(singleReport?.reportedAt || Date.now()).toLocaleString()}</p>
                                                        {singleReport?.tableVersion && (
                                                            <p><strong>Table Version:</strong> {singleReport.tableVersion}</p>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No individual reports available.</p>
                                            )}

                                            <button
                                                onClick={() => resolveReports(report?._id)}
                                                className="resolve-reports-button"
                                            >
                                                Mark All Resolved
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            ))
                        )
                    )}
                </div>
            )}
 
            {/* Tab Content for Manage Tools */}
            {activeTab === 'tools' && (
                <div className="manage-tools-section">
                    <h3 className="section-title">Manage Tools</h3>

                    {/* Error Messages */}
                    {error && <p className="adminpanel-error">{error}</p>}

                    {/* Loading State */}
                    {loadingTools && <p className="loading-message">Loading tools...</p>}

                    {/* Display Existing Tools */}
                    {!loadingTools && tools.length > 0 && (
                        <div className="tool-list">
                            {Object.entries(
                                tools.reduce((grouped, tool) => {
                                    grouped[tool.category] = grouped[tool.category] || [];
                                    grouped[tool.category].push(tool);
                                    return grouped;
                                }, {})
                            ).map(([category, groupedTools]) => (
                                <div key={category} className="tool-category-group">
                                    <h4 className="category-title">{category}</h4>
                                    {groupedTools.map((tool) => (
                                        <div key={tool._id} className="tool-item">
                                            <h4>{tool.name}</h4>
                                            <p>{tool.description}</p>
                                            <a
                                                href={tool.downloadUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="tool-download-link"
                                            >
                                                Download
                                            </a>
                                            <div className="tool-actions">
                                                <button
                                                    onClick={() => editTool(tool)}
                                                    className="edit-button"
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    onClick={() => deleteTool(tool._id)}
                                                    className="remove-tool-button"
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* No Tools Available */}
                    {!loadingTools && tools.length === 0 && (
                        <div className="no-tools">
                            <p>No tools available.</p>
                        </div>
                    )}

                    {/* Add or Edit Tool Form */}
                    <div className="add-tool-form">
                        <h4>{editingTool ? "Edit Tool" : "Add New Tool"}</h4>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (editingTool) {
                                    // Save changes for the edited tool
                                    const updatedTool = {
                                        ...editingTool,
                                        name: toolName,
                                        description: toolDescription,
                                        downloadUrl: toolDownloadUrl,
                                        category: toolCategory,
                                    };
                                    saveTool(updatedTool);
                                } else {
                                    // Add new tool
                                    const newTool = {
                                        name: toolName,
                                        description: toolDescription,
                                        downloadUrl: toolDownloadUrl,
                                        category: toolCategory,
                                    };
                                    addTool(newTool);
                                }
                            }}
                        >
                            <div className="form-group">
                                <label>Name:</label>
                                <input
                                    type="text"
                                    value={toolName}
                                    onChange={(e) => setToolName(e.target.value)}
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description:</label>
                                <textarea
                                    value={toolDescription}
                                    onChange={(e) => setToolDescription(e.target.value)}
                                    rows="3"
                                    className="form-textarea"
                                />
                            </div>
                            <div className="form-group">
                                <label>Download URL:</label>
                                <input
                                    type="text"
                                    value={toolDownloadUrl}
                                    onChange={(e) => setToolDownloadUrl(e.target.value)}
                                    required
                                    className="form-input"
                                />
                            </div>
                            <div className="form-group">
                                <label>Category:</label>
                                <input
                                    type="text"
                                    value={toolCategory}
                                    onChange={(e) => setToolCategory(e.target.value)}
                                    className="form-input"
                                />
                            </div>
                            <div className="form-buttons">
                                {editingTool ? (
                                    <>
                                        <button type="submit" className="save-edit-button">
                                            Save Changes
                                        </button>
                                        <button
                                            type="button"
                                            onClick={resetToolForm}
                                            className="cancel-edit-button"
                                        >
                                            Cancel Edit
                                        </button>
                                    </>
                                ) : (
                                    <button type="submit" className="add-tool-button">
                                        Add Tool
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            )}


            {/* Tab Content for Manage Requests */}
            {activeTab === 'requests' && (
                <div>
                    <h3>Cheat Requests</h3>

                    {/* Filter Buttons for Requests */}
                    <div className="request-filter-tabs">
                        <button
                            className={`request-filter-tab ${selectedFilter === 'pending' ? 'active' : ''}`}
                            onClick={() => {
                                handleFilterChange("pending");
                                setCurrentRequestPage(1); // Reset to first page
                            }}
                        >
                            Pending
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'approved' ? 'active' : ''}`}
                            onClick={() => {
                                handleFilterChange("approved");
                                setCurrentRequestPage(1); // Reset to first page
                            }}
                        >
                            Approved
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'rejected' ? 'active' : ''}`}
                            onClick={() => {
                                handleFilterChange("rejected");
                                setCurrentRequestPage(1); // Reset to first page
                            }}
                        >
                            Rejected
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'completed' ? 'active' : ''}`}
                            onClick={() => {
                                handleFilterChange("completed");
                                setCurrentRequestPage(1); // Reset to first page
                            }}
                        >
                            Completed
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'all' ? 'active' : ''}`}
                            onClick={() => {
                                handleFilterChange("all");
                                setCurrentRequestPage(1); // Reset to first page
                            }}
                        >
                            All
                        </button>
                    </div>

                    {/* Requests List */}
                    {loadingRequests && <p>Loading requests...</p>}
                    {paginatedRequests.length === 0 && !loadingRequests ? (
                        <p>No requests to manage</p>
                    ) : (
                        paginatedRequests.map((request, index) => (
                            <div key={`${request._id}-${index}`} className="adminpanel-card">
                                {/* Flex container for title and menu button */}
                                <div className="card-header">
                                    <h4>Game Name: {request.steamGameDetails?.name || "Unknown Game"}</h4>
                                    <button onClick={() => toggleRequestActionMenu(request._id)} className="menu-button">
                                        <FaEllipsisV />
                                    </button>
                                </div>

                                {/* Request Details */}
                                <p>
                                    <strong>Request by:</strong>{" "}
                                    <Link to={`/profile/${request.username}`} className="profile-link">
                                        {request.username}
                                    </Link>
                                </p>
                                <p><strong>Cheat Details:</strong> {request.cheatDetails}</p>
                                <p><strong>SteamDB Link:</strong>{" "}
                                <a href={request.steamDBLink} target="_blank" rel="noopener noreferrer">{request.steamDBLink}</a>
                                </p>
                                <p><strong>Votes:</strong> {request.votes}</p>
                                <p><strong>Status:</strong> {request.status}</p>
                                {request.status === "rejected" && (
                                    <p><strong>Reason:</strong> {request.rejectionReason}</p>
                                )}

                                {/* Show Voters Button */}
                                <button
                                    onClick={() =>
                                        setShowVoters((prev) => ({
                                            ...prev,
                                            [request._id]: !prev[request._id],
                                        }))
                                    }
                                    className="adminpanel-button show-voters"
                                >
                                    {showVoters[request._id] ? "Hide Voters" : "Show Voters"}
                                </button>

                                {/* Conditionally render styled voters list */}
                                {showVoters[request._id] && (
                                    <div className="voters-list">
                                        <h5>Voters:</h5>
                                        <ul className="voter-list">
                                            {request.voters.map((voter, i) => (
                                                <li key={i}>{voter || "Unknown Voter"}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {/* Dropdown Menu for Actions */}
                                {showRequestActionMenu === request._id && (
                                    <div className="dropdown-menu">
                                        {request.status === "approved" && (
                                            <button
                                                onClick={() => completeRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Completing..." : "Complete"}
                                            </button>
                                        )}
                                        {request.status === "pending" && (
                                            <button
                                                onClick={() => approveRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Approving..." : "Approve"}
                                            </button>
                                        )}
                                        {request.status !== "completed" && (
                                            <button
                                                onClick={() => rejectRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Rejecting..." : "Reject"}
                                            </button>
                                        )}
                                        <button
                                            onClick={() => deleteRequest(request._id)}
                                            disabled={actionLoading[request._id]}
                                            className="dropdown-item"
                                        >
                                            {actionLoading[request._id] ? "Deleting..." : "Delete"}
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))
                    )}

                    {/* Pagination Controls */}
                    <div className="pagination-controls">
                        <button
                            onClick={() => setCurrentRequestPage((prevPage) => Math.max(prevPage - 1, 1))}
                            disabled={currentRequestPage === 1}
                            className="pagination-button"
                        >
                            Previous
                        </button>
                        <span className="pagination-text">
                            Page {currentRequestPage} of {totalRequestPages}
                        </span>
                        <button
                            onClick={() =>
                                setCurrentRequestPage((prevPage) => Math.min(prevPage + 1, totalRequestPages))
                            }
                            disabled={currentRequestPage === totalRequestPages}
                            className="pagination-button"
                        >
                            Next
                        </button>
                    </div>
                </div>
            )}

            {activeTab === 'announcements' && (
                <div>
                    <h3>Manage Announcements</h3>

                    {/* Announcement Form */}
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addOrUpdateAnnouncement();
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Title"
                            value={newAnnouncement.title}
                            onChange={(e) =>
                                setNewAnnouncement((prev) => ({ ...prev, title: e.target.value }))
                            }
                            className="upload-input"
                            required
                        />
                        <textarea
                            placeholder="Content"
                            value={newAnnouncement.content}
                            onChange={(e) =>
                                setNewAnnouncement((prev) => ({ ...prev, content: e.target.value }))
                            }
                            className="upload-input"
                            required
                        ></textarea>
                        <button type="submit" className="upload-button">
                            {editingAnnouncement ? "Update Announcement" : "Add Announcement"}
                        </button>
                        {editingAnnouncement && (
                            <button
                                type="button"
                                className="cancel-button"
                                onClick={() => {
                                    setEditingAnnouncement(null);
                                    setNewAnnouncement({ title: "", content: "" });
                                }}
                            >
                                Cancel Edit
                            </button>
                        )}
                    </form>

                    {/* Announcement List */}
                    {loadingAnnouncements ? (
                        <p>Loading announcements...</p>
                    ) : announcements.length === 0 ? (
                        <p>No announcements available.</p>
                    ) : (
                        announcements.map((announcement) => (
                            <div key={announcement._id} className="adminpanel-card">
                                <p><strong>Title:</strong> {announcement.title}</p>
                                <p><strong>Content:</strong> {announcement.content}</p>
                                <p><strong>Date:</strong> {new Date(announcement.createdAt).toLocaleString()}</p>
                                <button
                                    onClick={() => {
                                        setEditingAnnouncement(announcement);
                                        setNewAnnouncement({
                                            title: announcement.title,
                                            content: announcement.content,
                                        });
                                    }}
                                    className="adminpanel-button modify"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => deleteAnnouncement(announcement._id)}
                                    className="adminpanel-button delete"
                                >
                                    Delete
                                </button>
                            </div>
                        ))
                    )}
                </div>
            )}

    
            {activeTab === 'users' && (
                <div>
                    <div className="role-button-section">
                        <h3>Manage Users</h3>
                        {/* Add User Counts and Role Filter Here */}
                        <div className="user-counts-admin">
                            <span>Total: {filteredUsers.length}</span>
                            <span>Users: {filteredUsers.filter(user => user.role === "user").length}</span>
                            <span>Members: {filteredUsers.filter(user => user.role === "member").length}</span>
                        </div>
                        <select
                            value={selectedRoleFilter}
                            onChange={(e) => setSelectedRoleFilter(e.target.value)}
                            className="role-filter-admin"
                        >
                            <option value="all">All Roles</option>
                            <option value="admin">Admin</option>
                            <option value="moderator">Moderator</option>
                            <option value="user">User</option>
                            <option value="member">Member</option>
                        </select>
                        {/* Existing user count label */}
                        <div className="role-filter-buttons">
                        </div>
                    </div>

                    {/* Add Users per Page Selector Here */}
                    <div className="users-per-page-selector">
                                <label htmlFor="users-per-page">Users per page:</label>
                                <select
                                    id="users-per-page"
                                    value={usersPerPage}
                                    onChange={(e) => {
                                        setUsersPerPage(Number(e.target.value));
                                        setCurrentUserPage(1); // Reset to first page when changing users per page
                                    }}
                                    className="users-per-page-select"
                                >
                                    <option value={5}>5</option>
                                    <option value={7}>7</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>

                    <div className="search-section">
                        <input
                            type="text"
                            placeholder="Search by Username, Discord, IP, or Email..."
                            value={userSearchQuery}
                            onChange={(e) => setUserSearchQuery(e.target.value)}
                            className="user-search-input"
                        />
                    </div>

                        {loadingUsers && <p>Loading users...</p>}
                        {filteredUsers.length === 0 && !loadingUsers ? (
                            <p>No users found</p>
                        ) : (
                            <div className="user-list-container">
                                {filteredCurrentUsers
                                    .filter((user) => user)
                                    .map((user) => (
                                        <div key={user._id} className="user-card">
                                            <div className="user-avatar-container">
                                                <img
                                                    src={getAvatarUrl(user.avatar, user.username)}
                                                    alt={`${user.username}'s Avatar`}
                                                    className="user-avatar"
                                                />
                                            </div>

                                            <p>
                                                {user.username ? (
                                                    <Link to={`/profile/${user.username}`} className="profile-link">
                                                        {user.username}
                                                    </Link>
                                                ) : (
                                                    "Unknown User"
                                                )}
                                            </p>
                                            {/* Add Badges */}
                                            <div className="user-badges">
                                                {user.badges && user.badges.length > 0 ? (
                                                    <div className="badges-container">
                                                        {user.badges.map((badge) => (
                                                            BADGE_ICONS[badge] && (
                                                                <span 
                                                                    key={badge}
                                                                    className="badge-icon"
                                                                    title={BADGE_ICONS[badge].tooltip}
                                                                >
                                                                    {BADGE_ICONS[badge].icon}
                                                                </span>
                                                            )
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <span className="no-badges">No badges</span>
                                                )}
                                            </div>
                                            <p>
                                                <strong>Email:</strong>{" "}
                                                <span style={{ color: user.email ? "#43B581" : "red" }}>
                                                    {user.email || <FontAwesomeIcon icon={faTimes} />}
                                                </span>
                                            </p>
                                            <p>
                                                <strong>Created:</strong>{" "}
                                                <span style={{ color: "#99AAB5", fontStyle: "italic" }}>
                                                    {new Date(user.createdAt).toLocaleString()}
                                                </span>
                                            </p>
                                            <p>
                                                <strong>ID:</strong>{" "}
                                                <span style={{ color: "#99AAB5" }}>{user._id}</span>
                                            </p>
                                            <p>
                                                <strong>Current IP:</strong>{" "}
                                                {user.ipAddress ? user.ipAddress : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />}
                                            </p>
                                            <p>
                                                <strong>Last Login:</strong>{" "}
                                                {user.lastLogin ? new Date(user.lastLogin).toLocaleString() : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />}
                                            </p>
                                            <p>
                                                <strong>Verified:</strong>{" "}
                                                <span className={user.isVerified ? "text-success" : "text-error"}>
                                                    {user.isVerified ? "✔" : <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />}
                                                </span>
                                            </p>
                                            <p>
                                                <strong>Discord:</strong>{" "}
                                                {user.discordUsername ? (
                                                    <span style={{ color: "#7289DA", fontWeight: "bold" }}>{user.discordUsername}</span>
                                                ) : (
                                                    <FontAwesomeIcon icon={faTimes} style={{ color: "red" }} />
                                                )}
                                            </p>
                                            <p>
                                                <span className={`role-badge ${user.role}`}>{user.role}</span>
                                            </p>
                                            <p>
                                                <strong>Status:</strong>{" "}
                                                <span className={`user-status ${user.banned ? "banned" : "active"}`}>
                                                    {user.banned ? "Banned" : "Unbanned"}
                                                </span>
                                                {user.banned && (
                                                    <button
                                                        onClick={() => openBanDetailsModal(user)}
                                                        className="adminpanel-button ban-details"
                                                        style={{ marginLeft: "10px" }}
                                                    >
                                                        View Ban Details
                                                    </button>
                                                )}
                                            </p>
                                            <p>
                                                <strong>Report Count:</strong>{" "}
                                                <span style={{ color: user.reportCount > 0 ? "red" : "#43B581", fontWeight: "bold" }}>
                                                    {user.reportCount || 0}
                                                </span>
                                            </p>

                                            <button
                                                onClick={() => setIpHistoryMenu((prev) => prev === user._id ? null : user._id)}
                                                className="toggle-ip-history-button"
                                            >
                                                {ipHistoryMenu === user._id ? "Hide IP History" : "Show IP History"}
                                            </button>

                                            {ipHistoryMenu === user._id && user.ipHistory && Array.isArray(user.ipHistory) && (
                                                <div className="ip-history">
                                                    <h5>IP History (Latest to Oldest):</h5>
                                                    <ul>
                                                        {user.ipHistory.length > 0 ? (
                                                            [...user.ipHistory]
                                                                .reverse()
                                                                .map((ip, index) => (
                                                                    <li key={`${ip}-${index}`}>{ip}</li>
                                                                ))
                                                        ) : (
                                                            <li>No IP history available.</li>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}

                                            <button
                                                onClick={() => toggleReportsVisibility(user._id)}
                                                className="adminpanel-button show-reports"
                                            >
                                                {showReports[user._id] ? "Hide Reports" : "Show Reports"}
                                            </button>

                                            {showReports[user._id] && (
                                                <div className="reports-list">
                                                    <h5>User Reports:</h5>
                                                    <ul>
                                                        {user.reports && user.reports.length > 0 ? (
                                                            user.reports.map((report, idx) => (
                                                                <li key={idx}>
                                                                    <p><strong>Reported By:</strong> {report.reportedBy || "Unknown User"}</p>
                                                                    <p><strong>Reason:</strong> {report.reason}</p>
                                                                    <p><strong>Description:</strong> {report.description || "No description provided"}</p>
                                                                    <p><strong>Reported At:</strong> {new Date(report.date).toLocaleString()}</p>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <p>No reports available for this user.</p>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}

                                            <div className="context-menu-container">
                                                <button
                                                    onClick={() => setActionMenu((prev) => (prev === user._id ? null : user._id))}
                                                    className="menu-button"
                                                >
                                                    <FaEllipsisV />
                                                </button>
                                                {actionMenu === user._id && (
                                                    <div className="dropdown-menu">
                                                        {!user.isVerified && (
                                                            <button
                                                                onClick={() => verifyUser(user._id)}
                                                                disabled={actionLoading[user._id]}
                                                            >
                                                                {actionLoading[user._id] ? "Verifying..." : "Verify User"}
                                                            </button>
                                                        )}
                                                        {user.role !== "admin" && (
                                                            <button onClick={() => changeUserRole(user._id, "admin")}>
                                                                Set Admin
                                                            </button>
                                                        )}
                                                        {user.role !== "moderator" && (
                                                            <button onClick={() => changeUserRole(user._id, "moderator")}>
                                                                Set Moderator
                                                            </button>
                                                        )}
                                                        {user.role !== "user" && (
                                                            <button onClick={() => changeUserRole(user._id, "user")}>
                                                                Set User
                                                            </button>
                                                        )}
                                                        {user.role !== "member" && (
                                                            <button onClick={() => changeUserRole(user._id, "member")}>
                                                                Set Member
                                                            </button>
                                                        )}
                                                        <button
                                                            onClick={() => {
                                                                setSelectedUserForBadges(user);
                                                                setShowBadgeModal(true);
                                                                setActionMenu(null); // Close the dropdown
                                                            }}
                                                        >
                                                            Badges
                                                        </button>
                                                        <button onClick={() => clearReports(user.username)}>
                                                            Clear Reports
                                                        </button>
                                                        {user.banned ? (
                                                            <button onClick={() => unbanUserWithConfirmation(user._id)}>
                                                                Unban
                                                            </button>
                                                        ) : (
                                                            <button onClick={() => banUserWithConfirmation(user._id)}>
                                                                Ban
                                                            </button>
                                                        )}
                                                        <button onClick={() => deleteUserWithConfirmation(user._id)}>
                                                            Delete User
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}

                        {/* Pagination Controls (unchanged) */}
                        <div className="pagination-controls">
                            <button
                                onClick={() => setCurrentUserPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentUserPage === 1}
                                className="pagination-button"
                            >
                                Previous
                            </button>
                            {currentUserPage > 2 && (
                                <>
                                    <button onClick={() => setCurrentUserPage(1)} className="pagination-button">
                                        1
                                    </button>
                                    {currentUserPage > 3 && <span className="pagination-ellipsis">...</span>}
                                </>
                            )}
                            {Array.from({ length: 5 }, (_, i) => currentUserPage - 1 + i).map((page) => {
                                if (page > 0 && page <= totalUserPages) {
                                    return (
                                        <button
                                            key={page}
                                            onClick={() => setCurrentUserPage(page)}
                                            className={`pagination-button ${currentUserPage === page ? "active" : ""}`}
                                        >
                                            {page}
                                        </button>
                                    );
                                }
                                return null;
                            })}
                            {currentUserPage < totalUserPages - 1 && (
                                <>
                                    {currentUserPage < totalUserPages - 2 && <span className="pagination-ellipsis">...</span>}
                                    <button onClick={() => setCurrentUserPage(totalUserPages)} className="pagination-button">
                                        {totalUserPages}
                                    </button>
                                </>
                            )}
                            <button
                                onClick={() => setCurrentUserPage((prev) => Math.min(prev + 1, totalUserPages))}
                                disabled={currentUserPage === totalUserPages}
                                className="pagination-button"
                            >
                                Next
                            </button>
                        </div>

                        {/* Confirmation Modal (unchanged) */}
                        {confirmationDialog.visible && (
                            <div className="confirmation-modal">
                                <div className="modal-content">
                                    <p>Are you sure you want to proceed with this action?</p>
                                    <div className="modal-button-container">
                                        <button onClick={confirmAction} className="confirm-button">
                                            Yes
                                        </button>
                                        <button onClick={closeConfirmationDialog} className="cancel-button">
                                            No
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {/* Badge Management Modal */}
                        {showBadgeModal && selectedUserForBadges && (
                                        <div className="confirmation-modal">
                                            <div className="modal-content">
                                                <h3>Manage Badges for {selectedUserForBadges.username}</h3>
                                                <p>Current Badges: {selectedUserForBadges.badges.length > 0 ? selectedUserForBadges.badges.join(", ") : "None"}</p>
                                                <div className="badge-list">
                                                    {Object.keys(BADGE_ICONS).map((badge) => (
                                                        <div key={badge} className="badge-item">
                                                            <input
                                                                type="checkbox"
                                                                id={`badge-${badge}`}
                                                                checked={selectedUserForBadges.badges.includes(badge)}
                                                                onChange={(e) => {
                                                                    const updatedBadges = e.target.checked
                                                                        ? [...selectedUserForBadges.badges, badge]
                                                                        : selectedUserForBadges.badges.filter((b) => b !== badge);
                                                                    setSelectedUserForBadges({
                                                                        ...selectedUserForBadges,
                                                                        badges: updatedBadges,
                                                                    });
                                                                }}
                                                            />
                                                            <label htmlFor={`badge-${badge}`} data-icon={BADGE_ICONS[badge]?.icon || "🏅"}>
                                                                {badge} ({BADGE_ICONS[badge]?.tooltip || "No description"})
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="modal-button-container">
                                                    <button
                                                        onClick={() => updateUserBadges(selectedUserForBadges._id, selectedUserForBadges.badges)}
                                                        className="confirm-button"
                                                        disabled={actionLoading[selectedUserForBadges._id]}
                                                    >
                                                        {actionLoading[selectedUserForBadges._id] ? "Saving..." : "Save Badges"}
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setShowBadgeModal(false);
                                                            setSelectedUserForBadges(null);
                                                        }}
                                                        className="cancel-button"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                        {/* Ban Details Modal */}
                        {showBanDetailsModal && selectedUserBanDetails && (
                            <div className="confirmation-modal">
                                <div className="modal-content">
                                    <h3>Ban Details for {selectedUserBanDetails.username}</h3>
                                    <p><strong>Reason:</strong> {selectedUserBanDetails.banReason || "No reason provided"}</p>
                                    <p><strong>Banned On:</strong> {selectedUserBanDetails.banDate ? new Date(selectedUserBanDetails.banDate).toLocaleString() : "Unknown date"}</p>
                                    <p>
                                        <strong>Banned By:</strong>{" "}
                                        {selectedUserBanDetails.bannedBy ? (
                                            <Link to={`/profile/${selectedUserBanDetails.bannedBy}`} className="profile-link">
                                                {selectedUserBanDetails.bannedBy}
                                            </Link>
                                        ) : (
                                            "Unknown Admin"
                                        )}
                                    </p>
                                    <div className="modal-button-container">
                                        <button onClick={closeBanDetailsModal} className="cancel-button">
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}

        </div>
        </div>
    );
    
}

export default AdminPanel;