import React, { useState, useEffect } from "react";
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function Signup() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tosAccepted, setTosAccepted] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [resendMessage, setResendMessage] = useState("");
    const [visitorId, setVisitorId] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchFingerprint = async () => {
            const fp = await FingerprintJS.load();
            const result = await fp.get();
            setVisitorId(result.visitorId);
        };
        fetchFingerprint();
    }, []);

    const handleSignup = async () => {
        if (!tosAccepted) {
            setError("You must accept the Terms of Service to sign up.");
            return;
        }
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            await axios.post("/api/auth/signup", { username, email, password, visitorId, tosAccepted });
            setSuccessMessage(
                "Signup successful! Please check your email to verify your account. Remember to check your spam or junk if you do not see the email. Unverified accounts are deleted after 24 hours."
            );
            setError("");
        } catch (err) {
            setError(err.response?.data?.msg || "Error signing up");
        }
    };

    const handleResendEmail = async () => {
        try {
            await axios.post("/api/auth/resend-verification", { email });
            setResendMessage(
                "Verification email has been resent. Please check your inbox. Unverified accounts are deleted after 24 hours."
            );
            setError("");
        } catch (err) {
            setError(err.response?.data?.msg || "Error resending verification email.");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter" && tosAccepted) {
            handleSignup();
        }
    };

    return (
        <div style={styles.container}>
            <Helmet>
                <title>Signup - Sintrix.net</title>
                <meta
                    name="description"
                    content="Create an account on Sintrix.net to access exclusive cheat tables and features. Join the community today!"
                />
            </Helmet>

            <div style={styles.formContainer}>
                <h2 style={styles.title}>Signup</h2>

                <div style={styles.warningBox}>
                    <p>
                        <strong>Note:</strong> Due to Microsoft email policies, users with Microsoft email accounts
                        (e.g., Outlook, Hotmail) may not receive our emails. If this happens, please check your inbox or spam for an email from <strong>sintrixmsbypass@gmail.com</strong>. For further assistance, join our{" "}
                        <a
                            href="https://discord.gg/zjvdGQBrBc"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={styles.link}
                        >
                            Discord server
                        </a>.
                    </p>
                </div>

                {successMessage ? (
                    <>
                        <p style={styles.successMessage}>{successMessage}</p>
                        {resendMessage && <p style={styles.resendMessage}>{resendMessage}</p>}
                        <button onClick={handleResendEmail} style={styles.resendButton}>
                            Resend Verification Email
                        </button>
                    </>
                ) : (
                    <>
                        {error && <p style={styles.errorMessage}>{error}</p>}
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                            autoComplete="off"
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                            autoComplete="off"
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                            autoComplete="new-password"
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                            autoComplete="new-password"
                        />
                        {/* Custom Checkbox Matching admin.css */}
                        <div style={styles.checkboxWrapper}>
                            <input
                                type="checkbox"
                                id="tos-agreement"
                                checked={tosAccepted}
                                onChange={(e) => setTosAccepted(e.target.checked)}
                                style={styles.hiddenCheckbox}
                            />
                            <label
                                htmlFor="tos-agreement"
                                style={{
                                    ...styles.customCheckbox,
                                    ...(tosAccepted && {
                                        backgroundColor: "var(--link-color)",
                                        borderColor: "var(--link-color)",
                                    }),
                                }}
                            >
                                {tosAccepted && <span style={styles.checkmark}>✔</span>}
                            </label>
                            <span style={styles.checkboxLabel}>
                                I agree to the{" "}
                                <Link to="/terms-of-service" style={styles.link}>
                                    Terms of Service
                                </Link>{" "}
                                and{" "}
                                <Link to="/privacy-policy" style={styles.link}>
                                    Privacy Policy
                                </Link>
                            </span>
                        </div>
                        <button
                            onClick={handleSignup}
                            style={{
                                ...styles.button,
                                opacity: tosAccepted ? 1 : 0.5,
                                cursor: tosAccepted ? "pointer" : "not-allowed",
                            }}
                            disabled={!tosAccepted}
                        >
                            Signup
                        </button>
                        <p style={styles.linkText}>
                            Already have an account? <Link to="/login" style={styles.link}>Login here</Link>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "var(--background-color)",
    },
    formContainer: {
        backgroundColor: "var(--card-background)",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "var(--text-color)",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        borderRadius: "6px",
        color: "var(--text-color)",
        border: "1px solid #ccc",
        fontSize: "16px",
        boxSizing: "border-box",
        backgroundColor: "var(--input-color)",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "var(--button-background)",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease, opacity 0.3s ease",
    },
    resendButton: {
        padding: "12px 20px",
        backgroundColor: "var(--button-background)",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "red",
        marginBottom: "10px",
    },
    successMessage: {
        color: "green",
        marginBottom: "10px",
    },
    resendMessage: {
        color: "blue",
        marginBottom: "10px",
    },
    linkText: {
        marginTop: "20px",
        fontSize: "14px",
        color: "var(--text-color)",
    },
    link: {
        color: "var(--link-color)",
        textDecoration: "none",
        fontWeight: "bold",
        transition: "color 0.3s ease",
    },
    warningBox: {
        backgroundColor: "#ffebcc",
        color: "#663300",
        border: "1px solid #ffa500",
        borderRadius: "8px",
        padding: "15px",
        marginBottom: "20px",
        fontSize: "14px",
        textAlign: "left",
    },
    checkboxWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        fontSize: "1rem",
        color: "var(--text-color)",
        margin: "10px 0",
        justifyContent: "flex-start",
    },
    hiddenCheckbox: {
        position: "absolute",
        opacity: 0,
        pointerEvents: "none",
        width: 0,
        height: 0,
    },
    customCheckbox: {
        width: "20px",
        height: "20px",
        border: "2px solid var(--link-color)",
        borderRadius: "4px",
        backgroundColor: "var(--input-background)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        transition: "background-color 0.3s, border-color 0.3s",
    },
    checkmark: {
        fontSize: "14px",
        color: "var(--button-text)",
        lineHeight: "20px", // Match checkbox height for vertical centering
    },
    checkboxLabel: {
        color: "var(--text-color)",
        fontSize: "14px",
    },
};

export default Signup;