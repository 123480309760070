import React, { useState, useEffect } from 'react';
import './DiscordButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function DiscordButton() {
    const [isOpen, setIsOpen] = useState(true);

    // Check local storage for the user's preference on mount
    useEffect(() => {
        const hidden = localStorage.getItem('hideDiscordButton');
        if (hidden === 'true') {
            setIsOpen(false);
        }
    }, []);

    const handleDiscordClick = () => {
        window.open('https://discord.gg/zjvdGQBrBc', '_blank');
    };

    const handleToggleButton = () => {
        setIsOpen(!isOpen);
        localStorage.setItem('hideDiscordButton', (!isOpen).toString()); // Persist the preference
    };

    return (
        <div className="discord-button-container">
            <div className={`discord-button-wrapper ${isOpen ? 'open' : 'closed'}`}>
                <div className="discord-button" onClick={handleDiscordClick}>
                    <FontAwesomeIcon icon={faDiscord} size="2x" />
                </div>
                <button
                    className="toggle-button"
                    onClick={handleToggleButton}
                    title={isOpen ? "Hide Discord Button" : "Show Discord Button"}
                >
                    <FontAwesomeIcon icon={isOpen ? faChevronRight : faChevronLeft} size="sm" />
                </button>
                <span className="tooltip">Join us on Discord!</span>
            </div>
        </div>
    );
}

export default DiscordButton;