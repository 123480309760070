import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Games.css";

function Tables() {
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [categoryImages, setCategoryImages] = useState({});
    const [sortOption, setSortOption] = useState("name-asc");
    const [showNSFW, setShowNSFW] = useState(false);
    const [steamUser, setSteamUser] = useState(null);
    const [steamLibrary, setSteamLibrary] = useState([]); // Ensure initial state is an array
    const [isSteamLoginInitiated, setIsSteamLoginInitiated] = useState(false); // Track if Steam login was initiated
    const adRef = useRef(null);
    localStorage.setItem("redirectAfterLogin", window.location.pathname);
    const navigate = useNavigate();

    // Added state for pagination
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get("/api/admin/categories");
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);

                    // Fetch images for categories
                    response.data.forEach((category) => {
                        if (category.appid) {
                            fetchCategoryLogo(parseInt(category.appid), category._id);
                        }
                    });
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCategoryLogo = async (steamAppId, categoryId) => {
            const imageUrl = `https://shared.fastly.steamstatic.com/store_item_assets/steam/apps/${steamAppId}/header.jpg`;

            const img = new Image();
            img.src = imageUrl;
            img.onload = () => {
                setCategoryImages((prev) => ({ ...prev, [categoryId]: imageUrl }));
            };
            img.onerror = () => {
                setCategoryImages((prev) => ({ ...prev, [categoryId]: "default-placeholder.jpg" }));
            };
        };

        const fetchNSFWPreference = async () => {
            const token = localStorage.getItem("token");
            if (token) {
                try {
                    const preferencesResponse = await axios.get("/api/auth/preferences/nsfw", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (preferencesResponse.data && preferencesResponse.data.showNSFW !== undefined) {
                        setShowNSFW(preferencesResponse.data.showNSFW);
                        sessionStorage.setItem("showNSFW", preferencesResponse.data.showNSFW);
                    }
                } catch (err) {
                    console.error("Error fetching NSFW preference:", err);
                }
            } else {
                // Load NSFW preference from session storage for non-logged-in users
                const savedNSFW = sessionStorage.getItem("showNSFW");
                if (savedNSFW !== null) {
                    setShowNSFW(JSON.parse(savedNSFW));
                }
            }
        };

        // Execute functions
        fetchCategories();
        fetchNSFWPreference();
    }, []);

    // Fetch Steam user only if login is initiated
    useEffect(() => {
        const fetchSteamUser = async () => {
            try {
                const userResponse = await axios.get("/api/steam/user");
                setSteamUser(userResponse.data);

                const libraryResponse = await axios.get("/api/steam/library");
                setSteamLibrary(libraryResponse.data.library || []);
            } catch (err) {
                console.error("Error fetching Steam user or library:", err);
                setSteamUser(null);
                setSteamLibrary([]);
            }
        };

        if (isSteamLoginInitiated) {
            fetchSteamUser();
        }
    }, [isSteamLoginInitiated]);

    const location = useLocation();

    // Improved AdSense Initialization for single ad
    useEffect(() => {
        const initializeAds = () => {
            if (adRef.current) {
                if (!adRef.current.hasAttribute('data-adsbygoogle-status')) {
                    console.log("Initializing ad:", adRef.current);
                    adRef.current.innerHTML = '';
                    try {
                        (window.adsbygoogle = window.adsbygoogle || []).push({});
                    } catch (e) {
                        console.error("AdSense push error:", e);
                    }
                } else {
                    console.log("Ad already initialized:", adRef.current);
                }
            } else {
                console.warn("Ad ref is null:", adRef);
            }
        };

        initializeAds();
        const timer = setTimeout(() => {
            console.log("Retrying ad initialization after delay...");
            initializeAds();
        }, 1000);

        return () => clearTimeout(timer);
    }, [location.pathname]);

    useEffect(() => {
        let filtered = categories.filter((category) =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        // Apply NSFW filter
        if (!showNSFW) {
            filtered = filtered.filter((category) => !category.isNSFW);
        }

        // Filter by owned games
        if (Array.isArray(steamLibrary) && steamLibrary.length > 0) {
            const ownedAppIds = steamLibrary.map((game) => String(game.appid));
            filtered = filtered.filter((category) => ownedAppIds.includes(String(category.appid)));
        }

        // Sort the filtered categories
        if (sortOption.startsWith("name")) {
            filtered.sort((a, b) =>
                sortOption === "name-asc"
                    ? a.name.localeCompare(b.name)
                    : b.name.localeCompare(a.name)
            );
        } else if (sortOption.startsWith("date")) {
            filtered.sort((a, b) =>
                sortOption === "date-newest"
                    ? new Date(b.createdAt) - new Date(a.createdAt)
                    : new Date(a.createdAt) - new Date(b.createdAt)
            );
        }

        setFilteredCategories(filtered);
    }, [categories, searchQuery, sortOption, showNSFW, steamLibrary]);

    // Reset to first page whenever the filtered categories change
    useEffect(() => {
        setCurrentPage(1);
    }, [filteredCategories]);

    // Compute pagination variables
    const indexOfLastCategory = currentPage * itemsPerPage;
    const indexOfFirstCategory = indexOfLastCategory - itemsPerPage;
    const currentCategories = filteredCategories.slice(indexOfFirstCategory, indexOfLastCategory);
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

    // Handle category click
    const handleCategoryClick = (category) => {
        const categorySlug = category.slug || category.name.toLowerCase().replace(/\s+/g, "-");
        navigate(`/game/${categorySlug}`);
    };

    // Handle Steam login
    const handleSteamLogin = () => {
        setIsSteamLoginInitiated(true); // Set flag to true when user initiates login
        window.location.href = "/api/steam/auth/steam";
    };

    // Handle NSFW toggle
    const handleNSFWToggle = async (isChecked) => {
        setShowNSFW(isChecked);

        // Save the state in session storage
        sessionStorage.setItem("showNSFW", isChecked);

        const token = localStorage.getItem("token");
        if (token) {
            try {
                await axios.post(
                    "/api/auth/preferences/nsfw",
                    { showNSFW: isChecked },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );
            } catch (error) {
                console.error("Error updating NSFW preference:", error);
            }
        }
    };

    // Handle logout for Steam
    const handleLogout = async () => {
        try {
            await axios.get("/api/steam/auth/steam/logout");
            setSteamUser(null);
            setSteamLibrary([]);
            setIsSteamLoginInitiated(false); // Reset the flag on logout
        } catch (err) {
            console.error("Error during logout:", err);
        }
    };

    return (
        <div className="tables-page-container">
            <Helmet>
                <title>Supported Games and Cheat Tables</title>
                <meta
                    name="description"
                    content={categories.map((cat) => `${cat.name} cheat table`).join(", ")}
                />
            </Helmet>

            <h2 className="tables-title">Supported Games</h2>

            <div className="ad-container">
                <ins
                    ref={adRef}
                    key={location.pathname}
                    className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-3980892685014254"
                    data-ad-slot="5067767497"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                />
            </div>

            {/* Steam Login/Logout Section */}
            <div className="steam-auth-section">
                {steamUser ? (
                    <div className="steam-user-info">
                        <img
                            src={steamUser.avatar}
                            alt="Steam Avatar"
                            className="steam-avatar"
                        />
                        <p>Welcome, {steamUser.username}</p>
                        <button onClick={handleLogout} className="logout-button">
                            Logout
                        </button>
                    </div>
                ) : (
                    <button
                        onClick={handleSteamLogin}
                        className="steam-login-button"
                        title="Sign in with Steam to find cheat tables from your library. No data is stored—everything stays in your session."
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg"
                            alt="Steam Logo"
                            className="steam-logo"
                        />
                        Sign in with Steam
                    </button>
                )}
            </div>

            <div className="search-sort-container">
                <input
                    type="text"
                    placeholder="Search games..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="search-input"
                />
                <select
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="sort-select"
                >
                    <option value="name-asc">Sort by Name (A-Z)</option>
                    <option value="name-desc">Sort by Name (Z-A)</option>
                    <option value="date-newest">Sort by Date Added (Newest to Oldest)</option>
                    <option value="date-oldest">Sort by Date Added (Oldest to Newest)</option>
                </select>
                <div className="nsfw-toggle">
                    <label htmlFor="nsfw-filter" className="toggle-label">
                        <span className="toggle-text">{showNSFW ? "NSFW Games" : "NSFW Games"}</span>
                        <div className="toggle-switch">
                            <input
                                type="checkbox"
                                id="nsfw-filter"
                                className="toggle-input"
                                checked={showNSFW}
                                onChange={(e) => handleNSFWToggle(e.target.checked)}
                            />
                            <span className="slider"></span>
                        </div>
                    </label>
                </div>
            </div>

            {loading && <p>Loading categories...</p>}

            <div className="game-grid">
                {currentCategories.map((category) => (
                    <div
                        key={category._id}
                        className="game-card"
                        onClick={() => handleCategoryClick(category)}
                    >
                        <img
                            src={categoryImages[category._id] || "default-placeholder.jpg"}
                            alt={`${category.name} cheat table`}
                            className="game-image"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "default-placeholder.jpg";
                            }}
                        />
                        <div className="game-details">
                            <h3 className="game-name">{category.name}</h3>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            {!loading && totalPages > 1 && (
                <div className="pagination">
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Prev
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => setCurrentPage(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    );
}

export default Tables;